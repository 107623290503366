import { Component, OnInit } from '@angular/core';
import { Location } from '../../../../models/location';
import { Company } from '../../../../models/company';
import { Category } from '../../../../models/category';
import { Page } from '../../../../models/paging/page';
import { Equipment } from '../../../../models/equipment';
import { CompanyService } from '../../../../services/company.service';
import { LocationService } from '../../../../services/location.service';
import { CategoryService } from '../../../../services/category.service';
import { EquipmentService } from '../../../../services/equipment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs as importedSaveAs } from 'file-saver';
import { ColumnService } from '../../../../services/column.service';
import { AuthService } from '../../../../services/auth.service';
import { AppComponent } from '../../../../app.component';
import { StorageService } from '../../../../services/storage.service';
import { JobService } from '../../../../services/job.service';
import { Location_Inspection } from '../../../../models/location_inspection';
import { forEach } from '@angular/router/src/utils/collection';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-job-manage-equipment',
  templateUrl: './job-manage-equipment.component.html',
  styleUrls: ['./job-manage-equipment.component.css']
})
export class JobManageEquipmentComponent implements OnInit {

  filter: any = {
    company_Id: 0,
    location_Id: 0,
    category_Id: 0,
    serial_Num: '',
    asset_Num: '',
    quickSearch: '',
    decommissioned: false,
    archived: false
  };
  asset_List = [];
  user;
  init: boolean;
  categories: Category[];
  toggleOpen = false;
  job: Location_Inspection;
  isService = false;

  page = new Page();
  rows = Array<Equipment>();

  public readonly pageLimitOptions = [
    { value: 5 },
    { value: 10 },
    { value: 25 },
    { value: 50 },
    { value: 100 },
  ];


  public currentPageLimit: number = 10;

  columns = [
    { name: 'Description', prop: 'description' },
    { name: 'Serial #', prop: 'serial_Num' },
    { name: 'Asset #', prop: 'asset_Num' },
    { name: 'SWL', prop: 'swl' },
    { name: 'Category', prop: 'category' },
    { name: 'Manufacturer', prop: 'manufacturer' },
    { name: 'Date of Manufacturer', prop: 'date_Of_Manuf_Formatted' },
    { name: 'Decommissioned', prop: 'decommissioned_Formatted' },
    { name: 'Created', prop: 'created_Formatted' },
    { name: 'Owner', prop: 'owner_Formatted' },
    { name: 'Original CE', prop: 'original_Cert' },
    { name: 'Location', prop: 'location' },
    { name: 'Company', prop: 'company' },
    { name: 'Other Details', prop: 'other_Details' },
    { name: 'Internal Location', prop: 'internal_Location' },
  ];

  allColumns = [
    { name: 'Description', prop: 'description' },
    { name: 'Serial #', prop: 'serial_Num' },
    { name: 'Asset #', prop: 'asset_Num' },
    { name: 'SWL', prop: 'swl' },
    { name: 'Category', prop: 'category' },
    { name: 'Manufacturer', prop: 'manufacturer' },
    { name: 'Date of Manufacturer', prop: 'date_Of_Manuf_Formatted' },
    { name: 'Decommissioned', prop: 'decommissioned_Formatted' },
    { name: 'Created', prop: 'created_Formatted' },
    { name: 'Owner', prop: 'owner_Formatted' },
    { name: 'Original CE', prop: 'original_Cert' },
    { name: 'Location', prop: 'location' },
    { name: 'Company', prop: 'company' },
    { name: 'Other Details', prop: 'other_Details' },
    { name: 'Internal Location', prop: 'internal_Location' },
  ];




  toggle(col) {
    const isChecked = this.isChecked(col);

    if (isChecked) {
      this.columns = this.columns.filter(c => {
        return c.name !== col.name;
      });
    } else {
      this.columns = [...this.columns, col];
    }
    this.toggleSelection();
  }

  isChecked(col) {
    return (
      this.columns.find(c => {
        return c.name === col.name;
      }) !== undefined
    );
  }

  toggleSelection() {
    this.toggleOpen = !this.toggleOpen;
  }

  constructor(public companyService: CompanyService, public locationService: LocationService, public categoryService: CategoryService,
    public equipmentService: EquipmentService, public router: Router, private authService: AuthService,
    private columnService: ColumnService, private storageService: StorageService, public jobService: JobService,
    private confirmationService: ConfirmationService, public route: ActivatedRoute) {

    this.page.pageNumber = 0;
    this.page.size = 10;
    this.user = localStorage.getItem("currentUser");
    this.user = JSON.parse(this.user);
  }


  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      if (id != undefined) {
        this.jobService.get(id).then((data) => {
          this.job = data;
          this.job.is_edit = true;
          this.filter.location_Id = this.job.locationId;
          console.log(this.job);
          this.currentPageLimit = this.page.size;
          
          if (this.job.jobType == 3) {
            this.isService = true;
            this.categoryService.getAllService().then((data) => {
              this.categories = data;
            });
          } else {
            this.isService = false;
            this.categoryService.getAll().then((data) => {
              this.categories = data;
            });
          }
          this.loadTable(this.page);
          this.loadAssetList();
        });
      }
    });
    
  }
  resetFilters() {
    this.filter = {
      company_Id: 0,
      location_Id: this.job.locationId,
      category_Id: 0,
      serial_Num: '',
      asset_Num: '',
      quickSearch: '',
      decommissioned: false,
      archived: false
    };
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.currentPageLimit = this.page.size;
  }




  // ###################################
  // TABLE
  // ###################################
  public onLimitChange(limit: any): void {
    this.page.size = parseInt(limit, 10);
    this.loadTable(this.page);
  }
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page);
  }

  loadTable(page: Page) {
    console.log("Loading Table")
    console.log(this.filter);
    if (this.filter.company_Id == null || this.filter.company_Id == undefined) {
      this.filter.company_Id = 0;
    }
    if (this.filter.location_Id == null || this.filter.location_Id == undefined) {
      this.filter.location_Id = 0;
    }
    this.equipmentService.getJobEquipResults(this.page, this.filter.location_Id,
      this.filter.category_Id, this.filter.serial_Num, this.filter.asset_Num, this.filter.quickSearch, this.job.location_Inspection_ID, false, this.isService).then((pagedData) => {
      this.page = pagedData.page;
        this.rows = pagedData.data;
        console.log(this.rows);

      if (this.init) {
        this.gotoTop();
        this.init = false;
      }
      this.refreshbasketrow();
        
        
        
      });
    
    this.getcolumns();
  }

  Save() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to save these assets to this job?</br><br/>' + 'Total Assets on this Job: ' + this.asset_List.length,
      accept: () => {
        this.jobService.saveEquipment(this.asset_List, this.job.location_Inspection_ID).then((data) => {
          //this.page.pageNumber = 0;
          //this.page.size = 10;
          //this.loadTable(this.page);
          this.router.navigate(['administration/jobs']); 
        });
      }
    });
    
  }

  getAll(add,remove) {
    this.equipmentService.getJobEquipResults(this.page, this.filter.location_Id,
      this.filter.category_Id, this.filter.serial_Num, this.filter.asset_Num, this.filter.quickSearch, this.job.location_Inspection_ID, true, this.isService).then((pagedData) => {
        console.log(pagedData.data);
        if (pagedData.data.length > 0) {
          if (add) {
            this.asset_List = [];
            for (let i = 0; i < pagedData.data.length; i++) {
              const jsonObj = pagedData.data[i];
              this.asset_List.push(jsonObj.equipment_ID);
            }
            this.refreshbasketrow();
            
          }
          else if (remove) {
            console.log("Removing ALL");
            
            this.asset_List = [];
            this.refreshbasketrow();
            
          }
          
          
        }
      });
  }

  loadAssetList() {
    this.equipmentService.getJobEquipResults(this.page, this.filter.location_Id,
      this.filter.category_Id, this.filter.serial_Num, this.filter.asset_Num, this.filter.quickSearch, this.job.location_Inspection_ID, true, this.isService).then((pagedData) => {
        console.log(pagedData.data);
        if (pagedData.data.length > 0) {
          for (let i = 0; i < pagedData.data.length; i++) {
            const jsonObj = pagedData.data[i];
            if (jsonObj.addedAsset) {
              this.asset_List.push(jsonObj.equipment_ID);
            }
            
          }
          this.refreshbasketrow();
          
          
        }
      });
  }

  addAllAssets() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to add all assets in the current filter?</br><br/>',
      accept: () => {
        this.getAll(true, false);
      }
    });
  }
  removeAllAssets() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to remove all assets from this job?</br><br/>' ,
      accept: () => {
        this.getAll(false, true);
      }
    });

    

  }

  refreshbasketrow() {
    for (const row of this.rows) {
      if (this.asset_List.includes(row.equipment_ID)) {
        row.addedAsset = 1;
      } else {
        row.addedAsset = 0;
      }

    }
    console.log(this.asset_List);
  }

  getcolumns() {
    this.columnService.getColumns(this.authService.currentUser.value.userId, "job_manage_equipment").then((data) => {
      if (data.columns != null) {
        this.columns = this.columns.filter(c => {
          return data.columns.includes(c.name);
        });
        this.toggleOpen = false;
      }

    });
  }
  SaveSelection() {
    let result = this.columns.map(a => a.name);
    this.columnService.save(this.authService.currentUser.value.userId, "job_manage_equipment", result).then((data) => {
    });
  }

  addAsset(row) {
    this.asset_List.push(row.equipment_ID);
    row.addedAsset = 1;

  }
  removeAsset(row) {
    let idx = this.asset_List.indexOf(row.equipment_ID);
    this.asset_List.splice(idx, 1);
    row.addedAsset = 0;

  }

  //removeAllAssets() {
  //  for (const row of this.rows) {
  //    this.removeAsset(row);
  //  }
  //  console.log(this.asset_List);
  //}
  //addAllAssets() {
  //  for (const row of this.rows) {
  //    this.addAsset(row);
  //  }
  //  console.log(this.asset_List);
  //}


  gotoTop() {
    console.log("GOTO TOP");
    // window.scrollTo(-9999,-99999);
    let top = document.getElementById('top');
    console.log(top);
    if (top !== null) {
      top.scrollIntoView(true);
      //  document.getElementById("top").scrollTop -= 99999;
      top = null;
    }
  }
}
