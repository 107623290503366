import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

import { User } from '../models/user';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthService {
  currentUser: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  constructor(private cookieService: CookieService, private router: Router, private http: HttpClient) { }

  public userLoggedIn() {
    let cookieExists = this.cookieService.check('User');
    if (!cookieExists) {
      return false;
    }

    return true;
  }

  public setUser(user) {
    this.cookieService.set('User', JSON.stringify(user), 1);
    this.currentUser.next(user);
  }

  public getUser(): BehaviorSubject<User> {
    if (this.currentUser.value != null) {
      this.currentUser.next(this.currentUser.value);
    }

    let cookieExists = this.cookieService.check('User');
    if (!cookieExists) {
      return null;
    }

    let cookieUser = this.cookieService.get('User');
    this.currentUser.next(<User>JSON.parse(cookieUser));
  }

  public logout() {
    this.currentUser.next(null);
    this.cookieService.delete('User');

    this.router.navigate(['/login']);
  }

  login(username: string, password: string, rememberMe: boolean): Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var dto = {
      username: username,
      password: password
    };
    console.log(dto);
    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + "auth/login", dto, { headers: headers }).subscribe(data => {
        this.setUser(data);
        //localStorage.setItem("login", JSON.stringify(dto))
        localStorage.setItem("currentUser", JSON.stringify(data));
        //console.log(data);
        if (rememberMe)
          localStorage.setItem("@user", JSON.stringify(dto));

        this.currentUser.next(data);

        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  public getWelders(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>('/api/account/getwelders').subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
}
