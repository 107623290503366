import { Component, OnInit } from '@angular/core';
import { Company } from '../../../../models/company';
import { Location } from '../../../../models/location';
import { CompanyService } from '../../../../services/company.service';
import { LocationService } from '../../../../services/location.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-location-manage',
  templateUrl: './location-manage.component.html',
  styleUrls: ['./location-manage.component.css']
})
export class LocationManageComponent implements OnInit {
  companies: Company[];
  location: Location;
  phoneisvalid: boolean;

  constructor(private companyService: CompanyService, private locationService: LocationService, private router: Router, private route: ActivatedRoute) {
    this.location = new Location();
  }

  ngOnInit() {
    this.phoneisvalid = true; 
    this.companyService.getAll().then((data) => {
      this.companies = data;
      this.route.queryParams.subscribe(params => {
        const id = params['id'];
        console.log(id);
        if (id != undefined) {
          this.locationService.get(id).then((data) => {
            this.location = data;
            this.location.is_edit = true;
          });
        }
      });
    });
  }

  submit() {
    this.location.companyId = +this.location.companyId;
    this.locationService.save(this.location).then((data) => {
      this.router.navigate(['administration/locations']);
    });
  }
  checknumber(input: string) {
    console.log(input);
    if (input == undefined || input == null || input == 'null') {
      this.phoneisvalid = true;
      return;
    }

      let num = + input;
      if (num > 0)
        this.phoneisvalid = true;
      else
        this.phoneisvalid = false;
  }

}
