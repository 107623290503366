import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Page } from '../../../models/paging/page';
import { PagedData } from '../../../models/paging/paged-data';
import { VehicleService } from '../../../services/vehicle.service';
import { Vehicle } from '../../../models/vehicle';
import { saveAs as importedSaveAs } from 'file-saver';
import { InstallationQuestionSetService } from '../../../services/installation-question-set.service';
import { CategoryService } from '../../../services/category.service';
import { ConfirmationService } from 'primeng/api';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-installation-question-set',
  templateUrl: './installation-question-set.component.html',
  styleUrls: ['./installation-question-set.component.css']
})
export class InstallationQuestionSetComponent implements OnInit {
  filter: any = {
    category_Id: 0,
  };
  page = new Page();
  rows = Array<any>();
  archivedQuestions = Array<any>();
  categories;
  question_Categories;
  installation_Question_Responses;
  user;
  is_admin = false;

  selectedResponse: any = null;
  typedValue: string = '';

  //columns = [
  //  { name: 'Name', prop: 'name' },
  //  { name: 'Inspection Interval', prop: 'inspection_Interval' },
  //  { name: 'Time Per Unit', prop: 'time_Per_Unit' },
  //  { name: 'Hours', prop: 'hours' },
  //  { name: 'Parent Category', prop: 'parent_Category_Name' },
  //  { name: 'Option', prop: '' },
  //]
  init: boolean;

  public readonly pageLimitOptions = [
    { value: 5 },
    { value: 10 },
    { value: 25 },
    { value: 50 },
    { value: 100 },
  ];


  public currentPageLimit: number = 10;

  constructor(public installationQuestionSetService: InstallationQuestionSetService, private snotifyService: SnotifyService,
    categoryService: CategoryService, public router: Router, private confirmationService: ConfirmationService) {
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.user = localStorage.getItem("currentUser");
    this.user = JSON.parse(this.user);
    //console.log(this.user);
    this.is_admin = this.user.userTypeId == 2;

    categoryService.getAll().then((data) => {
      this.categories = data;
    });

    installationQuestionSetService.getQuestionCategories().then((data) => {
      this.question_Categories = data;

    });
    installationQuestionSetService.getQuestionResponses().then((data) => {
      this.installation_Question_Responses = data;
    });
  }

  ngOnInit() {
    this.setPage({ offset: 0 });
    this.init = true;
  }



  // ###################################
  // TABLE
  // ###################################
  public onLimitChange(limit: any): void {
    this.page.size = parseInt(limit, 10);
    this.loadTable(this.page);
  }
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page);
  }


  increasePosition(row) {
    console.log(row);
    console.log(this.rows);
    for (let i = 0; i <= (this.rows.length); i++) {
      let r = this.rows[i];
      if (r.id == row.id) {
        this.rows[i].number = this.rows[i].number + 1;
        this.rows[i + 1].number = this.rows[i + 1].number - 1;
        console.log(this.rows)

        this.rows.sort(function (a, b) {
          return parseFloat(a.number) - parseFloat(b.number);
        });
        break;
      }
    }
  }

  decreasePosition(row) {
    console.log(row);
    console.log(this.rows);
    for (let i = 0; i <= (this.rows.length); i++) {
      let r = this.rows[i];
      if (r.id == row.id) {
        this.rows[i].number = this.rows[i].number - 1;
        this.rows[i - 1].number = this.rows[i - 1].number + 1;
        console.log(this.rows)

        this.rows.sort(function (a, b) {
          return parseFloat(a.number) - parseFloat(b.number);
        });
        break;
      }
    }
  }

  addAndResetResponse(row, responseID, response) {

    this.addResponse(row, responseID, response);

    this.selectedResponse = null;
    this.typedValue = '';
    row.installation_Question_Response_ID = undefined; 
  }

  addResponse(row,response_ID,response_Text) {
    console.log(row);
    console.log(response_ID);
    console.log(response_Text);
    if (response_Text == undefined) {
      
      return;
    }

    if (response_ID == undefined) {
      response_ID = 0;
      //check if already is this response in list of responses
      for (let p = 0; p < (this.installation_Question_Responses.length); p++) {
        let re = this.installation_Question_Responses[p];
        if (re.response == response_Text) {
          response_ID = re.installation_Question_Response_ID;
          break;

        }
      }

    }
    var response = {
      id:0,
      installation_Question_Response_ID: response_ID,
      response: response_Text
    };
    



    for (let i = 0; i <= (this.rows.length); i++) {
      let r = this.rows[i];
      if (r.id == row.id && r.number == row.number) {

        //check if already added
        if (this.rows[i].responses != null && this.rows[i].responses.length > 0) {
          for (let p = 0; p < (this.rows[i].responses.length); p++) {
            let re = this.rows[i].responses[p];
            if (re.response == response_Text) {
              this.snotifyService.warning('Already added this response for this question');

              return;
            }
          }
        }
        



        this.rows[i].responses.unshift(response);
        this.rows[i].installation_Question_Response_ID = undefined;
        console.log(this.rows[i])
        return;
      }
    }
    
  }

  AddQuestion() {
    var newquestion = {
      id: 0,
      installation_Question_Category_ID: -1,
      number: 1,
      rersponse_Type: 0,
      question: '',
      responses: [],
      archivedQuestionResponses: [],
    }
    this.page.totalElements = this.rows.unshift(newquestion);
    for (let i = 1; i <= (this.rows.length); i++) {
      this.rows[i].number = this.rows[i].number + 1;
    }
    
    this.rows.sort(function (a, b) {
      return parseFloat(a.number) - parseFloat(b.number);
    });
  }

  expire(row) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want delete this question?</br><br/>' + row.question,
      accept: () => {
        for (let i = 0; i <= (this.rows.length); i++) {
          let r = this.rows[i];
          if (r.id == row.id && r.number == row.number ) {
            
            this.rows[i].archived = true;
            this.archivedQuestions.unshift(this.rows[i]);
            this.rows.splice(i, 1)

            this.page.totalElements = this.rows.length;
            for (let e = i; e <= (this.rows.length); e++) {
              this.rows[e].number = this.rows[e].number - 1;
            }
            this.rows.sort(function (a, b) {
              return parseFloat(a.number) - parseFloat(b.number);
            });
          }
        }
      }
    });
    
  }
  removeResponse(row, response) {
    console.log(row);
    console.log(response);

    this.confirmationService.confirm({
      message: 'Are you sure that you want delete this response?</br><br/> Question: ' + row.question + '</br><br/> Response: ' + response.response,
      accept: () => {
        for (let i = 0; i <= (this.rows.length); i++) {
          let r = this.rows[i];
          if (r.id == row.id && r.number == row.number) {
            //got the row
            for (let p = 0; p <= (r.responses.length); p++) {
              let re = r.responses[p]
              if (re.id == response.id && re.response == response.response) {

                this.rows[i].responses[p].archived = true;
                this.rows[i].archivedQuestionResponses.push(this.rows[i].responses[p]);
                this.rows[i].responses.splice(p, 1)

                return;
              }
            }
            
          }
        }
        
      }
    });
    
  }

  onCategoryChange(value) {
    this.loadTable(this.page);
  }

  loadTable(page: Page) {
    this.installationQuestionSetService.getResults(this.page, this.filter.category_ID).then((pagedData) => {
      console.log(pagedData.data);
      this.page = pagedData.page;
      this.rows = pagedData.data;
      if (this.init) {
        this.gotoTop();
        this.init = false;
      }
      console.log(pagedData);
    });
  }

  generateExcel() {
    this.installationQuestionSetService.getExcel(this.filter.category_ID).then((data) => {
        importedSaveAs(data, 'Report.xlsx');
      });
  }

  Save() {
    console.log(this.rows);
    for (let i = 0; i < (this.rows.length); i++) {
      this.rows[i].installation_Question_Category_ID = +this.rows[i].installation_Question_Category_ID;
      this.rows[i].response_Type = +this.rows[i].response_Type;
    }
    for (let i = 0; i < (this.archivedQuestions.length); i++) {
      this.archivedQuestions[i].installation_Question_Category_ID = +this.archivedQuestions[i].installation_Question_Category_ID;
      this.archivedQuestions[i].response_Type = +this.archivedQuestions[i].response_Type;
    }
    console.log(this.rows)
    this.installationQuestionSetService.save(this.rows, this.archivedQuestions, this.filter.category_ID).then((data) => {
      this.loadTable(this.page);
      this.installationQuestionSetService.getQuestionResponses().then((data) => {
        this.installation_Question_Responses = data;
      });
    });
  }

  
  gotoTop() {
    console.log("GOTO TOP");
    // window.scrollTo(-9999,-99999);
    let top = document.getElementById('top');
    console.log(top);
    if (top !== null) {
      top.scrollIntoView(true);
      //  document.getElementById("top").scrollTop -= 99999;
      top = null;
    }
  }
}
