import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Category } from '../../../../models/category';
import { CategoryService } from '../../../../services/category.service';

@Component({
  selector: 'app-category-manage',
  templateUrl: './category-manage.component.html',
  styleUrls: ['./category-manage.component.css']
})
export class CategoryManageComponent implements OnInit {
  category: Category;
  parent_Categories;

  constructor(public categoryService: CategoryService, public router: Router, private route: ActivatedRoute) {
    this.category = new Category();
    //this.categoryService.getParentCategories().then((data) => {
    //  this.parent_Categories = data;
    //});

  }

  ngOnInit() {
    // Load our id if one exists
    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      if (id != undefined) {
        this.categoryService.get(id).then((data) => {
          this.category = data;
          this.category.is_edit = true;
        });
      }
    });
  }

  submit() {
    this.category.insp_Time_Period = +this.category.insp_Time_Period;
    this.category.report_Type_ID = +this.category.report_Type_ID;
    this.categoryService.save(this.category).then((data) => {
      this.router.navigate(['administration/categories']);
    });
  }

}
