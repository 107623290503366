import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { PagedData } from '../models/paging/paged-data';
import { Page } from '../models/paging/page';
import { Company } from '../models/company';
import { ServerData } from '../models/paging/server-data';

import { SnotifyService } from 'ng-snotify';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { Colour } from '../models/colour';



@Injectable()
export class ColourService {
  constructor(private http: HttpClient, private snotifyService: SnotifyService, private authService: AuthService) { }
  //public user = JSON.parse(localStorage.getItem("currentUser"));


  public getAll(): Promise<Colour[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<Colour[]>(environment.apiUrl + 'colour/getall', { headers: headers }).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        /*reject();*/
        resolve(null)
      });
    });
  }

 
}
