import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Basket_Item } from '../../models/Basket_Item';
import * as FileSaver from 'file-saver';
import { BasketService } from '../../services/basket.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.css']
})
export class BasketComponent implements OnInit {
  @Output() completed: EventEmitter<any> = new EventEmitter();
  @Output() clearing: EventEmitter<any> = new EventEmitter();
  @Output() removing: EventEmitter<any> = new EventEmitter();

  basketItems: Array<Basket_Item>;

  isOpen: boolean = false;

  typeId: number = 0;
  fileName: string = '';

  constructor(private basketService: BasketService, private router: Router) { }

  ngOnInit() {
    this.basketService.basketItems.subscribe(data => {
      this.basketItems = data;
    });
  }

  downloadZip() {
    if ((this.router.url == ('/reports/inspections')) || (this.router.url == ('/reports/assetregister'))) {
      this.typeId = 1;
      this.fileName = 'Inspections.zip';
    } else if (this.router.url == ('/reports/serviceinspections')) {
      this.typeId = 2;
      this.fileName = 'ServiceInspections.zip';
    } else if (this.router.url == ('/reports/rams')) {
      this.typeId = 3;
      this.fileName = 'rams.zip';
    } else if (this.router.url == ('/reports/erstr')) {
      this.typeId = 4;
      this.fileName = 'erstr.zip';
    } else if (this.router.url == ('/reports/breakdowninspectionsreport')) {
      this.typeId = 5;
      this.fileName = 'breakdown.zip';
    }
    console.log(this.typeId);
    this.basketService.getZip(this.typeId).then((data => {
      FileSaver.saveAs(data, this.fileName);
      this.resetItems();
    }))
  }

  downloadBatch() {
    if ((this.router.url == ('/reports/inspections'))  || (this.router.url == ('/reports/assetregister'))) {
      this.typeId = 1;
      this.fileName = 'Inspections.zip';
    } else if (this.router.url == ('/reports/serviceinspections')) {
      this.typeId = 2;
      this.fileName = 'ServiceInspections.zip';
    } else if (this.router.url == ('/reports/rams')) {
      this.typeId = 3;
      this.fileName = 'rams.zip';
    } else if (this.router.url == ('/reports/erstr')) {
      this.typeId = 4;
      this.fileName = 'erstr.zip';
    } else if (this.router.url == ('/reports/breakdowninspectionsreport')) {
      this.typeId = 5;
      this.fileName = 'breakdown.zip';
    }
    console.log(this.router.url);
    console.log(this.typeId);
    this.basketService.getBatchReport(this.typeId).then((data => {
      FileSaver.saveAs(data, 'BatchReport.pdf');
      this.resetItems();
    }))
  }

  resetItems() {
    this.completed.emit("Completed");
  }

  clear() {
    this.basketService.clear();
    this.clearing.emit("Clearing");
  }

  remove(item) {
    console.log(item);
    this.basketService.remove(item);
    this.removing.emit(item);
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }
}
