import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { JobService } from '../services/job.service';

@Injectable()
export class JobResolver implements Resolve<any> {
  constructor(private jobService: JobService) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.jobService.get(route.paramMap.get('id')).then(job => job);
  }
}
