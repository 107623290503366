export class Manufacturer {
  manuf_ID: number = 0;
  manuf: String = '';
/*  expired: Boolean = false;*/

  is_edit: Boolean = false;

  init(id: number, name: String, expired: Boolean) {
    this.manuf_ID = id;
    this.manuf = name;
    //this.expired = expired;
  }
}
