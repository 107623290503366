import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Page } from '../../../models/paging/page';
import { PagedData } from '../../../models/paging/paged-data';
import { CategoryService } from '../../../services/category.service';
import { Category } from '../../../models/category';
import { saveAs as importedSaveAs } from 'file-saver';
import { AuthService } from '../../../services/auth.service';
import { ColumnService } from '../../../services/column.service';
import { DescriptionsService } from '../../../services/descriptions.service';
import { StorageService } from '../../../services/storage.service';

@Component({
  selector: 'app-descriptions',
  templateUrl: './descriptions.component.html',
  styleUrls: ['./descriptions.component.css']
})
export class DescriptionsComponent implements OnInit {
  filter: any = {
    category_ID: 0,
    quickSearch: '',
  };
  page = new Page();
  rows = Array<Category>();
  toggleOpen = false;

  user;
  is_admin = false;
  categories;
  //columns = [
  //  { name: 'Name', prop: 'name' },
  //  { name: 'Inspection Interval', prop: 'inspection_Interval' },
  //  { name: 'Time Per Unit', prop: 'time_Per_Unit' },
  //  { name: 'Hours', prop: 'hours' },
  //  { name: 'Parent Category', prop: 'parent_Category_Name' },
  //  { name: 'Option', prop: '' },
  //]
  init: boolean;

  public readonly pageLimitOptions = [
    { value: 5 },
    { value: 10 },
    { value: 25 },
    { value: 50 },
    { value: 100 },
  ];


  public currentPageLimit: number = 10;

  columns = [
    { name: 'Description', prop: 'name' },
    { name: 'Category', prop: 'category_Name' },
  ];

  allColumns = [
    { name: 'Description', prop: 'name' },
    { name: 'Category', prop: 'category_Name' },
  ];




  toggle(col) {
    const isChecked = this.isChecked(col);

    if (isChecked) {
      this.columns = this.columns.filter(c => {
        return c.name !== col.name;
      });
    } else {
      this.columns = [...this.columns, col];
    }
    this.toggleSelection();
  }

  isChecked(col) {
    return (
      this.columns.find(c => {
        return c.name === col.name;
      }) !== undefined
    );
  }

  toggleSelection() {
    this.toggleOpen = !this.toggleOpen;
  }

  constructor(private authService: AuthService, private columnService: ColumnService,
    public descriptionService: DescriptionsService, public categoryService: CategoryService,
    public router: Router, private storageService: StorageService) {
    this.page.pageNumber = 0;
    this.page.size = 10;

    this.user = localStorage.getItem("currentUser");
    this.user = JSON.parse(this.user);
    //console.log(this.user);
    this.is_admin = this.user.userTypeId == 2;

    categoryService.getAll().then((data) => {
      this.categories = data;
    });
  }

  ngOnInit() {
    //this.loadTable(this.page);
    //this.init = true;
    var page = JSON.parse(this.storageService.get("description_page"));
    var filter = JSON.parse(this.storageService.get("description_filters"));
    if (page != undefined) {
      this.page = page;
      console.log(this.page);
    }
    if (filter != undefined) {
      this.filter = filter;
    }
    if (page == undefined && filter == undefined) {
      console.log("no filters");
      this.setPage({ offset: 0 });
      this.init = true;
      this.resetFilters();
    } else {
      this.currentPageLimit = this.page.size;
      this.loadTable(this.page, false);
    }
  }

  resetFilters() {
    this.filter = {
      category_ID: 0,
      quickSearch: '',
    };
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.currentPageLimit = this.page.size;
  }

  edit(row) {
    this.router.navigate(['administration/descriptions/manage'], { queryParams: { id: row.description_ID } });
  }


  // ###################################
  // TABLE
  // ###################################
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page, false);
  }
  public onLimitChange(limit: any): void {
    this.page.size = parseInt(limit, 10);
    this.loadTable(this.page, false);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page,false);
  }

  loadTable(page: Page,clicked) {
    if (clicked) {
      this.page.pageNumber = 0;
      this.currentPageLimit = this.page.size;
      this.setPage({ offset: 0 });
      return;
    }
    this.storageService.dataSave("description_filters", JSON.stringify(this.filter));
    this.storageService.dataSave("description_page", JSON.stringify(this.page));
    this.descriptionService.getResults(this.page, this.filter.category_ID, this.filter.quickSearch).then((pagedData) => {
      console.log(pagedData.data);
      this.page = pagedData.page;
      this.rows = pagedData.data;
      if (this.init) {
        this.gotoTop();
        this.init = false;
      }
    });
    this.getcolumns();
  }

  getcolumns() {
    this.columnService.getColumns(this.authService.currentUser.value.userId, "descriptions").then((data) => {
      if (data.columns != null) {
        this.columns = this.columns.filter(c => {
          return data.columns.includes(c.name);
        });
        this.toggleOpen = false;
      }

    });
  }
  SaveSelection() {
    let result = this.columns.map(a => a.name);
    this.columnService.save(this.authService.currentUser.value.userId, "descriptions", result).then((data) => {
    });
  }

  //generateExcel() {
  //  this.categoryService.getExcel(this.filter.name, this.filter.quickSearch).then((data) => {
  //    importedSaveAs(data, 'Report.xlsx');
  //  });
  //}
  gotoTop() {
    console.log("GOTO TOP");
    // window.scrollTo(-9999,-99999);
    let top = document.getElementById('top');
    console.log(top);
    if (top !== null) {
      top.scrollIntoView(true);
      //  document.getElementById("top").scrollTop -= 99999;
      top = null;
    }
  }
}
