import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PartService } from '../../../../services/parts.service';
import { JobService } from '../../../../services/job.service';
import { InspectionService } from '../../../../services/inspection.service';
import { ReportableService } from '../../../../services/reportable.service';
import { BasketService } from '../../../../services/basket.service';
import { Page } from '../../../../models/paging/page';
import { saveAs as importedSaveAs } from 'file-saver';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.css']
})
export class JobDetailsComponent implements OnInit {

  job;
  parts;
  selectedPart_Id = 0;
  estr_complete: boolean;
  cachedJob;
  viewtype: number = 0;
  page = new Page();
  rows = Array<any>();

  constructor(public actr: ActivatedRoute, public partService: PartService, public jobService: JobService, public router: Router,
    public inspectionService: InspectionService, public reportableService: ReportableService, public basketService: BasketService) {
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.page.sortOrder = 'desc';
    this.page.sortBy = "inspected_At";

    partService.get().then((data) => {
      this.parts = data;
    });
  }

  ngOnInit() {
    this.viewtype = 0;
    this.estr_complete = true;
    this.actr.data.subscribe((data) => {
      console.log(data);
      this.job = data.job;
      if (data.job.engineer_Reports[0] == undefined || data.job.engineer_Reports[0] == null)
        this.estr_complete = false;

        this.job.engineer_Report = data.job.engineer_Reports[0];
      
      this.cachedJob = JSON.parse(JSON.stringify(this.job));
    });

    console.log(this.job);
    this.loadTable(this.page);
  }

  selectReport(report) {
    this.job.engineer_Report = report;
  }

  removePart(part) {
    part.removed = true;
  }

  addPart() {
    if (this.selectedPart_Id == 0) {
      return;
    }

    let part = this.parts.find(a => a.id == this.selectedPart_Id);
    let newPart = {
      id: 0,
      part: part,
      part_Id: this.selectedPart_Id
    };

    this.job.engineer_Report.parts.push(newPart);
  }

  approve() {
    // we want to check if this job has been modified.
    // if it hasn't then we do not need to do the update on the server

    if (JSON.stringify(this.job) == JSON.stringify(this.cachedJob)) {
      this.job.modified = false;
    } else {
      this.job.modified = true;
    }

    this.jobService.approve(this.job).then((data) => {
      this.router.navigate(['administration/approval']);
    });
  }
  // ###################################
  // TABLE
  // ###################################
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page);
  }

  loadTable(page: Page) {
    this.inspectionService.getApprovalDetails(this.page, this.job.id).then((pagedData) => {
      console.log(pagedData);
      this.page = pagedData.page;
      this.rows = pagedData.data;
      console.log("ROWS");
      console.log(this.rows);
      });
  }

  downloadReport(id: number) {
    this.reportableService.getLiftingExam(id).then((data) => {
      importedSaveAs(data, 'Inspection Report.pdf');
    });
  }

  //downloadRAMS(id: string) {
  //  this.reportableService.getRAMSReport(id).then((data) => {
  //    importedSaveAs(data, 'RISKS.pdf');
  //  });
  //}

  downloadERSTR(id: number) {
    this.reportableService.getERSTR(id).then((data) => {
      importedSaveAs(data, 'ERSTR.pdf');
    });
  }
  edit(row) {
    this.router.navigate(['administration/locations/manage'], { queryParams: { id: row.id } });
  }
}
