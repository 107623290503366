import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Page } from '../../../models/paging/page';
import { PagedData } from '../../../models/paging/paged-data';
import { VehicleService } from '../../../services/vehicle.service';
import { Vehicle } from '../../../models/vehicle';
import { saveAs as importedSaveAs } from 'file-saver';
import { QuestionSetService } from '../../../services/question-set.service';
import { CategoryService } from '../../../services/category.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-question-set',
  templateUrl: './question-set.component.html',
  styleUrls: ['./question-set.component.css']
})
export class QuestionSetComponent implements OnInit {
  filter: any = {
    category_Id: 0,
  };
  page = new Page();
  rows = Array<any>();
  categories;
  user;
  is_admin = false;

  //columns = [
  //  { name: 'Name', prop: 'name' },
  //  { name: 'Inspection Interval', prop: 'inspection_Interval' },
  //  { name: 'Time Per Unit', prop: 'time_Per_Unit' },
  //  { name: 'Hours', prop: 'hours' },
  //  { name: 'Parent Category', prop: 'parent_Category_Name' },
  //  { name: 'Option', prop: '' },
  //]
  init: boolean;

  public readonly pageLimitOptions = [
    { value: 5 },
    { value: 10 },
    { value: 25 },
    { value: 50 },
    { value: 100 },
  ];


  public currentPageLimit: number = 10;

  constructor(public questionSetService: QuestionSetService, categoryService: CategoryService, public router: Router, private confirmationService: ConfirmationService) {
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.user = localStorage.getItem("currentUser");
    this.user = JSON.parse(this.user);
    //console.log(this.user);
    this.is_admin = this.user.userTypeId == 2;

    categoryService.getAll().then((data) => {
      this.categories = data;
    });
  }

  ngOnInit() {
    this.setPage({ offset: 0 });
    this.init = true;
  }



  // ###################################
  // TABLE
  // ###################################
  public onLimitChange(limit: any): void {
    this.page.size = parseInt(limit, 10);
    this.loadTable(this.page);
  }
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page);
  }


  increasePosition(row) {
    console.log(row);
    console.log(this.rows);
    for (let i = 0; i <= (this.rows.length); i++) {
      let r = this.rows[i];
      if (r.insp_Set_Question_ID == row.insp_Set_Question_ID) {
        this.rows[i].position = this.rows[i].position + 1;
        this.rows[i + 1].position = this.rows[i + 1].position - 1;
        console.log(this.rows)

        this.rows.sort(function (a, b) {
          return parseFloat(a.position) - parseFloat(b.position);
        });
        break;
      }
    }
  }

  decreasePosition(row) {
    console.log(row);
    console.log(this.rows);
    for (let i = 0; i <= (this.rows.length); i++) {
      let r = this.rows[i];
      if (r.insp_Set_Question_ID == row.insp_Set_Question_ID) {
        this.rows[i].position = this.rows[i].position - 1;
        this.rows[i - 1].position = this.rows[i - 1].position + 1;
        console.log(this.rows)

        this.rows.sort(function (a, b) {
          return parseFloat(a.position) - parseFloat(b.position);
        });
        break;
      }
    }
  }

  AddQuestion() {
    var newquestion = {
      insp_Set_Question_ID: 0,
      insp_Set_ID: 0,
      position: 1,
      question: ''
    }
    this.page.totalElements = this.rows.unshift(newquestion);
    for (let i = 1; i <= (this.rows.length); i++) {
      this.rows[i].position = this.rows[i].position + 1;
    }
    
    this.rows.sort(function (a, b) {
      return parseFloat(a.position) - parseFloat(b.position);
    });
  }

  expire(row) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want delete this question?</br><br/>' + row.question,
      accept: () => {
        for (let i = 0; i <= (this.rows.length); i++) {
          let r = this.rows[i];
          if (r.insp_Set_Question_ID == row.insp_Set_Question_ID && r.position ==row.position) {
            this.rows.splice(i, 1)

            this.page.totalElements = this.rows.length;
            for (let e = i; e <= (this.rows.length); e++) {
              this.rows[e].position = this.rows[e].position - 1;
            }
            this.rows.sort(function (a, b) {
              return parseFloat(a.position) - parseFloat(b.position);
            });
          }
        }
      }
    });
    
  }

  onCategoryChange(value) {
    this.loadTable(this.page);
  }

  loadTable(page: Page) {
    this.questionSetService.getResults(this.page, this.filter.category_ID).then((pagedData) => {
      console.log(pagedData.data);
      this.page = pagedData.page;
      this.rows = pagedData.data;
      if (this.init) {
        this.gotoTop();
        this.init = false;
      }
      console.log(pagedData);
    });
  }

  generateExcel() {
    this.questionSetService.getExcel(this.filter.category_ID).then((data) => {
        importedSaveAs(data, 'Report.xlsx');
      });
  }

  Save() {
    this.questionSetService.save(this.rows, this.filter.category_ID).then((data) => {
    });
  }

  
  gotoTop() {
    console.log("GOTO TOP");
    // window.scrollTo(-9999,-99999);
    let top = document.getElementById('top');
    console.log(top);
    if (top !== null) {
      top.scrollIntoView(true);
      //  document.getElementById("top").scrollTop -= 99999;
      top = null;
    }
  }
}
