import { Component, OnInit, ViewChild } from '@angular/core';
import { Company } from '../../../models/company';
import { Location } from '../../../models/location';
import { Page } from '../../../models/paging/page';
import { CompanyService } from '../../../services/company.service';
import { LocationService } from '../../../services/location.service';
import { AuthService } from '../../../services/auth.service';

import { saveAs as importedSaveAs } from 'file-saver';

import * as moment from 'moment';
import { BreakdownInspectionService } from '../../../services/breakdown-inspection.service';
import { ReportableService } from '../../../services/reportable.service';

@Component({
  selector: 'app-breakdown-inspections',
  templateUrl: './breakdown-inspections.component.html',
  styleUrls: ['./breakdown-inspections.component.css']
})
export class BreakdownInspectionsComponent implements OnInit {
  @ViewChild('myTable') table: any;

  companies: Company[];
  locations: Location[];
  locationList: Location[];
  years = [];
  startYear = 2018;

  filter: any = {
    company_Id: 0,
    location_Id: 0,
    year: this.startYear,
    serial_Num: '',
    fault:0
  };

  page = new Page();
  rows = Array<any>();
  init: boolean;
  is_client = false;
  client;

  graphFrequency = [];
  graphFaults = [];

  constructor(authService: AuthService, companyService: CompanyService, locationService: LocationService, public breakdownInpsectionService: BreakdownInspectionService,
    public reportableService: ReportableService) {
    // Work out the years, system starts in 18 so we can calculate that
    let diff = moment().year() - this.startYear;
    this.years.push(this.startYear);
    for (let i = 0; i < diff; i++) {
      this.years.push(this.startYear + (i + 1));
    }

    this.filter.year = moment().year();

    this.is_client = authService.currentUser.value.userType == 0;
    this.client = authService.currentUser.value;

    this.page.pageNumber = 0;
    this.page.size = 10;

    companyService.getAll().then((data) => {
      this.companies = data;

      if (this.is_client) {
        this.filter.company_Id = authService.currentUser.value.company_Id;
        this.filter.location_Id = authService.currentUser.value.location_Id;
      }

      locationService.getAll().then((data) => {
        this.locationList = data;
        this.locations = this.locationList;

        if (this.is_client) {
          this.locations = this.locationList.filter(c => {
            return c.companyId == this.filter.company_Id;
          });
        }

        this.loadTable(this.page);

      });
    });
  }

  ngOnInit() {
    //this.loadTable(this.page);
    this.init = true;
  //  this.gotoTop();
  }
  downloadReport(id) {
    this.reportableService.getBreakdown(id).then((data) => {
      importedSaveAs(data, 'Breakdown Report.pdf');
    });
  }

  onCompanyChange(value) {
    this.filter.location_Id = 0;
    if (value == 0) { value = null; }

    this.locations = this.locationList.filter(c => {
      return value == null || c.companyId == value;
    });
  }
  // ###################################
  // TABLE
  // ###################################
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page);
  }

  loadTable(page: Page) {
    console.log("LOAD TABLE");
    this.breakdownInpsectionService.getResults(this.page, this.filter.company_Id, this.filter.location_Id,
      this.filter.year, this.filter.serial_Num, this.filter.fault).then((pagedData) => {
        this.page = pagedData.page;
        this.rows = pagedData.data;
        // Get all data
        var allPage = new Page();
        allPage.size = 2147483647;
        allPage.pageNumber = 0;

        
        this.breakdownInpsectionService.getResults(allPage, this.filter.company_Id, this.filter.location_Id, this.filter.year, this.filter.serial_Num, this.filter.fault).then((graphData) => {
          this.generateFrequencyGraph(graphData.data);
          this.generateFaultTypes(graphData.data);
        });
      });
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  generateExcel() {
    this.breakdownInpsectionService.getExcel(this.filter.company_Id, this.filter.location_Id, this.filter.year, this.filter.serial_Num, this.filter.fault).then((data) => {
      importedSaveAs(data, 'Report.xlsx');
    });
  }

  generateFrequencyGraph(graphData) {
    this.graphFrequency = [];
    for (var i = 1; i <= 12; i++) {
      var gData = {
        "name": moment.monthsShort(i - 1),
        "series": []
      };
      for (var data of graphData) {
        if (i == 1) {
          if (data.january > 0) {
            gData.series.push({
              "name": data.serial_Num,
              "value": data.january
            });
          }
        } else if (i == 2) {
          if (data.february > 0) {
            gData.series.push({
              "name": data.serial_Num,
              "value": data.february
            });
          }
        } else if (i == 3) {
          if (data.march > 0) {
            gData.series.push({
              "name": data.serial_Num,
              "value": data.march
            });
          }
        } else if (i == 4) {
          if (data.april > 0) {
            gData.series.push({
              "name": data.serial_Num,
              "value": data.april
            });
          }
        } else if (i == 5) {
          if (data.may > 0) {
            gData.series.push({
              "name": data.serial_Num,
              "value": data.may
            });
          }
        } else if (i == 6) {
          if (data.june > 0) {
            gData.series.push({
              "name": data.serial_Num,
              "value": data.june
            });
          }
        } else if (i == 7) {
          if (data.july > 0) {
            gData.series.push({
              "name": data.serial_Num,
              "value": data.july
            });
          }
        } else if (i == 8) {
          if (data.august > 0) {
            gData.series.push({
              "name": data.serial_Num,
              "value": data.august
            });
          }
        } else if (i == 9) {
          if (data.september > 0) {
            gData.series.push({
              "name": data.serial_Num,
              "value": data.september
            });
          }
        } else if (i == 10) {
          if (data.october > 0) {
            gData.series.push({
              "name": data.serial_Num,
              "value": data.october
            });
          }
        } else if (i == 11) {
          if (data.november > 0) {
            gData.series.push({
              "name": data.serial_Num,
              "value": data.november
            });
          }
        } else if (i == 12) {
          if (data.december > 0) {
            gData.series.push({
              "name": data.serial_Num,
              "value": data.december
            });
          }
        }
      }

      this.graphFrequency.push(gData);
    }
    if (this.init) {
      this.gotoTop();
      this.init = false;
    }
  }

  generateFaultTypes(graphData) {
    this.graphFaults = [];
    var faults = ["Limits", "Brakes LT", "Pendant", "Brake Hoist", "Rope & Guides", "LT Fault", "Radio", "CT Fault", "DSL", "Hoist Fault", "Other"];
    for (var fault of faults) {
      var gData = {
        "name": fault,
        "series": []
      };

      var faultCount = 0;
      for (var equip of graphData) {
        if (equip.inspections != null) {
          for (var insp of equip.inspections) {
            for (var inspFault of insp.faults) {
              if (inspFault.fault == fault) {
                faultCount++;
              }
            }
          }
        }
      }

      gData.series.push({
        "name": faultCount,
        "value": faultCount
      });

      this.graphFaults.push(gData);
    }
    if (this.init) {
      this.gotoTop();
      this.init = false;
    }
  }

  axisFormat(val) {
    if (val % 1 === 0) {
      return val.toLocaleString();
    } else {
      return '';
    }
  }
  
  gotoTop() {
    console.log("GOTO TOP");
       // window.scrollTo(-9999,-99999);
    let top = document.getElementById('top');
    console.log(top);
    if (top !== null) {
      top.scrollIntoView(true);
   //  document.getElementById("top").scrollTop -= 99999;
      top = null;
    }
  }
}


