import { Component, OnInit } from '@angular/core';
import { WebUser } from '../../../../models/webUser';
import { WebUserService } from '../../../../services/webUser.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../../services/company.service';
import { Company } from '../../../../models/company';
import { Location } from '../../../../models/location';
import { LocationService } from '../../../../services/location.service';

@Component({
  selector: 'app-web-manage',
  templateUrl: './web-manage.component.html',
  styleUrls: ['./web-manage.component.css']
})
export class WebManageComponent implements OnInit {
  user: WebUser;
  companies: Company[];
  error: "";
  isEdit: boolean;

  locationsList: Location[];
  locations: Location[];

  constructor(private companyService: CompanyService, public locationService: LocationService, public webUserService: WebUserService, public router: Router, public route: ActivatedRoute) { }

  ngOnInit() {
    this.isEdit = false;
    this.user = new WebUser();
    this.companyService.getAll().then((data) => {
      this.companies = data;
      this.locationService.getAll().then((data) => {
        this.locationsList = data;

        this.route.queryParams.subscribe(params => {
          const id = params['id'];
          if (id != undefined) {
            this.isEdit = true;
            this.webUserService.get(id).then((data) => {
              console.log(data);
              this.locations = this.locationsList.filter(c => {
                return c.companyId == data.company_Id
              });

              this.user = data;
              this.user.email2 = data.email;
              this.user.is_edit = true;
            });
          }
        });
      });

      
    });
  }

  onCompanyChange(value) {
    if (value == 0) { value = null; }

    this.locations = this.locationsList.filter(c => {
      return value == null || c.companyId == value;
    });
  }
  
  submit() {
    if (this.user.password != this.user.password2) {
      alert('PASSWORDS DO NOT MATCH');
      return;
    }

    if (this.user.email != this.user.email2) {
      alert('EMAILS DO NOT MATCH');
      return;
    }

    if (!this.isEdit) {
      if (this.user.password.length < 1) {
        alert('NEED A LONGER PASSWORD');
        return;
      }
    }
    this.user.company_Id = +this.user.company_Id;
    this.user.userType = +this.user.userType;
    this.user.depot_ID = +this.user.depot_ID;
    console.log(this.user);

    this.webUserService.save(this.user).then((data) => {
      this.router.navigate(['users/web']);
    }).catch((err) => {
      this.error = err.error;
    });
  }

}
