import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { PagedData } from '../models/paging/paged-data';
import { Page } from '../models/paging/page';
import { Company } from '../models/company';
import { ServerData } from '../models/paging/server-data';

import { SnotifyService } from 'ng-snotify';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { Colour } from '../models/colour';
import { ApiResponse } from '../models/api-response';



@Injectable()
export class QuestionSetService {
  constructor(private http: HttpClient, private snotifyService: SnotifyService, private authService: AuthService) { }
  //public user = JSON.parse(localStorage.getItem("currentUser"));

  public save(questions: any, category_Id: Number): Promise<ApiResponse> {
    return new Promise((resolve, reject) => {
      const headers = {
        'Content-Type': 'application/json',
      };
      console.log("service");
      console.log(questions);
      var dto = {
        questions: questions,
        category_Id: category_Id,
      }

      this.http.post<ApiResponse>(environment.apiUrl + 'questionset/post', dto, { headers: headers }).subscribe(data => {
        resolve(data);
        this.snotifyService.success('Successfully saved question set');
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
  

  public getResults(page: Page, id: Number): Promise<PagedData<any>> {
    const pagedData = new PagedData<any>();
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {

      let params = 'category_Id=' + id;

      this.http.get<ServerData>(environment.apiUrl + 'questionset/gettable?' + params).subscribe(data => {
        page.totalElements = data.total;
        page.totalPages = page.totalElements / page.size;
        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }

  public getExcel(id: Number): Promise<any> {
    return new Promise((resolve, reject) => {
      let params = '?category_Id=' + id;
      

      this.http.get<any>(environment.apiUrl + 'questionset/getexcel' + params).subscribe(data => {
        let blob = this.converBase64toBlob(data, 'application/vnd.ms-excel');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }



  converBase64toBlob(content, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;
    let byteCharacters = window.atob(content); //method which converts base64 to binary
    let byteArrays = [
    ];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);
      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    let blob = new Blob(byteArrays, {
      type: contentType
    }); //statement which creates the blob
    return blob;
  }


  public getQuestions(page: Page, id: Number): Promise<PagedData<any>> {
    const pagedData = new PagedData<any>();
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {

      let params = 'category_Id=' + id;

      this.http.get<ServerData>(environment.apiUrl + 'questionset/getquestions?' + params).subscribe(data => {
        page.totalElements = data.total;
        page.totalPages = page.totalElements / page.size;
        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }
}
