import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  currentUser;

  constructor(private auth: AuthService) {
    this.auth.currentUser.subscribe(data => {
      this.currentUser = data;
    });
  }

  ngOnInit() {
    this.auth.getUser();
  }

  logout() {
    this.auth.logout();
  }
}
