import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { PagedData } from '../models/paging/paged-data';
import { Page } from '../models/paging/page';
import { Company } from '../models/company';
import { ServerData } from '../models/paging/server-data';

import { SnotifyService } from 'ng-snotify';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { Description } from '../models/description';
import { ApiResponse } from '../models/api-response';



@Injectable()
export class DescriptionsService {
  constructor(private http: HttpClient, private snotifyService: SnotifyService, private authService: AuthService) { }
  //public user = JSON.parse(localStorage.getItem("currentUser"));


  public getAll(): Promise<Description[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<Description[]>(environment.apiUrl + 'descriptions/getall', { headers: headers }).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        /*reject();*/
        resolve(null)
      });
    });
  }

  public get(id: Number): Promise<Description> {
    const headers = {
      'Content-Type': 'application/json',
    };
    return new Promise((resolve, reject) => {
      this.http.get<Description>(environment.apiUrl + 'descriptions/get?id=' + id, { headers: headers }).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public save(description: Description): Promise<ApiResponse> {
    return new Promise((resolve, reject) => {
      const headers = {
        'Content-Type': 'application/json',
      };
      this.http.post<ApiResponse>(environment.apiUrl + 'descriptions/post', description, { headers: headers }).subscribe(data => {
        resolve(data);
        if (description.description_ID > 0) {
          this.snotifyService.success('Successfully updated description');
        } else {
          this.snotifyService.success('Successfully added description');
        }
        
      }, error => {
        console.log(error);
        this.snotifyService.warning(error.error);
        reject();
      });

    });
  }


  public getResults(page: Page, category_Id: number, quickSearch: string): Promise<PagedData<any>> {
    const pagedData = new PagedData<any>();
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'Name'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'asc'; }

      let params = '&category_Id=' + category_Id;
      params += '&quickSearch=' + quickSearch;

      this.http.get<ServerData>(environment.apiUrl + 'descriptions/gettable?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params).subscribe(data => {
        page.totalElements = data.total;
        page.totalPages = page.totalElements / page.size;
        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }

}
