import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Filter } from '../models/paging/filter';

import * as moment from 'moment';

@Injectable()
export class FilterService {
  constructor(private cookieService: CookieService) { }

  getFilters(user_Id: string, page_Name: string) {
    var cookie = this.cookieService.get(user_Id + page_Name);
    if (cookie == null || cookie == "")
      return null;

    return this.parseFilters(cookie);
  }

  saveFilters(user_Id: string, page_Name: string, filters: any) {
    var filter = new Filter();
    filter.id = user_Id + page_Name;
    filter.filters = JSON.stringify(filters);
    this.cookieService.set(filter.id, filter.filters);
  }

  parseFilters(filtersAsJson: any) {
    var filters = JSON.parse(filtersAsJson);

    Object.keys(filters).forEach(function (key, index) {
      if (!(!isNaN(parseFloat(filters[key])) && !isNaN(filters[key] - 0))) {
        if (moment(filters[key]).isValid()) {
          filters[key] = moment(filters[key]).toDate();
        }
      }
    });

    return filters;
  }

}
