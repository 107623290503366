import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { EquipmentService } from '../../../../services/equipment.service';
import { InspectionService } from '../../../../services/inspection.service';
import { TabletService } from '../../../../services/tablet.service';
import * as moment from 'moment';
import { Inspection } from '../../../../models/inspection';
import { ColourService } from '../../../../services/colour.service';
import { DescriptionsService } from '../../../../services/descriptions.service';
import { CategoryService } from '../../../../services/category.service';
import { ManufacturerService } from '../../../../services/manufacturer.service';
import { Manufacturer } from '../../../../models/manufacturer';
import { Page } from '../../../../models/paging/page';
import { toDate } from '@angular/common/src/i18n/format_date';
import { Category } from '../../../../models/category';
import { Equipment } from '../../../../models/equipment';
import { QuestionSetService } from '../../../../services/question-set.service';
import { Answered_Questions } from '../../../../models/answered_Questions';
import { SnotifyService } from 'ng-snotify';


@Component({
  selector: 'app-inspect-asset',
  templateUrl: './inspect-asset.component.html',
  styleUrls: ['./inspect-asset.component.css']
})
export class InspectAssetComponent implements OnInit {
  colours;
  jobs;
  users;
  purposes;
  equipment: Equipment;
  manufacturers: Manufacturer[] = [];
  categories: Category[] = [];
  descriptionList;
  descriptions;
  user;
  page = new Page();
  rows = Array<any>();

  inspection: Inspection;

  interval: number = 0;

  constructor(public questionSetService: QuestionSetService, private inspectionService: InspectionService, public descriptionService: DescriptionsService,
    private colourService: ColourService, private tabletService: TabletService, public categoryService: CategoryService,
    private equipmentService: EquipmentService, public manufacturerService: ManufacturerService, private confirmationService: ConfirmationService,
    private router: Router, private route: ActivatedRoute, private snotifyService: SnotifyService) {
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.user = localStorage.getItem("currentUser");
    this.user = JSON.parse(this.user);
    console.log(this.user);
    

    this.purposes = [
      { id: 1, purpose: "Within an interval of 6 months" }
      , { id: 2, purpose: "Within an interval of 12 months" }
      , { id: 3, purpose: "In accordance with an examination scheme" }
      , { id: 3, purpose: "After an occurence of exceptional circumstance" }
    ];
    

    this.inspection = new Inspection();
    this.equipment = new Equipment();
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      

      const id = params['id'];
      this.inspection.locationInspectionId = params['jobId'];
      this.inspection.location_Inspection_ID = params['jobId'];
      this.inspection.PDA_User_ID += params['pda_user_Id'];

      if (id != undefined) {
        this.equipmentService.get(id).then((data) => {
          this.equipment = data
          console.log(this.equipment);
          if (this.equipment.date_Of_Manuf != null && this.equipment.date_Of_Manuf != undefined && moment(this.equipment.date_Of_Manuf).format("YYYY-MM-DD") != moment("0001-01-01").format("YYYY-MM-DD")) {
            var str = this.equipment.date_Of_Manuf.split("T", 2);
            this.equipment.date_Of_Manuf = moment(str[0]).format("YYYY-MM-DD");
          } else {
            this.equipment.date_Of_Manuf = undefined;
          }
          this.colourService.getAll().then((data) => {
            this.colours = data;
          });

          this.questionSetService.getQuestions(this.page, this.equipment.category_ID).then((pagedData) => {
            console.log(pagedData.data);
            this.page = pagedData.page;
            this.rows = pagedData.data;
            console.log("Questions");
            console.log(this.rows);
          });


          this.tabletService.getAll().then((data) => {
            this.users = data;
          });

          this.manufacturerService.getAll().then((data) => {
            this.manufacturers = data;
          });
          this.categoryService.getAll().then((data) => {
            this.categories = data;
            console.log("CATEGORIES");
            console.log(data);
            this.descriptionService.getAll().then((data) => {
              this.descriptionList = data;
              this.descriptions = data;
              if (this.equipment.category_ID != 0 && this.equipment.category_ID != -1) {
                this.descriptions = this.descriptionList.filter(d => {
                  return d.category_ID == this.equipment.category_ID;
                });
              }
            });

          });

    

          this.inspection.colour_Id = 1;
          this.inspection.inspected_At = moment().format("YYYY-MM-DD");
          let next_Inspection_Date = moment(this.inspection.inspected_At).add(this.equipment.inspection_Interval, 'M').format("YYYY-MM-DD");
          this.inspection.next_Inspection_Date = moment(next_Inspection_Date).format("YYYY-MM-DD");
          this.inspection.safe_For_Use = true;
          this.inspection.purpose_ID = 2
        });
      }
    });
  }

  statusChanged() {
    if (this.inspection.safe_For_Use) {
      let next_Inspection_Date = moment(this.inspection.inspected_At).add(this.equipment.inspection_Interval, 'M').format("YYYY-MM-DD");
      this.inspection.next_Inspection_Date = moment(next_Inspection_Date).format("YYYY-MM-DD");
    } else {
      this.inspection.next_Inspection_Date = moment(this.inspection.inspected_At).format("YYYY-MM-DD");
    }
  }

  dateChanged() {
    if (!this.inspection.safe_For_Use) {
      this.inspection.next_Inspection_Date = this.inspection.inspected_At;
    } else {
      let next_Inspection_Date = moment(this.inspection.inspected_At).add(this.equipment.inspection_Interval, 'M').format("YYYY-MM-DD");
      this.inspection.next_Inspection_Date = moment(next_Inspection_Date).format("YYYY-MM-DD");
    }
  }

  examChanged() {
    if (!this.inspection.first_Examination) {
      this.inspection.installed_Correctly = false;
    } 
  }

  immediateChanged() {
    if (this.inspection.immediate_To_Persons) {
      this.inspection.become_Danger_To_Persons = false;
      this.inspection.danger_Date = '';
      this.inspection.repair = '';
    } 
  }
  becomeChanged() {
    if (this.inspection.become_Danger_To_Persons) {
      this.inspection.immediate_To_Persons = false;

    } 
  }

  onDescriptionChange(value) {
    console.log(value);
    this.equipment.description = value;


  }
  onCategoryChange(value) {
    console.log(value);
    if (value == 0) { value = null; }

    this.descriptions = this.descriptionList.filter(c => {
      return value == null || c.category_ID == value;
    });
    this.equipment.description_ID = null;

  }
  passFail(row) {
    console.log(row);
    row.response = !row.response;
  }
  

  OnSubmit() {
    console.log("submitting");

    if ((this.equipment.serial_Num == null || this.equipment.serial_Num == undefined || this.equipment.serial_Num == '') &&
      (this.equipment.asset_Num == null || this.equipment.asset_Num == undefined || this.equipment.asset_Num == '')) {
      this.snotifyService.warning('Please enter a serial or asset number');
      return;
    }
    if (this.inspection.become_Danger_To_Persons == true && (this.inspection.danger_Date == null || this.inspection.danger_Date == undefined)) {

      alert('error: need to select when this defect could become a danger by.');
      return;
    }

    
    let status = "";

    if (this.inspection.safe_For_Use) {
      status = "PASS";
    } else {
      status = "FAIL";
    }

    var message = "";
    if (this.inspection.missing) {
      message = 'Are you sure you want to mark this item as missing?';
    } else {
      message = 'Are you sure this item is a ' + status + '?'
    }

    this.confirmationService.confirm({

      message: message,
      accept: () => {

        this.inspection.answered_Questions = this.rows;

        
        this.inspection.inspected_At = moment(this.inspection.inspected_At).format("YYYY-MM-DD");
        this.inspection.next_Inspection_Date = moment(this.inspection.next_Inspection_Date).format("YYYY-MM-DD");
        if (this.inspection.become_Danger_To_Persons == true) {
          this.inspection.danger_Date = moment(this.inspection.danger_Date).format("DD/MM/YYYY 00:00:00");
        }
        

        this.inspection.equipment = this.equipment;
        this.inspection.equipment.owner_ID = +this.inspection.equipment.owner_ID;
        this.inspection.colour_Id = +this.inspection.colour_Id;
        this.inspection.purpose_ID = +this.inspection.purpose_ID;
        this.inspection.PDA_User_ID = +this.inspection.PDA_User_ID;
        this.inspection.location_Inspection_ID = +this.inspection.location_Inspection_ID;

        console.log(this.inspection);
        this.inspectionService.save(this.inspection).then((data) => {
          this.router.navigate(['administration/jobs/inspect'], { queryParams: { id: this.inspection.location_Inspection_ID, from_Inspection: true } });

        });
      }
    });
  }

}
