import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '../../services/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';


enum State {
  login,
  forgottenPassword,
  resetPassword
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginModel: LoginModel = new LoginModel();
  loginMessage = '';

  showForgottenPassword = false;
  showReset = false;
  code;
  userId;

  currentState = State.login;

  //isLoading: boolean = false;
  invalid: boolean = false;

  loginForm = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    rememberMe: new FormControl(false),
  })

  resetForm = new FormGroup({
    token: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    confirmPassword: new FormControl('', Validators.required)
  });

  

  constructor(private authService: AuthService, private http: HttpClient, private auth: AuthService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      var token = params.token;
      if (token != null) {
        this.currentState = State.resetPassword;

        this.resetForm.patchValue({ 'token': token });
      }
    });

  }

  //login() {
  //  this.http.post('/api/account/login', this.loginModel).subscribe(data => {
  //    this.auth.setUser(data);
  //    this.router.navigate(['/dashboard']);
  //  }, error => {
  //    this.loginMessage = 'Invalid email or password';
  //  });
  //}

  toggleForgottenPassword() {
    this.showForgottenPassword = !this.showForgottenPassword;
  }

  resetPassword() {
    this.http.post(environment.apiUrl + '/api/account/resetpassword', this.loginModel).subscribe(data => {
      console.log('RESET');
    }, error => {
      this.loginMessage = 'Error resetting';
    });
  }

  confirmResetPassword() {
    if (this.loginModel.Password == '' || this.loginModel.Password == null) {
      this.loginMessage = 'Please enter password';
      return;
    }

    if (this.loginModel.Password != this.loginModel.Password2) {
      this.loginMessage = 'Passwords do not match';
      return;
    }

    let model = {
      Id: this.userId,
      Password: this.loginModel.Password,
      Code: this.code
    };

    this.http.post('/api/account/changepassword', model).subscribe(data => {

      this.showLogin();
    }, error => {
      this.loginMessage = 'Error resetting';
    });
  }

  showLogin() {
    this.showForgottenPassword = false;
    this.showReset = false;
  }


  async submit() {
    console.log("submit");
    //this.isLoading = true;
    this.invalid = false;
    console.log(this.loginModel);

    //var userModel = this.loginForm.value;


    //var loginDto = await this.authService.login(userModel.username, userModel.password, userModel.rememberMe);
    var loginDto = await this.authService.login(this.loginModel.Email, this.loginModel.Password, false);
    
    //this.isLoading = false;

    if (loginDto == null) {
      this.invalid = true;
    }

    this.router.navigate(['/dashboard']);
  }

}

export class LoginModel {
  Id: string;
  Email: string;
  Password: string;
  Password2: string;
  Code: string;
}
