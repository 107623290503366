export class Basket_Item {
  id: number;
  guid: string;
  description: string;
  type: string;
  serial_Num: string;
  category_Name: string;
  is_Annual: boolean;
  safe: boolean;
  category_Id: number;
  report_Type: number;
}
