export class Company {
  companyId = 0;
  companyName = '';
  address1 = '';
  address2 = '';
  address3 = '';
  address4 = '';
  postcode = '';
  county = '';
  telephone = '';
  fax = '';
  accNum = '';
  contactName = '';
  contactTelephone = '';
  contactEmail = '';
  depot_ID = 2;
  autoEmail = false;
  email = '';
  expired = false;
  
  is_edit: Boolean = false;
}
