import { Component, OnInit } from '@angular/core';
import { Page } from '../../../../models/paging/page';
import { Router, ActivatedRoute } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { ConfirmationService } from 'primeng/api';
import { EquipmentService } from '../../../../services/equipment.service';
import { ReportableService } from '../../../../services/reportable.service';
import { saveAs as importedSaveAs } from 'file-saver';

@Component({
  selector: 'app-equipment-files',
  templateUrl: './equipment-files.component.html',
  styleUrls: ['./equipment-files.component.css']
})
export class EquipmentFilesComponent implements OnInit {

  Equipment_Id: number;

  fileName: string;
  base64: string;

  page = new Page();
  rows = Array<any>();

  constructor(public router: Router, public route: ActivatedRoute, public equipmentService: EquipmentService,
    public reportableService: ReportableService, public snotifyService: SnotifyService,
    private confirmationService: ConfirmationService) {
    this.page.pageNumber = 0;
    this.page.size = 10;
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      if (id != undefined) {
        this.Equipment_Id = id;
        this.setPage({ offset: 0 });
      }
    });

  }

  changeListener(e): void {
    var rawBase64 = null;
    var base64 = null;

    // If a file was selected
    if (e.target.files.length > 0) {
      this.fileName = e.target.files[0].name;

      let file = e.target.files[0];
      var reader = new FileReader();

      reader.readAsDataURL(file);

      // Split the initial data type string from the base64 data
      reader.onload = (e) => {
        rawBase64 = reader.result;
        base64 = rawBase64.split(",", 2);
        this.base64 = base64[1];
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  }

  upload() {
    if (this.Equipment_Id != 0 && this.base64 != null && this.fileName != null) {
      this.equipmentService.uploadFile(this.Equipment_Id, this.base64, this.fileName).then((result) => {
        this.base64 = null;
        this.fileName = null;
        this.loadTable(this.page);
      }).catch((error) => {
        this.snotifyService.error(error);
      });

      (<HTMLInputElement>document.getElementById('filePicker')).value = '';
    }
  }

  remove(row) {
    let fileDetails = 'File Name: ' + row.file_Name + '<br/>';
    this.confirmationService.confirm({
      message: 'Are you sure that you want to remove this file?</br><br/>' + fileDetails,
      accept: () => {
        this.equipmentService.removeFile(row.equipment_Id, row.file_Name).subscribe(result => {
          this.snotifyService.success("Removal successful");
          this.loadTable(this.page);
        });
      }
    });
  }

  // ###################################
  // TABLE
  // ###################################
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page);
  }
  downloadReport(row) {
    console.log(row.file_Path);
    this.reportableService.getFile(row.file_Path).then((data) => {
      importedSaveAs(data, row.file_Name);
    });
  }
  loadTable(page: Page) {
    this.equipmentService.getFiles(page, this.Equipment_Id).then((pagedData) => {
      this.page = pagedData.page;
      this.rows = pagedData.data;
      console.log(this.rows);
    });
  }

}
