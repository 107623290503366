import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Tablet } from '../../../../models/tablet';
import { TabletService } from '../../../../services/tablet.service';

@Component({
  selector: 'app-tablet-manage',
  templateUrl: './tablet-manage.component.html',
  styleUrls: ['./tablet-manage.component.css']
})
export class TabletManageComponent implements OnInit {
  user: Tablet;

  constructor(public tabletService: TabletService, public router: Router, private route: ActivatedRoute) {
    this.user = new Tablet();
  }

  ngOnInit() {
    // Load our id if one exists
    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      if (id != undefined) {
        this.tabletService.get(id).then((data) => {
          this.user = data;
          this.user.is_edit = true;
          this.user.pin = this.user.android_PIN;
          this.user.pin2 = this.user.android_PIN;
        });
      }
    });
  }

  submit() {
    if (this.user.pin != this.user.pin2 || this.user.pin.length < 1) {
      alert('PINS DO NOT MATCH OR UNENTERED');
      return;
    }
    this.user.android_PIN = this.user.pin;
    this.tabletService.save(this.user).then((data) => {
      this.router.navigate(['users/tablet']);
    });
  }


}
