import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Timesheet_Day, Timesheet } from '../models/timesheet';
import { PagedData } from '../models/paging/paged-data';
import { Page } from '../models/paging/page';
import * as moment from 'moment';
import { ServerData } from '../models/paging/server-data';
import { environment } from '../../environments/environment';
import { ApiResponse } from '../models/api-response';
import { SnotifyService } from 'ng-snotify';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};
const timesheetUrl = 'api/timesheets';

@Injectable({
  providedIn: 'root'
})
export class TimesheetService {

  constructor(private http: HttpClient, private snotifyService: SnotifyService) { }

  getDay(id: string): Observable<Timesheet_Day> {
    const params = new HttpParams()
      .append('id', id);
    return this.http.get<Timesheet_Day>(timesheetUrl + '/getday', { params: params });
  }

  getTable(): Observable<PagedData<Timesheet[]>> {
    let params = new HttpParams();
    return this.http.get<PagedData<Timesheet[]>>(timesheetUrl + '/gettable', { params: params });
  }
  public getExcel(job_No: string, start_Date: string, end_Date: string, quickSearch: string, company_Id: number, location_Id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      let params = '?job_No=' + job_No;
      params += '&start_Date=' + moment(start_Date).format('YYYY-MM-DD');
      params += '&end_Date=' + moment(end_Date).format('YYYY-MM-DD');
      params += '&quickSearch=' + quickSearch;
      params += '&company_Id=' + company_Id;
      params += '&location_Id=' + location_Id;

      this.http.get<any>(environment.apiUrl + 'timesheets/getexcel' + params).subscribe(data => {
        let blob = this.converBase64toBlob(data, 'application/vnd.ms-excel');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getIndividulEntryExcel(tabletUser_Id: number, status: number, start_Date: string, end_Date: string, quickSearch: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let params = '?tabletUser_Id=' + tabletUser_Id;
      params += '&status=' + status;
      params += '&start_Date=' + moment(start_Date).format('YYYY-MM-DD');
      params += '&end_Date=' + moment(end_Date).format('YYYY-MM-DD');
      params += '&quickSearch=' + quickSearch;

      this.http.get<any>(environment.apiUrl + 'timesheets/getIndividualEntryexcel' + params).subscribe(data => {
        let blob = this.converBase64toBlob(data, 'application/vnd.ms-excel');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }


  public getResults(page: Page, tabletUser_Id: number, status: number, start_Date: string, end_Date: string, quickSearch: string): Promise<PagedData<any>> {
    const pagedData = new PagedData<any>();
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'Day'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'asc'; }


      let params = '&tabletUser_Id=' + tabletUser_Id;
      params += '&status=' + status;
      params += '&start_Date=' + moment(start_Date).format('YYYY-MM-DD');
      params += '&end_Date=' + moment(end_Date).format('YYYY-MM-DD');
      params += '&quickSearch=' + quickSearch;

      this.http.get<ServerData>(environment.apiUrl + 'timesheets/gettable?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params).subscribe(data => {
        page.totalElements = data.total;
        page.totalPages = page.totalElements / page.size;

        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }
  public getReportResults(page: Page, job_No: string, start_Date: string, end_Date: string, quickSearch: string, company_Id: number, location_Id: number): Promise<PagedData<any>> {
    const pagedData = new PagedData<any>();
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'Job_No'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'asc'; }


      let params = '&job_No=' + job_No;
      params += '&start_Date=' + moment(start_Date).format('YYYY-MM-DD');
      params += '&end_Date=' + moment(end_Date).format('YYYY-MM-DD');
      params += '&quickSearch=' + quickSearch;
      params += '&company_Id=' + company_Id;
      params += '&location_Id=' + location_Id;

      this.http.get<ServerData>(environment.apiUrl + 'timesheets/getreporttable?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params).subscribe(data => {
        page.totalElements = data.total;
        page.totalPages = page.totalElements / page.size;

        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }

  public getResultById(Id: string): Promise<PagedData<any>> {
    const pagedData = new PagedData<any>();
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      
      this.http.get<PagedData<any>>(environment.apiUrl + 'timesheets/getbyid?id=' + Id).subscribe(data => {
        console.log("service get");
        console.log(data);
        resolve(data);

      }, error => {
        reject();
      });
    });
  }

  amend(timesheet): Promise<ApiResponse> {
    //job.scheduled_Date = moment(job.scheduled_Date).add(-1, "months").format("YYYY/MM/DD");
    const headers = {
      'Content-Type': 'application/json',
    };
    console.log("Amend service");

    timesheet.arrived = timesheet.arrived.toString();
    timesheet.depart = timesheet.depart.toString();

    var arrivesplit = timesheet.arrived.split(":", 2);
    timesheet.arrived = arrivesplit[0].slice(-2) + ":" + arrivesplit[1]

    var departsplit = timesheet.depart.split(":", 2);
    timesheet.depart = departsplit[0].slice(-2) + ":" + departsplit[1]

    

    var dto = {
      /*      locationInspectionId: 0,*/
      id: timesheet.id,
      arrived: timesheet.arrived,
      depart: timesheet.depart,
      travel: timesheet.travel,
      hours: timesheet.hours,
      day: timesheet.day,
      reason: timesheet.reason,
      //day: moment(timesheet.day).format('DD/MM/YYYY').toString(),
      
    }

    console.log(dto);
    /*job.scheduled_Date = moment(job.scheduled_Date).format('YYYY/MM/DD');*/
    return new Promise((resolve, reject) => {


      this.http.post<ApiResponse>(environment.apiUrl + 'timesheets/amend' , dto, { headers: headers }).subscribe(data => {
        this.snotifyService.success('Successfully amended timesheet');
        resolve(data);
      }, (error) => {
        resolve(null)

      });

    });
  }

  approve(id): Promise<ApiResponse> {
    //job.scheduled_Date = moment(job.scheduled_Date).add(-1, "months").format("YYYY/MM/DD");
    const headers = {
      'Content-Type': 'application/json',
    };
    console.log("Approve service");
    console.log(id);


    /*job.scheduled_Date = moment(job.scheduled_Date).format('YYYY/MM/DD');*/
    return new Promise((resolve, reject) => {


      this.http.post<ApiResponse>(environment.apiUrl + 'timesheets/approve?id=' + id, { headers: headers }).subscribe(data => {
        this.snotifyService.success('Successfully approved timesheet');
        resolve(data);
      }, (error) => {
        resolve(null)

      });

    });
  }

  public getImages(timesheet_Id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>('api/timesheets/getimages?timesheet_Id=' + timesheet_Id).subscribe(data => {
        let blob = this.converBase64toBlob(data, 'application/zip');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  converBase64toBlob(content, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;
    let byteCharacters = window.atob(content); //method which converts base64 to binary
    let byteArrays = [
    ];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);
      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    let blob = new Blob(byteArrays, {
      type: contentType
    }); //statement which creates the blob
    return blob;
  }

}
