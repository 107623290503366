export class Location {
  locationId = 0;
  name = '';
  address1 = '';
  address2 = '';
  address3 = '';
  address4 = '';
  postcode = '';
  telephone = '';
  expired = false;
  email1 = '';
  email2 = '';
  county = '';
  accNum = '';
  companyName = '';
  fax = '';
  contact_Name = '';
  contact_Tel = '';
  autoEmail = false;
  colour_Required = false;

  monthlyInterval = 0;
  nextInspectionPeriod = 0;
  nextInspectionPeriodInterval = 0;
  companyId = 0;

  is_edit: Boolean = false;
}
