export class WebUser {
  userId;
  firstname = '';
  lastname = '';
  email = '';
  email2 = '';
  password = '';
  password2 = '';
  userType = 1;
  depot_ID = 1;
  is_edit = false;
  company_Id: number;
}
