import { Component, OnInit } from '@angular/core';
import { Company } from '../../../models/company';
import { Location } from '../../../models/location';
import { Tablet } from '../../../models/tablet';

import { saveAs as importedSaveAs } from 'file-saver';

import * as moment from 'moment';
import { Page } from '../../../models/paging/page';
import { AuthService } from '../../../services/auth.service';
import { CompanyService } from '../../../services/company.service';
import { LocationService } from '../../../services/location.service';
import { TabletService } from '../../../services/tablet.service';
import { ReportableService } from '../../../services/reportable.service';
import { Router } from '@angular/router';
import { BasketService } from '../../../services/basket.service';
import { User } from '../../../models/user';
//import { LoadTestService } from '../../../services/loadtest.service';
import { JobService } from '../../../services/job.service';

@Component({
  selector: 'app-load-test',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.css']
})
export class JobComponent implements OnInit {

  companies: Company[];
  locations: Location[];
  locationList: Location[];
  users: Tablet[];

  filter: any = {
    company_Id: 0,
    location_Id: 0,
    tabletUser_Id: '',
    date_Range: 1,
    start_Date: moment().add(-1, 'months').toDate(),
    end_Date: moment().toDate(),
    serial_Num: '',
    job_Num: ''
  };

  page = new Page();
  rows = Array<any>();

  is_client = false;
  client;
  current_User: User;

  constructor(authService: AuthService, companyService: CompanyService, locationService: LocationService,
    tabletService: TabletService, public reportableService: ReportableService, public router: Router, public basketService: BasketService, public jobService: JobService) {
    this.is_client = authService.currentUser.value.userType == 0;
    this.client = authService.currentUser.value;
    this.current_User = authService.currentUser.value;

    this.page.pageNumber = 0;
    this.page.size = 10;
    this.page.sortOrder = 'desc';
    this.page.sortBy = "Inspected_At";


    companyService.getAll().then((data) => {
      this.companies = data;

      if (this.is_client) {
        this.filter.company_Id = authService.currentUser.value.company_Id;
        this.filter.location_Id = authService.currentUser.value.location_Id;

        if (this.filter.location_Id == null) {
          this.filter.location_Id = undefined;
        }
      }

      locationService.getAll().then((data) => {
        //this.locationList = data;
        //this.locations = this.locationList;

        //if (this.is_client) {
        //  if (this.current_User.locations.length > 0) {
        //    this.locations = this.locationList.filter(l => this.current_User.locations.some(item => item.location_Id == l.id));
        //  } else {
        //    this.locations = this.locationList.filter(c => {
        //      return c.company_Id == this.filter.company_Id;
        //    });
        //  }
        //}
        //this.loadTable(this.page);

      });
    });


    //tabletService.getAll().then((data) => {
    //  this.users = data;
    //});
  }

  ngOnInit() {
    //this.loadTable(this.page);
  }

  onCompanyChange(value) {
    this.filter.location_Id = 0;
    if (value == 0) { value = null; }

    this.locations = this.locationList.filter(c => {
      return value == null || c.companyId == value;
    });
  }


  // ###################################
  // TABLE
  // ###################################
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page);
  }

  loadTable(page: Page) {
    //this.jobService.getReportResults(this.page, this.filter.company_Id, this.filter.location_Id,
    //  this.filter.tabletUser_Id, this.filter.start_Date, this.filter.end_Date, this.filter.serial_Num, 1, this.filter.job_Num).then((pagedData) => {
    //    this.page = pagedData.page;
    //    this.rows = pagedData.data;
    //    console.log(this.rows);
    //    this.refreshbasketrow();
    //  });
  }
  //downloadJobSheet(id: string, JobNo: string) {
  //  this.reportableService.getJobSheetROTE(id).then((data) => {
  //    importedSaveAs(data, 'JS-' + JobNo + '.pdf');
  //  });
  //}
  //downloadReport(id: number, JobNo: string) {
  //  this.reportableService.getJobJob(id).then((data) => {
  //    importedSaveAs(data, 'J-' + JobNo + '.pdf');
  //  });
  //}

  

  dateChange(ev) {
    if (ev == -1) {
      return;
    }

    let date = moment();
    if (ev == 0) {
      date = date.subtract(1, 'weeks');
    } else {
      date = date.subtract(ev, 'months');
    }

    let jsDate = date.toDate();
    //var startDate: NgbDateStruct = { year: jsDate.getFullYear(), month: jsDate.getMonth() + 1, day: jsDate.getDate() };
    this.filter.start_Date = jsDate;
  }



  // ###################################
  // BASKET
  // ###################################
  addToBasket(item, type) {
    if (type == 1)
      this.basketService.addROTEtoBasket(item);
    else if (type == 2)
      this.basketService.addServicetoBasket(item);
    else if (type == 3)
      this.basketService.addRAMStoBasket(item);
    else if (type == 4)
      this.basketService.addESTRtoBasket(item);

  }

  removeFromBasket(item, type) {
    if (type == 1)
      this.basketService.removeROTE(item);
    else if (type == 2)
      this.basketService.removeService(item);
    else if (type == 3)
      this.basketService.removeRAMS(item);
    else if (type == 4)
      this.basketService.removeESTR(item);

  }

  resetItem(item) {
    item.addedrote = false;
    item.addedrams = false;
    item.addedestr = false;
  }

  resetItems() {
    for (let item of this.rows) {
      this.resetItem(item);
    }
  }

  basketCompleted(data) {
    this.resetItems();
  }

  basketClearing(data) {
    this.resetItems();
  }

  addAllToBasket() {
    //this.jobService.getReportResults(this.page, this.filter.company_Id, this.filter.location_Id,
    //  this.filter.tabletUser_Id, this.filter.start_Date, this.filter.end_Date, this.filter.serial_Num, 1, this.filter.job_Num).then((pagedData) => {
    //    //for (let i = 0; i < pagedData.length; i++) {
    //    //  const jsonObj = pagedData[i];
    //    //  console.log(jsonObj);
    //    //  if (jsonObj.id != null) {
    //    //    if (jsonObj.ramS_Id != null && jsonObj.ramS_Id != undefined)
    //    //      this.addToBasket(jsonObj, 3);
    //    //    if (jsonObj.engineer_Report_Id != null && jsonObj.engineer_Report_Id != undefined)
    //    //      this.addToBasket(jsonObj, 6);
    //    //    if (jsonObj.next_Inspection_Date != 'N/A')
    //    //      this.addToBasket(jsonObj, 1);
    //    //  }
    //    //}
    //    this.refreshbasketrow();
    //  });
  }

  refreshbasketrow() {
    console.log("REFRESHING");
    var items = this.basketService.getitems();
    for (let i = 0; i < (this.rows.length); i++) {
      let row = this.rows[i];
      for (let k = 0; k < (items.value.length); k++) {
        let item = items.value[k];
        if (item.report_Type == 1) { //ROTE
          if ((item.description == row.description) && (item.serial_Num == row.serial_Num)) {
            this.rows[i].addedrote = true;
          }
        } else if (item.report_Type == 2) { //SERVICE
          if ((item.description == row.description) && (item.serial_Num == row.serial_Num)) {
            this.rows[i].addedservice = true;
          }
        } else if (item.report_Type == 3) { //RAMS
          if ((item.guid == row.ramS_Id) || (item.guid == row.location_Inspection_Id)) {
            this.rows[i].addedrams = true;
          }
        } else if (item.report_Type == 4) { //ERSTR
          if ((item.description == row.engineer) && (item.serial_Num == row.job_No)) {
            this.rows[i].addedestr = true;
          }
        }
        else if (item.report_Type == 6) { //ERSTR ROTE
          if ((item.description == row.engineer) && (item.serial_Num == row.job_No)) {
            this.rows[i].addedestr = true;
          }
        }
        else if (item.report_Type == 7) { //ERSTR SERVICE
          if ((item.description == row.engineer) && (item.serial_Num == row.job_No)) {
            this.rows[i].addedestr = true;
          }
        }
      }
    }
  }


  basketOnclickEvent(ev) {
    console.log(ev);
    console.log("CLICKED");
    //   this.refreshbasketrow();
    for (let i = 0; i < (this.rows.length); i++) {
      let row = this.rows[i];
      if (ev.report_Type == 1) { //ROTE
        if ((ev.description == row.description) && (ev.serial_Num == row.serial_Num)) {
          this.rows[i].addedrote = false;
        }
      } else if (ev.report_Type == 2) { //SERVICE
        if ((ev.description == row.description) && (ev.serial_Num == row.serial_Num)) {
          this.rows[i].addedservice = false;
        }
      } else if (ev.report_Type == 3) { //RAMS
        if ((ev.guid == row.ramS_Id) || (ev.guid == row.location_Inspection_Id)) {
          this.rows[i].addedrams = false;
        }
      } else if (ev.report_Type == 4) { //ERSTR
        if ((ev.description == row.engineer) && (ev.serial_Num == row.job_No)) {
          this.rows[i].addedestr = false;
        }
      }
    }
  }
}
