import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../services/auth.service';
import * as moment from 'moment';
import { PartService } from '../../../../services/parts.service';
import { ERSTRService } from '../../../../services/erstr.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class CreateComponent implements OnInit {
  report: any = {
    crane_No: "",
    serial_No: "",
    equipment: "",
    job_No: "",
    engineer_Name: "",
    applicationUser_Id: "",
    works_Carried_Out: "",
    timesheets: [],
    parts: [],
    further_Works: "",
    submitted_At: moment().toDate(),
    site: "",
    location: "",
  };

  parts;
  selectedPart_Id = 0;
  isReadOnly = false;
  editing = false;

  constructor(private authService: AuthService, private partService: PartService, private erstrService: ERSTRService,
    private router: Router, private snotifyService: SnotifyService, public route: ActivatedRoute) {

    var user = authService.currentUser.value;
    this.report.engineer_Name = user.firstname + ' ' + user.lastname;
    this.report.applicationUser_Id = user.id;

    // initialise the timesheets
    this.report.timesheets.push({ day: 'Monday' });
    this.report.timesheets.push({ day: 'Tuesday' });
    this.report.timesheets.push({ day: 'Wednesday' });
    this.report.timesheets.push({ day: 'Thursday' });
    this.report.timesheets.push({ day: 'Friday' });
    this.report.timesheets.push({ day: 'Saturday' });
    this.report.timesheets.push({ day: 'Sunday' });

    partService.get().then((data) => {
      this.parts = data;
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      if (id != undefined) {
        this.editing = false;
        this.isReadOnly = true;

        if (params['editing'] != undefined) {
          // we are only passing in editing once, and that is when we want to edit so we can just set these variables
          this.editing = true;
          this.isReadOnly = false;
        }

        this.erstrService.get(id).then((data) => {          
          this.report.id = data.id;
          this.report.crane_No = data.crane_No;
          this.report.equipment = data.equipment;
          this.report.serial_No = data.serial_No;
          this.report.job_No = data.job_No;
          this.report.engineer_Name = data.tabletUser != null ? data.tabletUser.firstname + ' ' + data.tabletUser.lastname : data.applicationUser.firstname + ' ' + data.applicationUser.lastname;
          this.report.applicationUser_Id = data.applicationUser_Id;
          this.report.works_Carried_Out = data.works_Carried_Out;
          this.report.tabletUser_Id = data.tabletUser_Id;
          this.report.timesheets = data.timesheets;
          this.report.parts = data.parts;
          this.report.further_Works = data.further_Works;
          this.report.submitted_At = moment(data.submitted_At).toDate();
        });
      }
    });
  }
  removePart(part) {
    if (part.removed == undefined || !part.removed)
      part.removed = true;
    else
      part.removed = false;
  }
  addPart() {
    if (this.selectedPart_Id == 0) {
      return;
    }

    let part = this.parts.find(a => a.id == this.selectedPart_Id);
    let newPart = {
      id: 0,
      //part: part, //Was coming in the wrong way around
      //part_Id: this.selectedPart_Id
      part: part,
      part_Id: part.id
    };

    console.log(newPart);

    this.report.parts.push(newPart);
  }

  submit() {
    //if (this.report.job_No == null || this.report.job_No == "") {
    //  this.snotifyService.warning("Please enter job no");
    //  return;
    //}

    if (!this.editing) {
      if (this.report.location == null || this.report.location == "") {
        this.snotifyService.warning("Please enter location");
        return;
      }

      if (this.report.site == null || this.report.site == "") {
        this.snotifyService.warning("Please enter site");
        return;
      }
    }

    if (this.editing) {
      console.log(this.report);

      this.erstrService.update(this.report).then((data) => {
        this.router.navigate(['reports/erstr']);
      });
    } else {
      this.erstrService.save(this.report).then((data) => {
        this.router.navigate(['reports/erstr']);
      });
    }

  }

}
