import { Tablet } from './tablet';

export class Location_Inspection {
  location_Inspection_ID = 0;
  company_Id = '';
  company = '';
  location = '';
  price = '';
  locationId = 0;
  jobNo = '';
  purchaseOrder = '';
  startTime;
  endTime;
  jobType = 1;
  completed: boolean;
  fullDay: boolean;
  halfDay: boolean;
  otherDay: boolean;
  notes: string;
  engineerIds: Tablet[] = [];
  scheduled_Date;
  scheduled_End_Date;
  contactName;
  contactDetails;
  officeContactName;
  officeContactDetails;
  jobSummary;
  booked_By;
  is_edit = false;
  //engineerIds: number[] = []
  colour_ID: number;
  includeEquip = true;
  //client_Id: string;
}
