import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Page } from '../../../models/paging/page';
import { Tablet } from '../../../models/tablet';
import { TabletService } from '../../../services/tablet.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { ColumnService } from '../../../services/column.service';
import { AuthService } from '../../../services/auth.service';
import { StorageService } from '../../../services/storage.service';

@Component({
  selector: 'app-tablet',
  templateUrl: './tablet.component.html',
  styleUrls: ['./tablet.component.css']
})
export class TabletComponent implements OnInit {

  page = new Page();
  rows = Array<any>();
  init: boolean;

  public readonly pageLimitOptions = [
    { value: 5 },
    { value: 10 },
    { value: 25 },
    { value: 50 },
    { value: 100 },
  ];


  public currentPageLimit: number = 10;

  filter: any = {
    expired: -1,
    name: '',
    quickSearch: '',
  };

  toggleOpen = false;
  columns = [
    { name: 'First Name', prop: 'firstname' },
    { name: 'Last Name', prop: 'lastname' },
    { name: 'Expired', prop: 'expired_Formatted' },
    { name: 'Created', prop: 'created_Date_Formatted' },
  ];

  allColumns = [
    { name: 'First Name', prop: 'firstname' },
    { name: 'Last Name', prop: 'lastname' },
    { name: 'Expired', prop: 'expired_Formatted' },
    { name: 'Created', prop: 'created_Date_Formatted' },
  ];




  toggle(col) {
    const isChecked = this.isChecked(col);

    if (isChecked) {
      this.columns = this.columns.filter(c => {
        return c.name !== col.name;
      });
    } else {
      this.columns = [...this.columns, col];
    }
    this.toggleSelection();
  }

  isChecked(col) {
    return (
      this.columns.find(c => {
        return c.name === col.name;
      }) !== undefined
    );
  }

  toggleSelection() {
    this.toggleOpen = !this.toggleOpen;
  }

  constructor(public tabletService: TabletService, private authService: AuthService,

    private columnService: ColumnService, public router: Router, private storageService: StorageService) {
    this.page.pageNumber = 0;
    this.page.size = 10;
  }

  ngOnInit() {
    var page = JSON.parse(this.storageService.get("tablet_page"));
    var filter = JSON.parse(this.storageService.get("tablet_filters"));
    if (page != undefined) {
      this.page = page;
      console.log(this.page);
    }
    if (filter != undefined) {
      this.filter = filter;
    }
    if (page == undefined && filter == undefined) {
      console.log("no filters");
      this.setPage({ offset: 0 });
      this.init = true;
      this.resetFilters();
    } else {
      this.currentPageLimit = this.page.size;
      this.loadTable(this.page, false);
    }
  }
  resetFilters() {
    this.filter = {
      expired: -1,
      name: '',
      quickSearch: '',
    };
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.currentPageLimit = this.page.size;
  }


  edit(row) {
    this.router.navigate(['users/tablet/manage'], { queryParams: { id: row.pdA_User_ID } });
  }

  // ###################################
  // TABLE
  // ###################################
  public onLimitChange(limit: any): void {
    this.page.size = parseInt(limit, 10);
    this.loadTable(this.page, false);
  }
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page, false);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page,false);
  }

  loadTable(page: Page, clicked) {
    if (clicked) {
      this.page.pageNumber = 0;
      this.currentPageLimit = this.page.size;
      this.setPage({ offset: 0 });
      return;
    }
    this.storageService.dataSave("tablet_filters", JSON.stringify(this.filter));
    this.storageService.dataSave("tablet_page", JSON.stringify(this.page));
    this.tabletService.getResults(this.page, this.filter.name, this.filter.expired, this.filter.quickSearch).then((pagedData) => {
      this.page = pagedData.page;
      this.rows = pagedData.data;
      //console.log(this.rows);
      if (this.init) {
        this.gotoTop();
        this.init = false;
      }
    });
    this.getcolumns();
  }

  getcolumns() {
    this.columnService.getColumns(this.authService.currentUser.value.userId, "tablet").then((data) => {
      if (data.columns != null) {
        this.columns = this.columns.filter(c => {
          return data.columns.includes(c.name);
        });
        this.toggleOpen = false;
      }

    });
  }
  SaveSelection() {
    let result = this.columns.map(a => a.name);
    this.columnService.save(this.authService.currentUser.value.userId, "tablet", result).then((data) => {
    });
  }
  generateExcel() {
    this.tabletService.getExcel(this.filter.name, this.filter.expired, this.filter.quickSearch).then((data) => {
      importedSaveAs(data, 'Report.xlsx');
    });
  }
  gotoTop() {
    console.log("GOTO TOP");
    // window.scrollTo(-9999,-99999);
    let top = document.getElementById('top');
    console.log(top);
    if (top !== null) {
      top.scrollIntoView(true);
      //  document.getElementById("top").scrollTop -= 99999;
      top = null;
    }
  }
}
