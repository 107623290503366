import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { NgModule, Pipe } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { CountToModule } from '../app/controls/count-to.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateAdapter, NgbDateStruct, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { NgSelectModule } from '@ng-select/ng-select';
import 'hammerjs';
import 'mousetrap';
import { ModalGalleryModule } from '@ks89/angular-modal-gallery';
import { CalendarModule as PrimePicker } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';

import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { AuthService } from '../app/services/auth.service';


import { LoginComponent } from './pages/login/login.component';
import { JobComponent } from './pages/reports/job/job.component';



/*import { LoadingComponent } from './components/loading/loading.component';*/

import { RoleGuard } from '../app/services/role.guard';
import { UserGuard } from '../app/services/user.guard';
import { CookieService } from 'ngx-cookie-service';
import { AssetRegisterService } from './services/assetregister.service';
import { CategoryService } from './services/category.service';
import { VehicleService } from './services/vehicle.service';
import { ManufacturerService } from './services/manufacturer.service';
import { OwnerService } from './services/owner.service';
import { TabletService } from './services/tablet.service';
import { ReportableService } from './services/reportable.service';
import { CompanyService } from './services/company.service';
import { LocationService } from './services/location.service';
import { EquipmentService } from './services/equipment.service';
import { JobService } from './services/job.service';
import { InspectionService } from './services/inspection.service';
import { BreakdownService } from './services/breakdown.service';
import { ServiceInspectionService } from './services/service-inspection.service';
import { InstallationService } from './services/installation.service';
import { BreakdownInspectionService } from './services/breakdown-inspection.service';
import { PartService } from './services/parts.service';
import { ColourService } from './services/colour.service';
import { RAMSService } from './services/rams.service';
import { ERSTRService } from './services/erstr.service';
import { MassUploadService} from './services/massupload.service';
import { RemindersService } from './services/reminders.service';
import { BasketService } from './services/basket.service';
import { DescriptionsService } from './services/descriptions.service';
import { QuestionSetService } from './services/question-set.service';
import { ColumnService } from './services/column.service';
import { StorageService } from './services/storage.service';
import { ModalService } from './services/modal.service'

import { LayoutComponent } from '../app/layout/layout.component';
import { PopUpModalComponent } from '../app/components/popup-modal/popup-modal.component';
import { UserComponent } from './layout/user/user.component';

import { CategoriesComponent } from './pages/administration/categories/categories.component';
import { DescriptionsComponent } from './pages/administration/descriptions/descriptions.component';
import { QuestionSetComponent } from './pages/administration/question-set/question-set.component'
import { VehicleComponent } from './pages/administration/vehicle/vehicle.component'
import { VehicleManageComponent} from './pages/administration/vehicle/vehicle-manage/vehicle-manage.component'
import { CategoryManageComponent } from './pages/administration/categories/category-manage/category-manage.component';
import { DescriptionsManageComponent } from './pages/administration/descriptions/descriptions-manage/descriptions-manage.component';
import { ManufacturersComponent } from './pages/administration/manufacturers/manufacturers.component';
import { ManufacturerManageComponent } from './pages/administration/manufacturers/manufacturer-manage/manufacturer-manage.component';
import { TabletComponent } from './pages/administration/tablet/tablet.component';
import { TabletManageComponent } from './pages/administration/tablet/tablet-manage/tablet-manage.component';
import { WebusersComponent } from './pages/administration/webusers/webusers.component';
import { WebUserService } from './services/webUser.service';
import { WebManageComponent } from './pages/administration/webusers/web-manage/web-manage.component';
import { CompaniesComponent } from './pages/administration/companies/companies.component';
import { CompanyManageComponent } from './pages/administration/companies/company-manage/company-manage.component';
import { LocationsComponent } from './pages/administration/locations/locations.component';
import { LocationManageComponent } from './pages/administration/locations/location-manage/location-manage.component';
import { EquipmentComponent } from './pages/administration/equipment/equipment.component';
import { EquipmentManageComponent } from './pages/administration/equipment/equipment-manage/equipment-manage.component';
import { JobManagementComponent } from './pages/administration/job-management/job-management.component';
import { JobManageComponent } from './pages/administration/job-management/job-manage/job-manage.component';
import { JobInspectComponent } from './pages/administration/job-management/inspect/job-inspect.component';
import { InspectionsComponent } from './pages/reports/inspections/inspections.component';
import { InspectionEditComponent } from './pages/reports/inspections/inspection-edit/inspection-edit.component';
import { VehicleInspectionsComponent } from './pages/reports/vehicle-inspections/vehicle-inspections.component';
import { VehicleInspectionEditComponent } from './pages/reports/vehicle-inspections/vehicle-inspection-edit/vehicle-inspection-edit.component';
import { ServiceInspectionsComponent } from './pages/reports/service-inspections/service-inspections.component';
import { InstallationInspectionsComponent } from './pages/reports/installation-inspections/installation-inspections.component';
import { BreakdownInspectionsComponent } from './pages/reports/breakdown-inspections/breakdown-inspections.component';
import { RamsComponent } from './pages/reports/rams/rams.component';
import { JobApprovalComponent } from './pages/administration/job-approval/job-approval.component';
import { JobDetailsComponent } from './pages/administration/job-approval/job-details/job-details.component';
import { ErstrComponent } from './pages/reports/erstr/erstr.component';
import { AssetRegisterComponent } from './pages/reports/assetregister/assetregister.component';
import { MassUploadComponent } from './pages/administration/massupload/massupload.component';
import { CreateComponent as ErstrCreateComponent } from './pages/reports/erstr/create/create.component';
import { JobAmendComponent as JobAmendComponent } from './pages/administration/job-approval/job-amend/job-amend.component';
import { JobResolver } from './resolvers/job-resolver';
import { TimesheetComponent } from './pages/administration/timesheet/timesheet.component';
import { TimesheetReportComponent } from './pages/reports/timesheet-report/timesheet-report.component';
import { TimesheetAmendComponent } from './pages/administration/timesheet/amend/timesheet-amend.component';
import { ReminderComponent } from './pages/reports/reminder/reminder.component';
import { FilterService } from './services/filter.service';
import { BasketComponent } from './pages/basket/basket.component';
import { BreakdownInspectionsReportComponent } from './pages/reports/breakdown-inspections-report/breakdown-inspections-report.component';
import { ApiHttpInterceptor } from './services/api-http-interceptor';
import { EquipmentFilesComponent } from './pages/administration/equipment/equipment-files/equipment-files.component';
import { InspectAssetComponent } from './pages/administration/job-management/inspect-asset/inspect-asset.component';




@Pipe({ name: 'safeHtml' })
export class Safe {
  constructor(private sanitizer: DomSanitizer) { }

  transform(style) {
    console.log(style);
    //return this.sanitizer.bypassSecurityTrustStyle(style);
    return this.sanitizer.bypassSecurityTrustHtml(style);
    // return this.sanitizer.bypassSecurityTrustXxx(style); - see docs
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    LayoutComponent,
    UserComponent,
    CategoriesComponent,
    DescriptionsComponent,
    VehicleManageComponent,
    VehicleComponent,
    QuestionSetComponent,
    CategoryManageComponent,
    DescriptionsManageComponent,
    ManufacturersComponent,
    ManufacturerManageComponent,
    TabletComponent,
    TabletManageComponent,
    WebusersComponent,
    WebManageComponent,
    Safe,
    CompaniesComponent,
    CompanyManageComponent,
    LocationsComponent,
    LocationManageComponent,
    EquipmentComponent,
    EquipmentManageComponent,
    EquipmentFilesComponent,
    JobManagementComponent,
    JobManageComponent,
    JobInspectComponent,
    InspectionsComponent,
    InspectionEditComponent,
    VehicleInspectionsComponent,
    VehicleInspectionEditComponent,
    ServiceInspectionsComponent,
    InstallationInspectionsComponent,
    BreakdownInspectionsComponent,
    RamsComponent,
    JobApprovalComponent,
    JobDetailsComponent,
    TimesheetComponent,
    TimesheetReportComponent,
    TimesheetAmendComponent,
    MassUploadComponent,
    ErstrComponent,
    JobAmendComponent,
    ErstrCreateComponent,
    ReminderComponent,
    AssetRegisterComponent,
    BasketComponent,
    BreakdownInspectionsReportComponent,
    JobComponent,
    PopUpModalComponent,
    InspectAssetComponent
    //LoadingComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    NgxDatatableModule,
    NgxChartsModule,
    HttpClientModule,
    NgHttpLoaderModule,
    FormsModule,
    ReactiveFormsModule,
    CountToModule,
    SnotifyModule,
    ModalGalleryModule.forRoot(),
    PrimePicker,
    ConfirmDialogModule,
    
    NgbModule.forRoot(),
    NgSelectModule,
    RouterModule.forRoot([
      { path: '', redirectTo: '/dashboard', pathMatch: 'full', canActivate: [RoleGuard] },
      { path: 'login', component: LoginComponent },
      { path: 'dashboard', component: HomeComponent, canActivate: [RoleGuard]  },
      {
        path: 'reports', canActivate: [RoleGuard], children: [
          { path: 'assetregister', component: AssetRegisterComponent },
          {
            path: 'inspections', children: [
              { path: '', component: InspectionsComponent },
              { path: 'edit', component: InspectionEditComponent },
              { path: 'edit/:id', component: InspectionEditComponent }
            ]
          },

          { path: 'serviceinspections', component: ServiceInspectionsComponent },
          { path: 'installationinspections', component: InstallationInspectionsComponent },
          { path: 'breakdowninspections', component: BreakdownInspectionsComponent },
          { path: 'breakdowninspectionsreport', component: BreakdownInspectionsReportComponent },
          { path: 'rams', component: RamsComponent },
          { path: 'reminders', component: ReminderComponent },
          { path: 'jobjob', component: JobComponent },
          { path: 'timesheetreport', component: TimesheetReportComponent },
          {
            path: 'erstr', children: [
              { path: '', component: ErstrComponent },
              { path: 'manage', component: ErstrCreateComponent },
              { path: 'manage/:id', component: ErstrCreateComponent }
            ]
          },
          {
            path: 'vehicleinspections', children: [
              { path: '', component: VehicleInspectionsComponent },
              { path: 'edit', component: VehicleInspectionEditComponent },
              { path: 'edit/:id', component: VehicleInspectionEditComponent }
            ]
          },
        ]
      },
      {
        path: 'users', canActivate: [RoleGuard], children: [
          {
            path: 'tablet', children: [
              { path: '', component: TabletComponent },
              { path: 'manage', component: TabletManageComponent },
              { path: 'manage/:id', component: TabletManageComponent }
            ]
          },
          {
            path: 'web', children: [
              { path: '', component: WebusersComponent },
              { path: 'manage', component: WebManageComponent },
              { path: 'manage/:id', component: WebManageComponent }
            ]
          }
        ]
      },
      {
        path: 'administration', canActivate: [RoleGuard], children: [
          {
            path: 'jobs', children: [
              { path: '', component: JobManagementComponent },
              { path: 'manage', component: JobManageComponent },
              { path: 'manage/:id', component: JobManageComponent },
              { path: 'inspect', component: JobInspectComponent },
              { path: 'inspect/:id', component: JobInspectComponent },
              { path: 'inspect-asset', component: InspectAssetComponent },
              { path: 'inspect-asset/:id', component: InspectAssetComponent },
            ]
          },
          {
            path: 'approval', children: [
              { path: '', component: JobApprovalComponent },
              { path: 'details', component: JobDetailsComponent },
              { path: 'details/:id', component: JobDetailsComponent, resolve: { job: JobResolver } },
            ]
          },
          {
            path: 'companies', children: [
              { path: '', component: CompaniesComponent },
              { path: 'manage', component: CompanyManageComponent },
              { path: 'manage/:id', component: CompanyManageComponent }
            ]
          },
          {
            path: 'locations', children: [
              { path: '', component: LocationsComponent },
              { path: 'manage', component: LocationManageComponent },
              { path: 'manage/:id', component: LocationManageComponent }
            ]
          },
          {
            path: 'equipment', children: [
              { path: '', component: EquipmentComponent },
              { path: 'manage', component: EquipmentManageComponent },
              { path: 'manage/:id', component: EquipmentManageComponent },
              { path: 'files', component: EquipmentFilesComponent },
              { path: 'files/:id', component: EquipmentFilesComponent }
            ]
          },
          {
            path: 'categories', children: [
              { path: '', component: CategoriesComponent },
              { path: 'manage', component: CategoryManageComponent },
              { path: 'manage/:id', component: CategoryManageComponent }
            ]
          },
          {
            path: 'descriptions', children: [
              { path: '', component: DescriptionsComponent },
              { path: 'manage', component: DescriptionsManageComponent },
              { path: 'manage/:id', component: DescriptionsManageComponent }
            ]
          },
          {
            path: 'vehicle', children: [
              { path: '', component: VehicleComponent },
              { path: 'manage', component: VehicleManageComponent },
              { path: 'manage/:id', component: VehicleManageComponent }
            ]
          },
          {
            path: 'question-set', children: [
              { path: '', component: QuestionSetComponent },
            ]
          },
          {
            path: 'manufacturers', children: [
              { path: '', component: ManufacturersComponent },
              { path: 'manage', component: ManufacturerManageComponent },
              { path: 'manage/:id', component: ManufacturerManageComponent }
            ]
          },
          {
            path: 'timesheets', children: [
              { path: '', component: TimesheetComponent },
              { path: 'amend', component: TimesheetAmendComponent },
              { path: 'amend/:id', component: TimesheetAmendComponent }
            ]
          },
          {
            path: 'massupload', children: [
              { path: '', component: MassUploadComponent }
            ]
          }
        ]
      },
    ])
  ],
  providers: [
    CookieService,
    AuthService,
    RoleGuard,
    UserGuard,
    CategoryService,
    VehicleService,
    ManufacturerService,
    OwnerService,
    TabletService,
    ReportableService,
    WebUserService,
    CompanyService,
    LocationService,
    EquipmentService,
    InspectionService,
    BreakdownService,
    ServiceInspectionService,
    InstallationService,
    BreakdownInspectionService,
    JobService,
    PartService,
    ColourService,
    RAMSService,
    ERSTRService,
    MassUploadService,
    FilterService,
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults },
    SnotifyService,
    ConfirmationService,
    JobResolver,
    RemindersService,
    CookieService,
    BasketService,
    DescriptionsService,
    QuestionSetService,
    ColumnService,
    StorageService,
    ModalService,
    AssetRegisterService,
    { provide: HTTP_INTERCEPTORS, useClass: ApiHttpInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export class NgbdDatepickerAdapter {

  model1: Date;
  model2: Date;

  get today() {
    return new Date();
  }
}


