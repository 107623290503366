import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Timesheet } from '../../../models/timesheet';
import { TimesheetService } from '../../../services/timesheet.service';
import { Tablet } from '../../../models/tablet';
import { Page } from '../../../models/paging/page';
import { TabletService } from '../../../services/tablet.service';

import { saveAs as importedSaveAs } from 'file-saver';
import { AuthService } from '../../../services/auth.service';
import { FilterService } from '../../../services/filter.service';
import { ReportableService } from '../../../services/reportable.service';
import { ConfirmationService } from 'primeng/components/common/confirmationservice';
import { ColumnService } from '../../../services/column.service';
import { StorageService } from '../../../services/storage.service';

@Component({
  selector: 'app-timesheet',
  templateUrl: './timesheet.component.html',
  styleUrls: ['./timesheet.component.css']
})
export class TimesheetComponent implements OnInit {

  page = new Page();
  rows: any[];
  users: Tablet[];
  user;
  is_admin = false;

  public readonly pageLimitOptions = [
    { value: 5 },
    { value: 10 },
    { value: 25 },
    { value: 50 },
    { value: 100 },
  ];

  public currentPageLimit: number = 10;

  toggleOpen = false;
  columns = [
    { name: 'Engineer', prop: 'engineer' },
    { name: 'Day', prop: 'day' },
    { name: 'Job No', prop: 'job_No' },
    { name: 'Company', prop: 'company' },
    { name: 'Location', prop: 'location' },
    { name: 'Arrived', prop: 'arrived' },
    { name: 'Depart', prop: 'depart' },
    { name: 'Travel', prop: 'travel' },
    { name: 'Hours', prop: 'hours' },
    { name: 'Status', prop: 'status_Formatted' },
  ];

  allColumns = [
    { name: 'Engineer', prop: 'engineer' },
    { name: 'Day', prop: 'day' },
    { name: 'Job No', prop: 'job_No' },
    { name: 'Company', prop: 'company' },
    { name: 'Location', prop: 'location' },
    { name: 'Arrived', prop: 'arrived' },
    { name: 'Depart', prop: 'depart' },
    { name: 'Travel', prop: 'travel' },
    { name: 'Hours', prop: 'hours' },
    { name: 'Status', prop: 'status_Formatted' },
  ];




  toggle(col) {
    const isChecked = this.isChecked(col);

    if (isChecked) {
      this.columns = this.columns.filter(c => {
        return c.name !== col.name;
      });
    } else {
      this.columns = [...this.columns, col];
    }
    this.toggleSelection();
  }

  isChecked(col) {
    return (
      this.columns.find(c => {
        return c.name === col.name;
      }) !== undefined
    );
  }

  toggleSelection() {
    this.toggleOpen = !this.toggleOpen;
  }

  filter: any = {
    tabletUser_Id: 0,
    status: 0,
    date_Range: 0,
    //start_Date: moment().add(-1, 'months').toDate(),
    start_Date: moment(this.getMonday(new Date())).add(-7, 'days').toDate(),
    end_Date: moment(this.getMonday(new Date())).add(-1, 'days').toDate(),
    quickSearch: '',
  };
  default_filters = JSON.parse(JSON.stringify(this.filter));


  getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); 
    return new Date(d.setDate(diff));
  }
  constructor(private timesheetService: TimesheetService,
    private tabletUserService: TabletService,
    private router: Router, private authService: AuthService,
    private confirmationService: ConfirmationService,
    private columnService: ColumnService,
    private filterService: FilterService,
    public reportableService: ReportableService
    , private storageService: StorageService  ) {
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.user = localStorage.getItem("currentUser");
    this.user = JSON.parse(this.user);
    //console.log(this.user);
    this.is_admin = this.user.userTypeId == 2;

    // Check if any filters are present
    //var filters = filterService.getFilters(authService.currentUser.value.id, this.router.url);
    //if (filters != null)
    //  this.filter = filters;

    tabletUserService.getAll().then((data) => {
      this.users = data;

    });
  }

  loadTable(page: Page, clicked) {
    if (clicked) {
      this.page.pageNumber = 0;
      this.currentPageLimit = this.page.size;
      this.setPage({ offset: 0 });
      return;
    }
    this.storageService.dataSave("timesheet_filters", JSON.stringify(this.filter));
    this.storageService.dataSave("timesheet_page", JSON.stringify(this.page));

    this.filter.tabletUser_Id = +this.filter.tabletUser_Id;

    console.log(this.filter);
    this.timesheetService.getResults(this.page, this.filter.tabletUser_Id,
      this.filter.status, this.filter.start_Date, this.filter.end_Date, this.filter.quickSearch)
      .then((pagedData) => {
        this.page = pagedData.page;
        this.rows = pagedData.data;
      });
    this.getcolumns();
  }

  getcolumns() {
    this.columnService.getColumns(this.authService.currentUser.value.userId, "timesheets").then((data) => {
      if (data.columns != null) {
        this.columns = this.columns.filter(c => {
          return data.columns.includes(c.name);
        });
        this.toggleOpen = false;
      }

    });
  }
  SaveSelection() {
    let result = this.columns.map(a => a.name);
    this.columnService.save(this.authService.currentUser.value.userId, "timesheets", result).then((data) => {
    });
  }

  public onLimitChange(limit: any): void {
    this.page.size = parseInt(limit, 10);
    this.loadTable(this.page, false);
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page, false);
  }
  prevWeek(startDate, EndDate) {
    this.filter.start_Date = moment.utc(startDate).startOf('day').add(-7, 'days').toDate();
    this.filter.end_Date = moment.utc(this.filter.start_Date).startOf('day').add(+6, 'days').toDate();  
  }
  nextWeek(startDate, EndDate) {
    this.filter.start_Date = moment.utc(startDate).startOf('day').add(+7, 'days').toDate();
    this.filter.end_Date = moment.utc(this.filter.start_Date).startOf('day').add(+6, 'days').toDate();  
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page,false);
  }

  

  open(id: string, engineerName: string, date: string, addDays: number) {
    //this.router.navigate(['administration/timesheets/view', id]);
    this.router.navigate(['administration/timesheets/view'], { queryParams: { id: id, engineerName: engineerName, date: date, addDays: addDays } });
  }

  ngOnInit() {
    //this.loadTable(this.page);
    //this.init = true;
    var page = JSON.parse(this.storageService.get("timesheet_page"));
    var filter = JSON.parse(this.storageService.get("timesheet_filters"));
    if (page != undefined) {
      this.page = page;
      console.log(this.page);
    }
    if (filter != undefined) {
      this.filter = filter;
      this.filter.start_Date = moment(this.filter.start_Date).toDate();
      this.filter.end_Date = moment(this.filter.end_Date).toDate();
      this.filter.tabletUser_Id = +this.filter.tabletUser_Id;
      this.filter.status = +this.filter.status;
    }
    if (page == undefined && filter == undefined) {
      console.log("no filters");
      this.setPage({ offset: 0 });
      //this.init = true;
      this.resetFilters();
    } else {
      this.currentPageLimit = this.page.size;
      this.loadTable(this.page, false);
    }
  }

  resetFilters() {
    this.filter = {
      tabletUser_Id: 0,
      status: 0,
      date_Range: 0,
      //start_Date: moment().add(-1, 'months').toDate(),
      start_Date: moment(this.getMonday(new Date())).add(-7, 'days').toDate(),
      end_Date: moment(this.getMonday(new Date())).add(-1, 'days').toDate(),
      quickSearch: '',
    };
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.currentPageLimit = this.page.size;
  }

  dateChange(ev) {
    if (ev == -1) {
      return;
    }

    let date = moment();
    if (ev == -2) {
      date.set('year', 2010);
      date.set('month', 0);
      date.set('date', 1);
    }
    else {
      if (ev == 0) {
        date = date.subtract(1, 'weeks');
      } else {
        date = date.subtract(ev, 'months');
      }
    }

    let jsDate = date.toDate();
    //var startDate: NgbDateStruct = { year: jsDate.getFullYear(), month: jsDate.getMonth() + 1, day: jsDate.getDate() };
    this.filter.start_Date = jsDate;
  }

  getImages(timesheet_Id) {
    this.timesheetService.getImages(timesheet_Id).then((data) => {
      importedSaveAs(data, 'Images.zip');
    });
  }
  generateExcel() {
    this.timesheetService.getIndividulEntryExcel(this.filter.tabletUser_Id,
      this.filter.status, this.filter.start_Date, this.filter.end_Date, this.filter.quickSearch).then((data) => {
      importedSaveAs(data, 'Report.xlsx');
    });
  }
  gotoTop() {
    console.log("GOTO TOP");
    // window.scrollTo(-9999,-99999);
    let top = document.getElementById('top');
    console.log(top);
    if (top !== null) {
      top.scrollIntoView();
      //  document.getElementById("top").scrollTop -= 99999;
      top = null;
    }
  }
  approve(day) {
    //let jobDetails = 'Company: ' + job.company.name + '<br/>';
    //jobDetails += 'Location: ' + job.location.name + '<br/>';

    console.log(day);
    let timesheetDetails = 'Job No: ' + day.job_No + ',' + ' Engineer: ' + day.engineer + ',' + ' Date: ' + day.day;
    this.confirmationService.confirm({
      message: 'Are you sure that you want approve this timesheet?</br><br/>' + timesheetDetails,
      accept: () => {
        this.timesheetService.approve(day.id).then(() => {
          this.loadTable(this.page, false);
        });
      }
    });
  }

  amend(day) {
    this.router.navigate(['administration/timesheets/amend'], { queryParams: { id: day.id } });
  }

  downloadReport(id: string) {
    this.reportableService.getTimesheet(id).then((data) => {
      importedSaveAs(data, 'Timesheet Report.pdf');
    });
  }

  downloadSummary() {
    var start_Date = moment.utc(this.filter.start_Date).startOf('day').format('YYYY-MM-DD'); 
    var end_Date = moment.utc(this.filter.end_Date).startOf('day').format('YYYY-MM-DD');
    this.reportableService.getTimesheetsummary(start_Date, end_Date, this.filter.tabletUser_Id).then((data) => {
      importedSaveAs(data, 'Timesheet Report.pdf');
    });
  }
}
