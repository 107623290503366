import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Guid } from 'guid-typescript';

import { Observable } from 'rxjs';
import { PagedData } from '../models/paging/paged-data';
import { Page } from '../models/paging/page';
import { ServerData } from '../models/paging/server-data';

import { Equipment } from '../models/equipment';
import { SnotifyService } from 'ng-snotify';
import { forEach } from '@angular/router/src/utils/collection';
import { environment } from '../../environments/environment';
import { ApiResponse } from '../models/api-response';

@Injectable()
export class EquipmentService {
  constructor(private http: HttpClient, private snotifyService: SnotifyService) { }

  public get(id: Number): Promise<Equipment> {
    const headers = {
      'Content-Type': 'application/json',
    };
    return new Promise((resolve, reject) => {
      this.http.get<Equipment>(environment.apiUrl + 'equipment/get?id=' + id).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public save(equipment: any): Promise<ApiResponse> {
    //Need to do this regardless of put/post
    //for (const e of equipment.extras) {
    //  console.log(e);
    //  if (!e.id) {
    //    e.id = Guid.create().toString();
    //  }
    //}
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      console.log("equipment service");
      console.log(equipment);

      this.http.post<ApiResponse>(environment.apiUrl + 'equipment/post', equipment, { headers: headers }).subscribe(data => {
        resolve(data);
        this.snotifyService.success('Successfully added equipment');
      }, error => {
        console.log(error);
        this.snotifyService.warning(error.error);
        reject();
      });
    });
  }


  public getResults(page: Page, company_Id: number, location_Id: number, category_Id: number,
    serial_Num: string, asset_Num: string, quickSearch: string, decommissioned: boolean, archived: boolean): Promise<PagedData<any>> {
    const pagedData = new PagedData<any>();
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'Description'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'asc'; }

      let params = '&company_Id=' + company_Id;
      params += '&location_Id=' + location_Id;
      params += '&category_Id=' + category_Id;
      params += '&serial_Num=' + serial_Num;
      params += '&asset_Num=' + asset_Num;
      params += '&quickSearch=' + quickSearch;
      params += '&decommissioned=' + decommissioned;
      params += '&archived=' + archived;

      this.http.get<ServerData>(environment.apiUrl + 'equipment/gettable?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params).subscribe(data => {
        page.totalElements = data.total;
        page.totalPages = page.totalElements / page.size;
        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }

  public getJobEquipResults(page: Page, location_Id: number, category_Id: number,
    serial_Num: string, asset_Num: string, quickSearch: string, location_Inspection_Id: number, getAll: boolean, isService: boolean): Promise<PagedData<any>> {
    const pagedData = new PagedData<any>();
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'Description'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'asc'; }


      let params = '&location_Id=' + location_Id;
      params += '&category_Id=' + category_Id;
      params += '&serial_Num=' + serial_Num;
      params += '&asset_Num=' + asset_Num;
      params += '&quickSearch=' + quickSearch;
      params += '&location_Inspection_Id=' + location_Inspection_Id;
      params += '&getAll=' + getAll;
      params += '&isService=' + isService;

      this.http.get<ServerData>(environment.apiUrl + 'equipment/getJobEquipResults?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params).subscribe(data => {
        page.totalElements = data.total;
        page.totalPages = page.totalElements / page.size;
        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }

  public getJobEquipment(page: Page, Id: number, quickSearch: string): Promise<PagedData<any>> {
    const pagedData = new PagedData<any>();
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'Description'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'asc'; }

      let params = '&Id=' + Id;
      params += '&quickSearch=' + quickSearch;

      this.http.get<ServerData>(environment.apiUrl + 'equipment/getjobequipmenttable?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params).subscribe(data => {
        page.totalElements = data.total;
        page.totalPages = page.totalElements / page.size;
        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }

  public getFiles(page: Page, id: number): Promise<PagedData<any>> {
    const pagedData = new PagedData<any>();
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'File_Name'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'asc'; }

      let params = '&equipment_id=' + id;

      this.http.get<ServerData>(environment.apiUrl + 'equipment/getuploadedfiles?size=' + page.size + '&pageNumber=' + page.pageNumber + params).subscribe(data => {
        page.totalElements = data.total;
        page.totalPages = page.totalElements / page.size;
        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;

        console.log(pagedData);

        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }


  public removeFile(id: string, file_Name: string) {
    return this.http.delete(environment.apiUrl + "equipment/removeFile?equipment_id=" + id + "&file_Name=" + file_Name);
  }

  public uploadFile(id: number, base64String: string, fileName: string): Promise<any> {
    var dto = {
      Equipment_Id: +id,
      Base64String: base64String,
      File_Name: fileName
    };

    return new Promise((resolve, reject) => {
      this.http.post(environment.apiUrl + 'equipment/uploadFile', dto).subscribe(data => {
        this.snotifyService.success('Successfully uploaded file');
        resolve(data);
      }, error => {
        reject(error.error);
      });
    });
  }


  public getExcel(company_Id: number, location_Id: number, category_Id: number,
    serial_Num: string, asset_Num: string, quickSearch: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let params = '?company_Id=' + company_Id;
      params += '&location_Id=' + location_Id;
      params += '&category_Id=' + category_Id;
      params += '&serial_Num=' + serial_Num;
      params += '&asset_Num=' + asset_Num;
      params += '&quickSearch=' + quickSearch;

      this.http.get<any>(environment.apiUrl + 'equipment/getexcel' + params).subscribe(data => {
        let blob = this.converBase64toBlob(data, 'application/vnd.ms-excel');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
  public getJobEquipExcel(Id: number, quickSearch: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let params = '?Id=' + Id;
      params += '&quickSearch=' + quickSearch;

      this.http.get<any>(environment.apiUrl + 'equipment/getjobequipmentexcel' + params).subscribe(data => {
        let blob = this.converBase64toBlob(data, 'application/vnd.ms-excel');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }



  converBase64toBlob(content, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;
    let byteCharacters = window.atob(content); //method which converts base64 to binary
    let byteArrays = [
    ];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);
      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    let blob = new Blob(byteArrays, {
      type: contentType
    }); //statement which creates the blob
    return blob;
  }


  
}
