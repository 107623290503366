import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from '../../../../services/company.service';
import { Company } from '../../../../models/company';

@Component({
  selector: 'app-company-manage',
  templateUrl: './company-manage.component.html',
  styleUrls: ['./company-manage.component.css']
})
export class CompanyManageComponent implements OnInit {
  company: Company;
  constructor(private companyService: CompanyService, public router: Router, public route: ActivatedRoute) { }

  ngOnInit() {
    this.company = new Company();
    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      if (id != undefined) {
        console.log(id);
        this.companyService.get(id).then((data) => {
          console.log(data);
          this.company = data;
          this.company.is_edit = true;
        });
      }
    });
  }

  submit() {
    this.company.depot_ID = +this.company.depot_ID;
    this.companyService.save(this.company).then((data) => {
      this.router.navigate(['administration/companies']);
    });
  }

}
