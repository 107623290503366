import { Component, OnInit } from '@angular/core';
import { Page } from '../../../models/paging/page';
import { WebUserService } from '../../../services/webUser.service';
import { CompanyService } from '../../../services/company.service';
import { Company } from '../../../models/company';
import { Router } from '@angular/router';
import { LocationService } from '../../../services/location.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { AuthService } from '../../../services/auth.service';
import { ColumnService } from '../../../services/column.service';
import { StorageService } from '../../../services/storage.service';

@Component({
  selector: 'app-webusers',
  templateUrl: './webusers.component.html',
  styleUrls: ['./webusers.component.css']
})
export class WebusersComponent implements OnInit {
  filter: any = {
    company_Id: 0,
    email: '',
    quickSearch: '',
    role:-1
  };
  init: boolean;
  page = new Page();
  rows = Array<any>();

  companies: Company[];

  public readonly pageLimitOptions = [
    { value: 5 },
    { value: 10 },
    { value: 25 },
    { value: 50 },
    { value: 100 },
  ];


  public currentPageLimit: number = 10;

  toggleOpen = false;
  columns = [
    { name: 'Firstname', prop: 'firstname' },
    { name: 'Lastname', prop: 'lastname' },
    { name: 'Email', prop: 'email' },
    { name: 'Company', prop: 'company' },
    { name: 'User Type', prop: 'userType_Formatted' },
    { name: 'Last Logged In', prop: 'lastLoginDate_Formatted' },
  ];

  allColumns = [
    { name: 'Firstname', prop: 'firstname' },
    { name: 'Lastname', prop: 'lastname' },
    { name: 'Email', prop: 'email' },
    { name: 'Company', prop: 'company' },
    { name: 'User Type', prop: 'userType_Formatted' },
    { name: 'Last Logged In', prop: 'lastLoginDate_Formatted' },
  ];




  toggle(col) {
    const isChecked = this.isChecked(col);

    if (isChecked) {
      this.columns = this.columns.filter(c => {
        return c.name !== col.name;
      });
    } else {
      this.columns = [...this.columns, col];
    }
    this.toggleSelection();
  }

  isChecked(col) {
    return (
      this.columns.find(c => {
        return c.name === col.name;
      }) !== undefined
    );
  }

  toggleSelection() {
    this.toggleOpen = !this.toggleOpen;
  }

  constructor(public companyService: CompanyService, public locationService: LocationService,
    public webUserService: WebUserService, private authService: AuthService, private columnService: ColumnService,
    public router: Router, private storageService: StorageService) {
    this.page.pageNumber = 0;
    this.page.size = 10;

    companyService.getAll().then((data) => {
      this.companies = data;
    });

 
  }



  ngOnInit() {
    this.filter.role = -1;

    var page = JSON.parse(this.storageService.get("webusers_page"));
    var filter = JSON.parse(this.storageService.get("webusers_filters"));
    if (page != undefined) {
      this.page = page;
      console.log(this.page);
    }
    if (filter != undefined) {
      this.filter = filter;
      this.filter.role = +this.filter.role;
    }
    if (page == undefined && filter == undefined) {
      console.log("no filters");
      this.setPage({ offset: 0 });
      this.init = true;
      this.resetFilters();
    } else {
      this.currentPageLimit = this.page.size;
      this.loadTable(this.page, false);
    }
  }
  resetFilters() {
    this.filter = {
      company_Id: 0,
      email: '',
      quickSearch: '',
      role: -1
    };
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.currentPageLimit = this.page.size;
  }

  edit(row) {
    this.router.navigate(['users/web/manage'], { queryParams: { id: row.userId } });
  }

  // ###################################
  // TABLE
  // ###################################
  public onLimitChange(limit: any): void {
    this.page.size = parseInt(limit, 10);
    this.loadTable(this.page, false);
  }
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page, false);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page,false);
  }

  loadTable(page: Page,clicked) {
    if (clicked) {
      this.page.pageNumber = 0;
      this.currentPageLimit = this.page.size;
      this.setPage({ offset: 0 });
      return;
    }
    this.storageService.dataSave("webusers_filters", JSON.stringify(this.filter));
    this.storageService.dataSave("webusers_page", JSON.stringify(this.page));


    if (this.filter.company_Id == null || this.filter.company_Id == undefined) {
      this.filter.company_Id = 0;
    }
    this.webUserService.getResults(this.page, this.filter.company_Id, this.filter.email, this.filter.role, this.filter.quickSearch).then((pagedData) => {
      console.log(pagedData.data);
      this.page = pagedData.page;
      this.rows = pagedData.data;
      if (this.init) {
        this.gotoTop();
        this.init = false;
      }
    });
    this.getcolumns();
  }

  getcolumns() {
    this.columnService.getColumns(this.authService.currentUser.value.userId, "web").then((data) => {
      if (data.columns != null) {
        this.columns = this.columns.filter(c => {
          return data.columns.includes(c.name);
        });
        this.toggleOpen = false;
      }

    });
  }
  SaveSelection() {
    let result = this.columns.map(a => a.name);
    this.columnService.save(this.authService.currentUser.value.userId, "web", result).then((data) => {
    });
  }
  generateExcel() {
    this.webUserService.getExcel(this.filter.company_Id, this.filter.email, this.filter.role, this.filter.quickSearch).then((data) => {
      importedSaveAs(data, 'Report.xlsx');
    });
  }
  gotoTop() {
    console.log("GOTO TOP");
    // window.scrollTo(-9999,-99999);
    let top = document.getElementById('top');
    console.log(top);
    if (top !== null) {
      top.scrollIntoView(true);
      //  document.getElementById("top").scrollTop -= 99999;
      top = null;
    }
  }
}
