import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Page } from '../../../models/paging/page';
import { PagedData } from '../../../models/paging/paged-data';
import { saveAs as importedSaveAs } from 'file-saver';
import { ManufacturerService } from '../../../services/manufacturer.service';
import { Manufacturer } from '../../../models/manufacturer';
import { StorageService } from '../../../services/storage.service';

@Component({
  selector: 'app-manufacturers',
  templateUrl: './manufacturers.component.html',
  styleUrls: ['./manufacturers.component.css']
})
export class ManufacturersComponent implements OnInit {
  filter: any = {
    name: '',
    quickSearch: '',
  };
  init: boolean;
  page = new Page();
  rows = Array<Manufacturer>();

  public readonly pageLimitOptions = [
    { value: 5 },
    { value: 10 },
    { value: 25 },
    { value: 50 },
    { value: 100 },
  ];


  public currentPageLimit: number = 10;

  columns = [
    { name: 'Name', prop: 'name' },
    { name: 'Option', prop: '' },
  ];

  constructor(public manufacturerService: ManufacturerService, public router: Router, private storageService: StorageService) {
    this.page.pageNumber = 0;
    this.page.size = 10;
  }

  ngOnInit() {
    //this.loadTable(this.page);
    //this.init = true;
    var page = JSON.parse(this.storageService.get("manufacturers_page"));
    var filter = JSON.parse(this.storageService.get("manufacturers_filters"));
    if (page != undefined) {
      this.page = page;
      console.log(this.page);
    }
    if (filter != undefined) {
      this.filter = filter;
    }
    if (page == undefined && filter == undefined) {
      console.log("no filters");
      this.setPage({ offset: 0 });
      this.init = true;
      this.resetFilters();
    } else {
      this.currentPageLimit = this.page.size;
      this.loadTable(this.page, false);
    }
  }

  resetFilters() {
    this.filter = {
      name: '',
      quickSearch: '',
    };
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.currentPageLimit = this.page.size;
  }

  edit(row) {
    this.router.navigate(['administration/manufacturers/manage'], { queryParams: { id: row.manuf_ID } });
  }


  // ###################################
  // TABLE
  // ###################################
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page, false);
  }

  public onLimitChange(limit: any): void {
    this.page.size = parseInt(limit, 10);
    this.loadTable(this.page, false);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page,false);
  }

  loadTable(page: Page, clicked) {
    if (clicked) {
      this.page.pageNumber = 0;
      this.currentPageLimit = this.page.size;
      this.setPage({ offset: 0 });
      return;
    }
    this.storageService.dataSave("manufacturers_filters", JSON.stringify(this.filter));
    this.storageService.dataSave("manufacturers_page", JSON.stringify(this.page));
    this.manufacturerService.getResults(this.page, this.filter.name, this.filter.quickSearch).then((pagedData) => {
      this.page = pagedData.page;
      this.rows = pagedData.data;
      if (this.init) {
        this.gotoTop();
        this.init = false;
      }
    });
  }
  generateExcel() {
    this.manufacturerService.getExcel(this.filter.name, this.filter.quickSearch).then((data) => {
      importedSaveAs(data, 'Report.xlsx');
    });
  }
  gotoTop() {
    console.log("GOTO TOP");
    // window.scrollTo(-9999,-99999);
    let top = document.getElementById('top');
    console.log(top);
    if (top !== null) {
      top.scrollIntoView(true);
      //  document.getElementById("top").scrollTop -= 99999;
      top = null;
    }
  }
}
