import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { PagedData } from '../models/paging/paged-data';
import { Page } from '../models/paging/page';
import { ServerData } from '../models/paging/server-data';

import { Owner } from '../models/owner';

@Injectable()
export class OwnerService {
  constructor(private http: HttpClient) { }

  public getAll(): Promise<Owner[]> {
    return new Promise((resolve, reject) => {
      this.http.get<Owner[]>('/api/owner/getall').subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
}
