export class Category {
  category_ID = 0;
  category_Name = '';
  insp_Time_Period= 1;
  report_Type_ID= 1;
  no_Of_Signatures: number;
  expired = false;

  is_edit = false;
}
