import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { PagedData } from '../models/paging/paged-data';
import { Page } from '../models/paging/page';
import { ServerData } from '../models/paging/server-data';

import { Vehicle } from '../models/vehicle';
import { SnotifyService } from 'ng-snotify';
import { environment } from '../../environments/environment';
import { ApiResponse } from '../models/api-response';
import * as moment from 'moment';

@Injectable()
export class VehicleService {
  constructor(private http: HttpClient, private snotifyService: SnotifyService) { }

  public get(id: any): Promise<Vehicle> {
    const headers = {
      'Content-Type': 'application/json',
    };
    console.log("in service");
    return new Promise((resolve, reject) => {
      this.http.get<Vehicle>(environment.apiUrl + 'vehicle/get?id=' + id).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public save(vehicle: Vehicle): Promise<ApiResponse> {
    return new Promise((resolve, reject) => {
      const headers = {
        'Content-Type': 'application/json',
      };
      console.log("vehicle service");
      console.log(vehicle);
      this.http.post<ApiResponse>(environment.apiUrl + 'vehicle/post', vehicle, { headers: headers }).subscribe(data => {
        resolve(data);
        this.snotifyService.success('Successfully updated vehicle');
      }, error => {
        console.log(error);
        reject();
      });

    });
  }

  public getAll(): Promise<Vehicle[]> {
    const headers = {
      'Content-Type': 'application/json',
    };
    return new Promise((resolve, reject) => {
      this.http.get<Vehicle[]>(environment.apiUrl + 'vehicle/getall').subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getInspection(page: Page, id: Number): Promise<any> {
    const pagedData = new PagedData<any>()
    const headers = {
      'Content-Type': 'application/json',
    };
    return new Promise((resolve, reject) => {
      this.http.get<ServerData>(environment.apiUrl + 'vehicle/getinspection?id=' + id, { headers: headers }).subscribe(data => {

        page.totalElements = data.total;
        page.totalPages = page.totalElements / page.size;
        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }
        pagedData.page = page;

        resolve(pagedData);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  // public save(category: Category): Promise<ApiResponse> {
  //   return new Promise((resolve, reject) => {
  //     const headers = {
  //       'Content-Type': 'application/json',
  //     };
  //     console.log("company service");
  //     console.log(category);
  //     this.http.post<ApiResponse>(environment.apiUrl + 'category/post', category, { headers: headers }).subscribe(data => {
  //       resolve(data);
  //       this.snotifyService.success('Successfully added category');
  //     }, error => {
  //       console.log(error);
  //       reject();
  //     });


      //if (category.is_edit) {
      //  this.http.put<Category>('/api/category/put', category).subscribe(data => {
      //    resolve(data);
      //    this.snotifyService.success('Successfully updated category');
      //  }, error => {
      //    console.log(error);
      //    reject();
      //  });
      //} else {
      //  this.http.post<Category>('/api/category/post', category).subscribe(data => {
      //    resolve(data);
      //    this.snotifyService.success('Successfully added category');
      //  }, error => {
      //    console.log(error);
      //    reject();
      //  });
      //}
  //   });
  // }


  public getResults(page: Page, reg: string, quickSearch: string): Promise<PagedData<any>>{
    const pagedData = new PagedData<any>();
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'Reg'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'asc'; }

      let params = '&reg=' + reg;
      params += '&quickSearch=' + quickSearch;

      this.http.get<ServerData>(environment.apiUrl + 'vehicle/gettable?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params).subscribe(data => {
        page.totalElements = data.total;
        page.totalPages = page.totalElements / page.size;
        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }

  public getInspectionTable(page: Page, vehicleId: string, tabletUser_Id: Number, start_Date: string, end_Date: string, quickSearch: string): Promise<PagedData<any>>{
    const pagedData = new PagedData<any>();
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'Id'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'desc'; }

      let params = '&vehicleId=' + vehicleId;
      params += '&tabletUser_Id=' + tabletUser_Id;
      params += '&start_Date=' + moment(start_Date).format('YYYY-MM-DD');
      params += '&end_Date=' + moment(end_Date).add(1, 'days').format('YYYY-MM-DD')
      params += '&quickSearch=' + quickSearch

      this.http.get<ServerData>(environment.apiUrl + 'vehicle/getinspectiontable?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params).subscribe(data => {
        page.totalElements = data.total;
        page.totalPages = page.totalElements / page.size;
        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }

  public getInspectionExcel(vehicleId: string, tabletUser_Id: Number, start_Date: string, end_Date: string, quickSearch: string): Promise<any> {
     return new Promise((resolve, reject) => {
       let params = '?vehicleId=' + vehicleId;
       params += '&tabletUser_Id=' + tabletUser_Id;
       params += '&start_Date=' + moment(start_Date).format('YYYY-MM-DD');
       params += '&end_Date=' + moment(end_Date).add(1, 'days').format('YYYY-MM-DD')
       params += '&quickSearch=' + quickSearch

       this.http.get<any>(environment.apiUrl + 'vehicle/getinspectionexcel' + params).subscribe(data => {
         let blob = this.converBase64toBlob(data, 'application/vnd.ms-excel');
         resolve(blob);
       }, error => {
         console.log(error);
         reject();
       });
     });
  }

  public getExcel(reg: string, quickSearch: string): Promise<any> {
     return new Promise((resolve, reject) => {
       let params = '?reg=' + reg;
       params += '&quickSearch=' + quickSearch;

       this.http.get<any>(environment.apiUrl + 'vehicle/getexcel' + params).subscribe(data => {
         let blob = this.converBase64toBlob(data, 'application/vnd.ms-excel');
         resolve(blob);
       }, error => {
         console.log(error);
         reject();
       });
     });
   }

   converBase64toBlob(content, contentType) {
     contentType = contentType || '';
     let sliceSize = 512;
     let byteCharacters = window.atob(content); //method which converts base64 to binary
     let byteArrays = [
     ];
     for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
       let slice = byteCharacters.slice(offset, offset + sliceSize);
       let byteNumbers = new Array(slice.length);
       for (let i = 0; i < slice.length; i++) {
         byteNumbers[i] = slice.charCodeAt(i);
       }
       let byteArray = new Uint8Array(byteNumbers);
       byteArrays.push(byteArray);
     }
     let blob = new Blob(byteArrays, {
       type: contentType
     }); //statement which creates the blob
     return blob;
   }

  public getImage(id: string): Promise<any> {

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + 'vehicle/getImage?id=' + id).subscribe(data => {
        resolve(data);
      }, error => {
        reject();
      });
    });
  }

  public getOfficeNotes(id: string): Promise<any> {

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + 'vehicle/getOfficeNotes?id=' + id).subscribe(data => {

        resolve(data);
        

      }, error => {

        reject();
      });
    });
  }


  public saveNotes(notes: any, id: string): Promise<ApiResponse> {
    const headers = {
      'Content-Type': 'application/json',
    };
    var dto = {
      notes: notes,
      id: id
    }
    console.log(dto);
    return new Promise((resolve, reject) => {
      

      this.http.post<ApiResponse>(environment.apiUrl + 'vehicle/postNotes', dto, { headers: headers }).subscribe(data => {
        resolve(data);
        this.snotifyService.success('Successfully added office notes');
      }, error => {
        console.log(error);
        reject();
      });
    });
  }



}
