import { Component, OnInit } from '@angular/core';
import { TimesheetService } from '../../../../services/timesheet.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Timesheet_Day, Timesheet_Entry } from '../../../../models/timesheet';
import { Page } from '../../../../models/paging/page';
import * as moment from 'moment';
import { Timesheet_View } from '../../../../models/timesheet_view';
import { ConfirmationService } from 'primeng/components/common/confirmationservice';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-timesheet-amend',
  templateUrl: './timesheet-amend.component.html',
  styleUrls: ['./timesheet-amend.component.css']
})
export class TimesheetAmendComponent implements OnInit {

  engineerName: string;
  entries: Timesheet_Entry[];
  id: string;
  page = new Page();
  day: string;
  formattedDate: string;

  timesheet: Timesheet_View;

  constructor(private timesheetService: TimesheetService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute) {
    this.page.pageNumber = 0;
    this.page.size = 10;
  }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      const engineerName = params['engineerName'];
      var date = params['date'];
      const addDays = parseInt(params['addDays']);

      this.timesheetService.getResultById(params['id'])
        .then((pagedData) => {
          //this.page = pagedData.page;
          console.log("component");
          //console.log(pagedData);
          this.timesheet = pagedData.data[0];
          console.log(this.timesheet);
          
          this.day = this.timesheet.day;
          //this.day = moment(this.timesheet.day).format('MM/DD/YYYY');
          //this.timesheet.day = this.day;
        })

    });

  }

  updateHours(arrive, depart, travel) {
    arrive = arrive.toString();
    depart = depart.toString();

    //convert the arrive/depart time to dates
    var arrivesplit = arrive.split(":", 2);
    let arrivedate = moment(moment().format("YYYY-MM-DD") + ' ' + arrivesplit[0].slice(-2) + ":" + arrivesplit[1]).toDate();

    var departsplit = depart.split(":", 2);
    let departdate = moment(moment().format("YYYY-MM-DD") + ' ' + departsplit[0].slice(-2) + ":" + departsplit[1]).toDate();

    // get the amount of hours difference in arrive/depart times
    const msBetweenDates = departdate.getTime() - arrivedate.getTime();
    this.convertMsToTime(msBetweenDates);
    console.log("time difference");
    console.log(this.convertMsToTime(msBetweenDates));
    let timediff = this.convertMsToTime(msBetweenDates);

    //adding the time difference to the travel time
    let timediffsplit = timediff.split(".", 2);
    let timemins = timediffsplit[1];
    let timehrs = timediffsplit[0];

    if (timehrs == null || timehrs == '') {
      timehrs = "0";
    }

    //adding the time difference to the travel time
    let travelsplit = travel.split(".", 2);
    console.log(travelsplit);
    let travelhrs = travelsplit[0];
    if (travelhrs.length > 1) {
      while (travelhrs.charAt(0) === '0') { // remove first charecter if 0
        travelhrs = travelhrs.substring(1);
      }
    }

    
    let travelmins = ""
    if (travelsplit[1] == undefined || travelsplit[1] == null) {
      travelmins = "0";
    } else {
      travelmins = travelsplit[1];
    }
    if (travelmins.length < 2) {
      travelmins = travelmins +"0"
    }
    if (timemins.length < 2) {
      timemins = timemins + "0"
    }
    let minsnum = parseInt(travelmins) + parseInt(timemins);
    var mins = minsnum.toString();
    console.log("travel mins " +travelmins)
    console.log("time mins " +timemins)
    console.log("mins total " + mins);

    let hrs = parseInt(travelhrs) + parseInt(timehrs);
    let res = '';

    if (mins.length >= 3) {
      hrs = hrs + 1;
      mins = mins.substring(1);
    }
    if (mins == "50") {
      mins = "5";
    }
    res = hrs.toString() + '.' + mins.toString();
    console.log("result");
    console.log(res);
    this.timesheet.hours = res;
  }

  padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
  }

  convertMsToTime(milliseconds: number) {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;


    let minsAsDecimal = this.padTo2Digits(minutes / 60);
    let split = minsAsDecimal.split(".", 2);
    var min = split[1]
    if (min == undefined || min == null) {
      min = "0";
    }

    //remove 0 if first charecter
    var hour = this.padTo2Digits(hours);
    if (hour == undefined || hour == null) {
      hour = "0";
    }
    while (hour.charAt(0) === '0') {
      hour = hour.substring(1);
    }
    return `${hour}.${min}`;
  }

  submit() {
    if (parseInt(this.timesheet.hours) < 0) {
      alert('You cannot have a timesheet with negative hours.');
      return;
    }
    if (this.timesheet.reason === "") {
      alert('Please enter the reason for this amendment.');
      return;
    }


    this.confirmationService.confirm({
      message: 'Are you sure that you want amend this timesheet?</br><br/>',
      accept: () => {
        console.log("submit");
        if (this.timesheet.day.toString().length > 10) {
          this.timesheet.day = moment(this.timesheet.day).format('DD/MM/YYYY').toString();
        }
        console.log(this.timesheet);
        this.timesheetService.amend(this.timesheet).then((data) => {
          this.router.navigate(['administration/timesheets']);
        });
      }
    });



    
  }


  
}

