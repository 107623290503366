import { Component, OnInit } from '@angular/core';
import { RemindersService } from '../../../services/reminders.service';
import { Page } from '../../../models/paging/page';
import * as moment from 'moment';
import { saveAs as importedSaveAs } from 'file-saver';

@Component({
  selector: 'app-reminder',
  templateUrl: './reminder.component.html',
  styleUrls: ['./reminder.component.css']
})
export class ReminderComponent implements OnInit {
  page = new Page();
  rows = Array<any>();

  filter: any = {
    start_Date: moment().add(1, 'months').toDate(),
    job_Type: 2,
    yearRange: moment().add("years", -1).format("YYYY") + ":" + moment().add('years', 3).format("YYYY"),
    showAll: false
  };
   init:boolean;
 
  constructor(private remindersService: RemindersService) {
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.page.sortOrder = 'asc';
    this.page.sortBy = "Due_Date";
  }

  ngOnInit() {
    this.loadTable(this.page);
      this.init = true;
    
  }

  // ###################################
  // TABLE
  // ###################################
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page);
  }

  loadTable(page: Page) {
    this.remindersService.getResults(this.page, this.filter.start_Date, this.filter.job_Type, this.filter.showAll).then((pagedData) => {
        console.log(pagedData);
        this.page = pagedData.page;
      this.rows = pagedData.data;
      if (this.init) {
        this.gotoTop();
        this.init = false;
      }
      });
  }

  generateExcel() {
    this.remindersService.getExcel(this.page, this.filter.start_Date, this.filter.job_Type, this.filter.showAll).then((data) => {
      importedSaveAs(data, 'Report.xlsx');
    });
  }
  gotoTop() {
    console.log("GOTO TOP");
    // window.scrollTo(-9999,-99999);
    let top = document.getElementById('top');
    console.log(top);
    if (top !== null) {
      top.scrollIntoView(true);
      //  document.getElementById("top").scrollTop -= 99999;
      top = null;
    }
  }
}
