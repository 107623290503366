import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { PagedData } from '../models/paging/paged-data';
import { Page } from '../models/paging/page';
import { ServerData } from '../models/paging/server-data';

import { Manufacturer } from '../models/manufacturer';
import { SnotifyService } from 'ng-snotify';
import { environment } from '../../environments/environment';
import { ApiResponse } from '../models/api-response';

@Injectable()
export class ManufacturerService {
  constructor(private http: HttpClient, private snotifyService: SnotifyService) { }

  public getAll(): Promise<Manufacturer[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {

      this.http.get<Manufacturer[]>(environment.apiUrl + 'manufacturer/getall', { headers: headers }).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public get(id: Number): Promise<Manufacturer> {
    const headers = {
      'Content-Type': 'application/json',
    };
    return new Promise((resolve, reject) => {
      this.http.get<Manufacturer>(environment.apiUrl + 'manufacturer/get?id=' + id, { headers: headers }).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public save(manufacturer: Manufacturer): Promise<ApiResponse> {
    return new Promise((resolve, reject) => {
      const headers = {
        'Content-Type': 'application/json',
      };
      console.log("manufacturer service");
      console.log(manufacturer);
      this.http.post<ApiResponse>(environment.apiUrl + 'manufacturer/post', manufacturer, { headers: headers }).subscribe(data => {
        resolve(data);
        this.snotifyService.success('Successfully added manufacturer');
      }, error => {
        console.log(error);
        reject();
      });
    });
  }


  public getResults(page: Page, name: string, quickSearch: string): Promise<PagedData<any>> {
    const pagedData = new PagedData<any>();
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'Manuf'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'asc'; }

      let params = '&name=' + name;
      params += '&quickSearch=' + quickSearch;

      this.http.get<ServerData>(environment.apiUrl + 'manufacturer/gettable?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params, { headers: headers }).subscribe(data => {
        page.totalElements = data.total;
        page.totalPages = page.totalElements / page.size;
        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }

  public getExcel(name: string, quickSearch: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let params = '?name=' + name;
      params += '&quickSearch=' + quickSearch;

      this.http.get<any>(environment.apiUrl + 'manufacturer/getexcel' + params).subscribe(data => {
        let blob = this.converBase64toBlob(data, 'application/vnd.ms-excel');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
  converBase64toBlob(content, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;
    let byteCharacters = window.atob(content); //method which converts base64 to binary
    let byteArrays = [
    ];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);
      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    let blob = new Blob(byteArrays, {
      type: contentType
    }); //statement which creates the blob
    return blob;
  }
}
