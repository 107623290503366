import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Page } from '../../../../models/paging/page';
import { Category } from '../../../../models/category';
import { VehicleService } from '../../../../services/vehicle.service';
import { Vehicle_Inspection_Questions } from '../../../../models/vehicle_inspection_questions';
import { Image } from '@ks89/angular-modal-gallery';

@Component({
  selector: 'app-vehicle-inspection-edit',
  templateUrl: './vehicle-inspection-edit.component.html',
  styleUrls: ['./vehicle-inspection-edit.component.css']
})

export class VehicleInspectionEditComponent implements OnInit {

  rows = Array<Vehicle_Inspection_Questions>();
  page = new Page();
  init: boolean;
  imageSource;
  Id;
  office_notes;

  constructor(public vehicleService: VehicleService, public router: Router, private route: ActivatedRoute) {

    this.page.pageNumber = 0;
    this.page.size = 50;
    this.page.sortOrder = 'desc';
    this.page.sortBy = "Position";

  }


  ngOnInit() {
    this.loadTable(this.page);
    this.init = true;
  }




  // ###################################
  // TABLE
  // ###################################
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page);
  }

  loadTable(page: Page) {
    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      if (id != undefined) {
        this.Id = id;
        this.vehicleService.getInspection(page,id).then((pagedData) => {
          this.page = pagedData.page;
          this.rows = pagedData.data;
          console.log(pagedData);
          // this.category.is_edit = true;
        });
       ///CAll API and get Base64 string
        this.vehicleService.getImage(id).then((data) => {
          this.imageSource = data;

        });
        this.vehicleService.getOfficeNotes(id).then((data) => {
          this.office_notes = data;

        });


        
      }
    });
  }





  submit() {
    // this.category.insp_Time_Period = +this.category.insp_Time_Period;
    // this.category.report_Type_ID = +this.category.report_Type_ID;
    // this.categoryService.save(this.category).then((data) => {
    //   this.router.navigate(['administration/categories']);
    // });

    this.vehicleService.saveNotes(this.office_notes, this.Id).then((data) => {
      this.router.navigate(['reports/vehicleinspections']);
    });
  }

}
