import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { PagedData } from '../models/paging/paged-data';
import { Page } from '../models/paging/page';
import { ServerData } from '../models/paging/server-data';

import { Location_Inspection } from '../models/location_inspection';
import { UUID } from 'angular2-uuid';

import * as moment from 'moment';
import { forEach } from '@angular/router/src/utils/collection';
import { AuthService } from './auth.service';
import { serializePath } from '@angular/router/src/url_tree';
import { SnotifyService } from 'ng-snotify';
import { environment } from '../../environments/environment';
import { ApiResponse } from '../models/api-response';

@Injectable()
export class JobService {
  constructor(private http: HttpClient, private auth: AuthService, private snotifyService: SnotifyService) { }

  public getJobNo(job_Type_Id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>('/api/locationinspection/generatejobno?job_Type_Id=' + job_Type_Id).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public get(id: any): Promise<Location_Inspection> {
    return new Promise((resolve, reject) => {
      this.http.get<Location_Inspection>(environment.apiUrl + 'job/get?id=' + id).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getEquipResults(page: Page, id: string, serial_Num: string, parent_Category_Id: number): Promise<PagedData<any>> {
    const pagedData = new PagedData<any>();

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'completed'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'asc'; }

      let params = '&serial_Num=' + serial_Num;
      params += '&parent_Category_Id=' + parent_Category_Id;

      this.http.get<ServerData>('/api/locationinspection/getequiptable?location_Inspection_Id=' + id + '&size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params).subscribe(data => {
        page.totalElements = data.total;
        page.totalPages = page.totalElements / page.size;

        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }

  public getResults(page: Page, company_Id: number, location_Id: number, job_No: string, 
    created_start_Date: string, created_end_Date: string, status: number, quickSearch: string, pdaUserId: number): Promise<PagedData<any>> {
    const pagedData = new PagedData<any>();
    const headers = {
      'Content-Type': 'application/json',
    };
    
    return new Promise((resolve, reject) => {
      console.log(page);
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'Created_At'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'desc'; }

      if (job_No == "") {
        job_No = "null"
      };
      let params = '&company_Id=' + company_Id;
      params += '&location_Id=' + location_Id;
      params += '&job_No=' + job_No;
/*      params += '&completed=' + completed;*/
      params += '&created_start_Date=' + moment(created_start_Date).format('YYYY-MM-DD');
      params += '&created_end_Date=' + moment(created_end_Date).add(1, 'days').format('YYYY-MM-DD');
      params += '&status=' + status;
      params += '&quickSearch=' + quickSearch;
      params += '&pdaUserId=' + pdaUserId;
      //params += '&scheduled_start_Date=' + moment(scheduled_start_Date).format('YYYY-MM-DD');
      //params += '&scheduled_end_Date=' + moment(scheduled_end_Date).format('YYYY-MM-DD');
      //params += '&filterbyScheduled=' + 'false';
      //params += '&filterbyCreated=' + 'false';

      console.log(params);
      this.http.get<ServerData>(environment.apiUrl + 'job/gettable?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params, { headers: headers }).subscribe(data => {
        console.log("in service");
        console.log(data);
        page.totalElements = data.total;
        page.totalPages = page.totalElements / page.size;

        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);
        
      }, error => {
        reject();
      });
    });
  }

  public getApproveResults(page: Page, company_Id: number, location_Id: number, job_No: string, completed: boolean,
    created_start_Date: string, created_end_Date: string, scheduled_start_Date: string, scheduled_end_Date: string, filterbyScheduled: boolean, filterbyCreated: boolean): Promise<PagedData<any>> {
    const pagedData = new PagedData<any>();

    return new Promise((resolve, reject) => {
      console.log(page);
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'Created_At'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'desc'; }

      let params = '&company_Id=' + company_Id;
      params += '&location_Id=' + location_Id;
      params += '&job_No=' + job_No;
      params += '&completed=' + completed;
      params += '&created_start_Date=' + moment(created_start_Date).format('YYYY-MM-DD');
      params += '&created_end_Date=' + moment(created_end_Date).format('YYYY-MM-DD');
      params += '&scheduled_start_Date=' + moment(scheduled_start_Date).format('YYYY-MM-DD');
      params += '&scheduled_end_Date=' + moment(scheduled_end_Date).format('YYYY-MM-DD');
      params += '&filterbyScheduled=' + filterbyScheduled;
      params += '&filterbyCreated=' + filterbyCreated;

      this.http.get<ServerData>('/api/locationinspection/gettable?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params).subscribe(data => {
        page.totalElements = data.total;
        page.totalPages = page.totalElements / page.size;

        for (let i = 0; i < data.data.length; i++) {
           const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }

  create(job: Location_Inspection, userId: string): Promise<ApiResponse> {
    //job.scheduled_Date = moment(job.scheduled_Date).add(-1, "months").format("YYYY/MM/DD");
    console.log("create job service");
    const headers = {
      'Content-Type': 'application/json',
    };
    var dto = {
/*      locationInspectionId: 0,*/
      location_Inspection_ID: +job.location_Inspection_ID,
      locationId: +job.locationId,
      jobNo: job.jobNo,
      notes: job.notes,
      colour_ID: +job.colour_ID,
      jobType: +job.jobType,
      userId: userId,
      price: job.price,
      fullDay: job.fullDay,
      halfDay: job.halfDay,
      otherDay: job.otherDay,

      contactName: job.contactName,
      contactDetails: job.contactDetails,
      officeContactName: job.officeContactName,
      officeContactDetails: job.officeContactDetails,
      purchaseOrder: job.purchaseOrder,
      startTime: moment(job.startTime).format('HH:mm'),
      scheduled_Date: moment(job.scheduled_Date).format('YYYY-MM-DD'),
      endTime: moment(job.endTime).format('HH:mm'),
      scheduled_End_Date: moment(job.scheduled_End_Date).format('YYYY-MM-DD'),
      engineerIds: job.engineerIds,
      includeEquip: job.includeEquip,
      jobSummary: job.jobSummary,
      certificateRequired: job.certificateRequired
    }

    /*job.scheduled_Date = moment(job.scheduled_Date).format('YYYY/MM/DD');*/
    return new Promise((resolve, reject) => {
      
      console.log(dto);
      this.http.post<any>(environment.apiUrl + 'job/post', dto, { headers: headers }).subscribe(data => {
        //this.snotifyService.success('Successfully added job');
        resolve(data);
      }, (error) => {
        resolve(null)
      
      });
      
    });
  }

  public saveEquipment(equipment_Ids,job_Id): Promise<ApiResponse> {

    const headers = {
      'Content-Type': 'application/json',
    };
    console.log(equipment_Ids);
    return new Promise((resolve, reject) => {
      var dto = {
        equipment_Ids: equipment_Ids,
        location_Inspection_ID: job_Id

      }
      console.log(dto);
      this.http.post<ApiResponse>(environment.apiUrl + 'job/postequipment', dto, { headers: headers }).subscribe(data => {
        resolve(data);
        this.snotifyService.success('Successfully saved job assets');
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  complete(id): Promise<ApiResponse> {
    //job.scheduled_Date = moment(job.scheduled_Date).add(-1, "months").format("YYYY/MM/DD");
    const headers = {
      'Content-Type': 'application/json',
    };
    console.log("Completing service");
    console.log(id);
    

    /*job.scheduled_Date = moment(job.scheduled_Date).format('YYYY/MM/DD');*/
    return new Promise((resolve, reject) => {


      this.http.post<ApiResponse>(environment.apiUrl + 'job/completeJob?id=' + id, { headers: headers }).subscribe(data => {
        this.snotifyService.success('Successfully completed job');
        resolve(data);
      }, (error) => {
        resolve(null)

      });

    });
  }

  reopen(id): Promise<ApiResponse> {
    //job.scheduled_Date = moment(job.scheduled_Date).add(-1, "months").format("YYYY/MM/DD");
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {


      this.http.post<ApiResponse>(environment.apiUrl + 'job/reopenJob?id=' + id, { headers: headers }).subscribe(data => {
        this.snotifyService.success('Successfully reopened job');
        resolve(data);
      }, (error) => {
        resolve(null)

      });

    });
  }






  public approve(job): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.put<any>('/api/locationinspection/approve', job).subscribe(data => {
        resolve(data);
        this.snotifyService.success('Successfully approved job');
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  

  public delete(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get('/api/locationinspection/delete?id=' + id).subscribe(data => {
        this.snotifyService.success('Successfully deleted job');
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getExcel(company_Id: number, location_Id: number, job_No: string,
    created_start_Date: string, created_end_Date: string, status: number, quickSearch: string, pdaUserId:number): Promise<any> {
    return new Promise((resolve, reject) => {
      if (job_No == "") {
        job_No = "null"
      };
      let params = '?company_Id=' + company_Id;
      params += '&location_Id=' + location_Id;
      params += '&job_No=' + job_No;
      /*      params += '&completed=' + completed;*/
      params += '&created_start_Date=' + moment(created_start_Date).format('YYYY-MM-DD');
      params += '&created_end_Date=' + moment(created_end_Date).add(1, 'days').format('YYYY-MM-DD');
      params += '&status=' + status;
      params += '&quickSearch=' + quickSearch;
      params += '&pdaUserId=' + pdaUserId;

      this.http.get<any>(environment.apiUrl + 'job/getexcel' + params).subscribe(data => {
        let blob = this.converBase64toBlob(data, 'application/vnd.ms-excel');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getUnapprovedExcel(page: Page, company_Id: number, location_Id: number, job_No: string, completed: boolean,
    created_start_Date: string, created_end_Date: string, scheduled_start_Date: string, scheduled_end_Date: string, filterbyScheduled: boolean, filterbyCreated: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      let params = '?company_Id=' + company_Id;
      params += '&location_Id=' + location_Id;
      params += '&job_No=' + job_No;
      params += '&completed=' + completed;
      params += '&created_start_Date=' + moment(created_start_Date).format('YYYY-MM-DD');
      params += '&created_end_Date=' + moment(created_end_Date).format('YYYY-MM-DD');
      params += '&scheduled_start_Date=' + moment(scheduled_start_Date).format('YYYY-MM-DD');
      params += '&scheduled_end_Date=' + moment(scheduled_end_Date).format('YYYY-MM-DD');
      params += '&filterbyScheduled=' + filterbyScheduled;
      params += '&filterbyCreated=' + filterbyCreated;
      this.http.get<any>('/api/locationinspection/getexcel' + params).subscribe(data => {
        let blob = this.converBase64toBlob(data, 'application/vnd.ms-excel');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
  converBase64toBlob(content, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;
    let byteCharacters = window.atob(content); //method which converts base64 to binary
    let byteArrays = [
    ];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);
      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    let blob = new Blob(byteArrays, {
      type: contentType
    }); //statement which creates the blob
    return blob;
  }



}
