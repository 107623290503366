import { Component, OnInit } from '@angular/core';
import { Location } from '../../../../models/location';
import { Company } from '../../../../models/company';
import { Category } from '../../../../models/category';
import { Page } from '../../../../models/paging/page';
import { Equipment } from '../../../../models/equipment';
import { CompanyService } from '../../../../services/company.service';
import { LocationService } from '../../../../services/location.service';
import { CategoryService } from '../../../../services/category.service';
import { EquipmentService } from '../../../../services/equipment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs as importedSaveAs } from 'file-saver';
import { ColumnService } from '../../../../services/column.service';
import { AuthService } from '../../../../services/auth.service';
import { ModalService } from '../../../../services/modal.service';
import { TabletService } from '../../../../services/tablet.service';
import { Tablet } from '../../../../models/tablet';
import { InspectionService } from '../../../../services/inspection.service';
import { ColourService } from '../../../../services/colour.service';
import { Colour } from '../../../../models/colour';
import * as moment from 'moment';
import { QuickInspect } from '../../../../models/quickInspect';
import { ConfirmationService } from 'primeng/api';
import { StorageService } from '../../../../services/storage.service';

@Component({
  selector: 'app-job-inspect',
  templateUrl: './job-inspect.component.html',
  styleUrls: ['./job-inspect.component.css']
})
export class JobInspectComponent implements OnInit {

  filter: any = {
    company_Id: 0,
    location_Id: 0,
    category_Id: 0,
    serial_Num: '',
    asset_Num: '',
    quickSearch: '',
  };

  locationList: Location[];

  companies: Company[];
  locations: Location[];
  init: boolean;
  categories: Category[];
  toggleOpen = false;
  job_Id;

  users: Tablet[];
  pdA_User_ID: number = -1;
  pin;
  PDA_User: Tablet;
  signed_In = false;
  urlUser;
  colours: Colour[];
  purposes;
  quickInspect: QuickInspect;


  quick_Pass_List = [];

  page = new Page();
  rows = Array<Equipment>();

  public readonly pageLimitOptions = [
    { value: 5 },
    { value: 10 },
    { value: 25 },
    { value: 50 },
    { value: 100 },
  ];


  public currentPageLimit: number = 10;

  columns = [
    { name: 'Description', prop: 'description' },
    { name: 'Serial #', prop: 'serial_Num' },
    { name: 'Asset #', prop: 'asset_Num' },
    { name: 'SWL', prop: 'swl' },
    { name: 'Category', prop: 'category' },
    { name: 'Manufacturer', prop: 'manufacturer' },
    { name: 'Date of Manufacturer', prop: 'date_Of_Manuf_Formatted' },
    { name: 'Created', prop: 'created_Formatted' },
    { name: 'Owner', prop: 'owner_Formatted' },
    { name: 'Original CE', prop: 'original_Cert' },
    { name: 'Other Details', prop: 'other_Details' },
    { name: 'Internal Location', prop: 'internal_Location' },
    { name: 'State', prop: 'state' },
  ];

  allColumns = [
    { name: 'Description', prop: 'description' },
    { name: 'Serial #', prop: 'serial_Num' },
    { name: 'Asset #', prop: 'asset_Num' },
    { name: 'SWL', prop: 'swl' },
    { name: 'Category', prop: 'category' },
    { name: 'Manufacturer', prop: 'manufacturer' },
    { name: 'Date of Manufacturer', prop: 'date_Of_Manuf_Formatted' },
    { name: 'Created', prop: 'created_Formatted' },
    { name: 'Owner', prop: 'owner_Formatted' },
    { name: 'Original CE', prop: 'original_Cert' },
    { name: 'Other Details', prop: 'other_Details' },
    { name: 'Internal Location', prop: 'internal_Location' },
    { name: 'State', prop: 'state' },
  ];




  toggle(col) {
    const isChecked = this.isChecked(col);

    if (isChecked) {
      this.columns = this.columns.filter(c => {
        return c.name !== col.name;
      });
    } else {
      this.columns = [...this.columns, col];
    }
    this.toggleSelection();
  }

  isChecked(col) {
    return (
      this.columns.find(c => {
        return c.name === col.name;
      }) !== undefined
    );
  }

  toggleSelection() {
    this.toggleOpen = !this.toggleOpen;
  }

  constructor(public companyService: CompanyService, public locationService: LocationService, public categoryService: CategoryService,
    public equipmentService: EquipmentService, private confirmationService: ConfirmationService,
    private inspectionService: InspectionService, public colourService: ColourService,
    public tabletService: TabletService, public router: Router, public modalService: ModalService,
    public route: ActivatedRoute, private authService: AuthService, private columnService: ColumnService,
    public storageService: StorageService) {

    this.route.queryParams.subscribe(params => {
      const from_Inspection = params['from_Inspection'];
      console.log(from_Inspection);
      if (!from_Inspection) {
        this.storageService.dataRemove("pda_User");
      }
    });


    var user = JSON.parse(this.storageService.get("pda_User"));
    if (user != undefined) {
      console.log("getting previous user");
      console.log(user);
      this.PDA_User = user;
      this.signed_In = true;
    }

    

    this.page.pageNumber = 0;
    this.page.size = 10;
    this.tabletService.getAll().then((data) => {
      this.users = data;
      console.log("getting users");
      console.log(this.users);
    });

    this.colourService.getAll().then((data) => {
      this.colours = data;
    });

    this.purposes = [
      { id: 1, purpose: "Within an interval of 6 months" }
      , { id: 2, purpose: "Within an interval of 12 months" }
      , { id: 3, purpose: "In accordance with an examination scheme" }
      , { id: 3, purpose: "After an occurence of exceptional circumstance" }
    ];
    //this.quickInspect = new QuickInspect();
    

  }

  ngOnInit() {
    //this.setPage({ offset: 0 });
    //this.init = true;
    
    this.quickInspect = new QuickInspect();
    this.quickInspect.inspected_At = moment(moment().toDate()).format("YYYY-MM-DD") ;
    this.quickInspect.purpose_ID = 2;
    this.quickInspect.colour_Id = 1;
    this.quickInspect.scrap = '';
    var page = JSON.parse(this.storageService.get("job_inspect_page"));
    var filter = JSON.parse(this.storageService.get("job_inspect_filters"));
    if (page != undefined) {
      this.page = page;
      console.log(this.page);
    }
    if (filter != undefined) {
      this.filter = filter;
      this.filter.start_Date = moment(this.filter.start_Date).toDate();
      this.filter.end_Date = moment(this.filter.end_Date).toDate();
    }
    if (page == undefined && filter == undefined) {
      console.log("no filters");
      this.setPage({ offset: 0 });
      this.init = true;
      //this.resetFilters();
    } else {
      this.currentPageLimit = this.page.size;
      this.loadTable(this.page, false);
    }
  }

  login() {
    this.openModel('popup-engineer-login');
  }

  submitlogin() {
    if (this.pdA_User_ID == undefined) {
      this.pdA_User_ID = undefined;
      return;
    }
    var user = null;
    //let user = this.users.find((u) => {
    //  return u.pdA_User_ID == this.pdA_User_ID;
    //});
    for (var a = 0; a < this.users.length; a++) {
      if (this.users[a].pdA_User_ID == this.pdA_User_ID) {
        user = this.users[a];
        break;
      }
    }


    console.log(user);
    if (user.android_PIN == this.pin) {
      console.log("success");
      this.signed_In = true;
      this.PDA_User = user;
      this.modalService.close('popup-engineer-login');
    }
    this.storageService.dataSave("pda_User", JSON.stringify(this.PDA_User));
    this.pin = '';

  }

  //log_User_In_From_Url(pda_User_Id) {
  //  if (this.pdA_User_ID == undefined) {
  //    this.pdA_User_ID = undefined;
  //    return;
  //  }
  //  var user = null;
  //  //let user = this.users.find((u) => {
  //  //  return u.pdA_User_ID == this.pdA_User_ID;
  //  //});
  //  console.log(this.users);

  //  for (var a = 0; a < this.users.length; a++) {
  //    if (this.users[a].pdA_User_ID == pda_User_Id) {
  //      user = this.users[a];
  //      break;
  //    }
  //  }
  //  this.signed_In = true;
  //  this.PDA_User = user;

  //}
  openModel(id) {
    this.modalService.open(id);
  }

  back() {
    this.storageService.dataRemove("pda_User");
    this.router.navigate(['administration/jobs']);

  }
  inspect(row) {
    console.log(row);
    this.router.navigate(['administration/jobs/inspect-asset'], { queryParams: { id: row.equipment_ID, jobId: this.job_Id, pda_user_Id: this.PDA_User.pdA_User_ID } });
  }


  // ###################################
  // TABLE
  // ###################################
  public onLimitChange(limit: any): void {
    this.page.size = parseInt(limit, 10);
    this.loadTable(this.page, false);
  }
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page,false);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page, false);
  }

  loadTable(page: Page, clicked) {
    if (clicked) {
      this.page.pageNumber = 0;
      this.currentPageLimit = this.page.size;
      this.setPage({ offset: 0 });
      return;
    }
    this.storageService.dataSave("job_inspect_filters", JSON.stringify(this.filter));
    this.storageService.dataSave("job_inspect_page", JSON.stringify(this.page));

    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      this.job_Id = id
      //this.urlUser = params['pda_user_Id'];
      //if (this.urlUser != undefined && this.urlUser != null ) {
      //  console.log(this.urlUser);
      //  this.log_User_In_From_Url(this.urlUser);
      //}
      
      if (id != undefined) {
        this.equipmentService.getJobEquipment(this.page, id, this.filter.quickSearch).then((pagedData) => {
          this.page = pagedData.page;
          this.rows = pagedData.data;
          console.log(this.rows);
          if (this.init) {
            this.gotoTop();
            this.init = false;
          }
          for (const row of this.rows) {
            if (this.quick_Pass_List.includes(row.equipment_ID)) {
              row.addedQuickPass = 1;
            }

          }
        });

      }
    });

    
    this.getcolumns();
  }

  getcolumns() {
    this.columnService.getColumns(this.authService.currentUser.value.userId, "jobInspect").then((data) => {
      if (data.columns != null) {
        this.columns = this.columns.filter(c => {
          return data.columns.includes(c.name);
        });
        this.toggleOpen = false;
      }

    });
  }
  SaveSelection() {
    let result = this.columns.map(a => a.name);
    this.columnService.save(this.authService.currentUser.value.userId, "jobInspect", result).then((data) => {
    });
  }


  quickPass() {
    this.quickInspect.inspected_At = moment(this.quickInspect.inspected_At).format("YYYY-MM-DD");
    this.quickInspect.colour_Id = +this.quickInspect.colour_Id;
    this.job_Id = +this.job_Id;
    this.PDA_User.pdA_User_ID = +this.PDA_User.pdA_User_ID;
    this.quickInspect.purpose_ID = +this.quickInspect.purpose_ID;

    this.inspectionService.saveQuickPass(this.quick_Pass_List, this.job_Id, this.PDA_User.pdA_User_ID,
      this.quickInspect.inspected_At, this.quickInspect.purpose_ID, this.quickInspect.colour_Id).then((data) => {
        this.quick_Pass_List = [];
        this.page.pageNumber = 0;
        this.page.size = 10;
        this.loadTable(this.page, false);
    });
  }

  quickMissing() {
    this.quickInspect.inspected_At = moment(this.quickInspect.inspected_At).format("YYYY-MM-DD");
    this.quickInspect.colour_Id = +this.quickInspect.colour_Id;
    this.job_Id = +this.job_Id;
    this.PDA_User.pdA_User_ID = +this.PDA_User.pdA_User_ID;
    this.quickInspect.purpose_ID = +this.quickInspect.purpose_ID;

    this.inspectionService.saveQuickMissing(this.quick_Pass_List, this.job_Id, this.PDA_User.pdA_User_ID,
      this.quickInspect.inspected_At, this.quickInspect.purpose_ID, this.quickInspect.colour_Id).then((data) => {
        this.quick_Pass_List = [];
        this.page.pageNumber = 0;
        this.page.size = 10;
        this.loadTable(this.page, false);
    });
  }
  quickScrap() {
    this.quickInspect.inspected_At = moment(this.quickInspect.inspected_At).format("YYYY-MM-DD");
    this.quickInspect.colour_Id = +this.quickInspect.colour_Id;
    this.job_Id = +this.job_Id;
    this.PDA_User.pdA_User_ID = +this.PDA_User.pdA_User_ID;
    this.quickInspect.purpose_ID = +this.quickInspect.purpose_ID;

    this.inspectionService.saveQuickScrap(this.quick_Pass_List, this.job_Id, this.PDA_User.pdA_User_ID,
      this.quickInspect.inspected_At, this.quickInspect.purpose_ID, this.quickInspect.colour_Id, this.quickInspect.scrap).then((data) => {
        this.quick_Pass_List = [];
        this.quickInspect.scrap = '';
        this.page.pageNumber = 0;
        this.page.size = 10;
        this.loadTable(this.page, false);
      });
  }

  remainingMissing() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want set remaining items as missing?</br><br/>',
      accept: () => {
        this.quickInspect.inspected_At = moment(this.quickInspect.inspected_At).format("YYYY-MM-DD");
        this.quickInspect.colour_Id = +this.quickInspect.colour_Id;
        this.job_Id = +this.job_Id;
        this.PDA_User.pdA_User_ID = +this.PDA_User.pdA_User_ID;
        this.quickInspect.purpose_ID = +this.quickInspect.purpose_ID;

        this.inspectionService.saveRemainingMissing( this.job_Id, this.PDA_User.pdA_User_ID,
          this.quickInspect.inspected_At, this.quickInspect.purpose_ID, this.quickInspect.colour_Id).then((data) => {
            this.quick_Pass_List = [];
            this.quickInspect.scrap = '';
            this.page.pageNumber = 0;
            this.page.size = 10;
            this.loadTable(this.page, false);
          });

        //var equip = this.rows.filter(r => {
        //  return r.completed == false;
        //});
        //if (equip == undefined || equip.length == 0) {
        //  return;
        //}
        //for (var a = 0; a < equip.length; a++) {
        //  this.addQuickPass(equip[a]);
        //}
        //this.quickMissing();
      }
    });
  }
  quickDecommission() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to decommission these items?</br><br/>',
      accept: () => {
        this.quickInspect.inspected_At = moment(this.quickInspect.inspected_At).format("YYYY-MM-DD");
        this.quickInspect.colour_Id = +this.quickInspect.colour_Id;
        this.job_Id = +this.job_Id;
        this.PDA_User.pdA_User_ID = +this.PDA_User.pdA_User_ID;
        this.quickInspect.purpose_ID = +this.quickInspect.purpose_ID;

        this.inspectionService.saveQuickDecommission(this.quick_Pass_List, this.job_Id, this.PDA_User.pdA_User_ID,
          this.quickInspect.inspected_At, this.quickInspect.purpose_ID, this.quickInspect.colour_Id).then((data) => {
            this.quick_Pass_List = [];
            this.quickInspect.scrap = '';
            this.page.pageNumber = 0;
            this.page.size = 10;
            this.loadTable(this.page, false);
          });
      }
    });
  }



  addQuickPass(row) {
    this.quick_Pass_List.push(row.equipment_ID);
    row.addedQuickPass = 1;
    console.log(this.quick_Pass_List);
  }
  removeQuickPass(row) {
    let idx = this.quick_Pass_List.indexOf(row.equipment_ID);
    this.quick_Pass_List.splice(idx, 1);
    row.addedQuickPass = 0;
    console.log(this.quick_Pass_List);
  }




  generateExcel() {
    this.equipmentService.getJobEquipExcel(this.job_Id, this.filter.quickSearch).then((data) => {
        importedSaveAs(data, 'Report.xlsx');
      });
  }
  gotoTop() {
    console.log("GOTO TOP");
    // window.scrollTo(-9999,-99999);
    let top = document.getElementById('top');
    console.log(top);
    if (top !== null) {
      top.scrollIntoView(true);
      //  document.getElementById("top").scrollTop -= 99999;
      top = null;
    }
  }
}
