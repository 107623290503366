import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { PagedData } from '../models/paging/paged-data';
import { Page } from '../models/paging/page';
import { Company } from '../models/company';
import { ServerData } from '../models/paging/server-data';

import { SnotifyService } from 'ng-snotify';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { Colour } from '../models/colour';
import { ApiResponse } from '../models/api-response';



@Injectable()
export class ColumnService {
  constructor(private http: HttpClient, private snotifyService: SnotifyService, private authService: AuthService) { }
  //public user = JSON.parse(localStorage.getItem("currentUser"));


  public getColumns(user_Id: any, page: string): Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };
    console.log("getting colums - service");
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + 'column/get?user_Id=' + user_Id + '&Page=' + page).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }


  public save(user: any, page: string, columns: any): Promise<ApiResponse> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var dto = {
      user_Id: user,
      page: page,
      columns: columns,
    };

    return new Promise((resolve, reject) => {
      console.log("column service");
      console.log(dto);

      this.http.post<ApiResponse>(environment.apiUrl + 'column/post', dto, { headers: headers }).subscribe(data => {
        resolve(data);
        this.snotifyService.success('Successfully saved column preferences');
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

}
