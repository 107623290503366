import { Component, OnInit } from '@angular/core';
import { CompanyService } from '../../../services/company.service';
import { Company } from '../../../models/company';
import { Router } from '@angular/router';
import { Page } from '../../../models/paging/page';
import { LocationService } from '../../../services/location.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { AuthService } from '../../../services/auth.service';
import { ColumnService } from '../../../services/column.service';
import { StorageService } from '../../../services/storage.service';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.css']
})
export class LocationsComponent implements OnInit {
  filter: any = {
    name: '',
    quickSearch: '',
    company_Id: 0,
    archived:false
  };
  init: boolean;
  companies: Company[];
  toggleOpen = false;
  user;
  is_admin = false;
  page = new Page();
  rows = Array<any>();
  public readonly pageLimitOptions = [
    { value: 5 },
    { value: 10 },
    { value: 25 },
    { value: 50 },
    { value: 100 },
  ];


  public currentPageLimit: number = 10;

  columns = [
    { name: 'Location Name', prop: 'name' },
    { name: 'Address 1', prop: 'address1' },
    { name: 'Address 2', prop: 'address2' },
    { name: 'Address 3', prop: 'address3' },
    { name: 'Address 4', prop: 'address4' },
    { name: 'Postcode', prop: 'postcode' },
    { name: 'Company', prop: 'companyName' },
  ];

  allColumns = [
    { name: 'Location Name', prop: 'name' },
    { name: 'Address 1', prop: 'address1' },
    { name: 'Address 2', prop: 'address2' },
    { name: 'Address 3', prop: 'address3' },
    { name: 'Address 4', prop: 'address4' },
    { name: 'Postcode', prop: 'postcode' },
    { name: 'Company', prop: 'companyName' },
  ];


  toggle(col) {
    const isChecked = this.isChecked(col);

    if (isChecked) {
      this.columns = this.columns.filter(c => {
        return c.name !== col.name;
      });
    } else {
      this.columns = [...this.columns, col];
    }
    this.toggleSelection();
  }

  isChecked(col) {
    return (
      this.columns.find(c => {
        return c.name === col.name;
      }) !== undefined
    );
  }

  toggleSelection() {
    this.toggleOpen = !this.toggleOpen;
  }

  constructor(private authService: AuthService, private columnService: ColumnService,
    private companyService: CompanyService, private locationService: LocationService, private router: Router, private storageService: StorageService) {
    this.page.pageNumber = 0;
    this.page.size = 10;

    this.user = localStorage.getItem("currentUser");
    this.user = JSON.parse(this.user);
    //console.log(this.user);
    this.is_admin = this.user.userTypeId == 2;

    this.companyService.getAll().then((data) => {
      this.companies = data;

    });
  }


  ngOnInit() {
    //this.loadTable(this.page);
    //this.init = true;
    var page = JSON.parse(this.storageService.get("locations_page"));
    var filter = JSON.parse(this.storageService.get("locations_filters"));
    if (page != undefined) {
      this.page = page;
      console.log(this.page);
    }
    if (filter != undefined) {
      this.filter = filter;
    }
    if (page == undefined && filter == undefined) {
      console.log("no filters");
      this.setPage({ offset: 0 });
      this.init = true;
      this.resetFilters();
    } else {
      this.currentPageLimit = this.page.size;
      this.loadTable(this.page, false);
    }
  }

  resetFilters() {
    this.filter = {
      name: '',
      quickSearch: '',
      company_Id: 0,
      archived:false
    };
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.currentPageLimit = this.page.size;
  }

  edit(row) {
    this.router.navigate(['administration/locations/manage'], { queryParams: { id: row.locationId } });
  }

  // ###################################
  // TABLE
  // ###################################
  public onLimitChange(limit: any): void {
    this.page.size = parseInt(limit, 10);
    this.loadTable(this.page, false);
  }
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page,false);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page, false);
  }

  loadTable(page: Page, clicked) {
    if (clicked) {
      this.page.pageNumber = 0;
      this.currentPageLimit = this.page.size;
      this.setPage({ offset: 0 });
      return;
    }
    if (this.user.userTypeId != 3) { this.filter.archived = false }
    this.storageService.dataSave("locations_filters", JSON.stringify(this.filter));
    this.storageService.dataSave("locations_page", JSON.stringify(this.page));

    if (this.filter.company_Id == null || this.filter.company_Id == undefined) {
      this.filter.company_Id = 0;
    }
    this.locationService.getResults(this.page, this.filter.name, this.filter.company_Id, this.filter.quickSearch, this.filter.archived).then((pagedData) => {
      this.page = pagedData.page;
      this.rows = pagedData.data;
      console.log(this.rows);
      if (this.init) {
        this.gotoTop();
        this.init = false;
      }
    });
    this.getcolumns();
  }

  getcolumns() {
    this.columnService.getColumns(this.authService.currentUser.value.userId, "locations").then((data) => {
      if (data.columns != null) {
        this.columns = this.columns.filter(c => {
          return data.columns.includes(c.name);
        });
        this.toggleOpen = false;
      }

    });
  }
  SaveSelection() {
    let result = this.columns.map(a => a.name);
    this.columnService.save(this.authService.currentUser.value.userId, "locations", result).then((data) => {
    });
  }

  generateExcel() {
    this.locationService.getExcel(this.filter.name, this.filter.company_Id, this.filter.quickSearch).then((data) => {
      importedSaveAs(data, 'Report.xlsx');
    });
  }
  gotoTop() {
    console.log("GOTO TOP");
    // window.scrollTo(-9999,-99999);
    let top = document.getElementById('top');
    console.log(top);
    if (top !== null) {
      top.scrollIntoView(true);
      //  document.getElementById("top").scrollTop -= 99999;
      top = null;
    }
  }
}
