import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { EquipmentService } from '../../../../services/equipment.service';
import { InspectionService } from '../../../../services/inspection.service';
import { TabletService } from '../../../../services/tablet.service';
import * as moment from 'moment';
import { Inspection } from '../../../../models/inspection';
import { ColourService } from '../../../../services/colour.service';
import { DescriptionsService } from '../../../../services/descriptions.service';
import { CategoryService } from '../../../../services/category.service';
import { ManufacturerService } from '../../../../services/manufacturer.service';
import { Manufacturer } from '../../../../models/manufacturer';
import { Page } from '../../../../models/paging/page';
import { toDate } from '@angular/common/src/i18n/format_date';
import { Equipment } from '../../../../models/equipment';


@Component({
  selector: 'app-inspection-edit',
  templateUrl: './inspection-edit.component.html',
  styleUrls: ['./inspection-edit.component.css']
})
export class InspectionEditComponent implements OnInit {
  colours;
  jobs;
  users;
  purposes;
  equipment;
  manufacturers: Manufacturer[] = [];
  categories;
  descriptionList;
  descriptions;

  page = new Page();
  rows = Array<any>();

  inspection;

  interval: number = 0;

  constructor(private inspectionService: InspectionService, public descriptionService: DescriptionsService,
    private colourService: ColourService, private tabletService: TabletService, public categoryService: CategoryService,
    private equipmentService: EquipmentService, public manufacturerService: ManufacturerService, private confirmationService: ConfirmationService,
    private router: Router, private route: ActivatedRoute) {
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.equipment = new Equipment();
    this.equipment.inspection_Status = -1;
    this.equipment.location_Id = -1;
    this.equipment.company_Id = -1;
    this.equipment.owner_ID = 1;
    this.equipment.cE_Num = '';
    this.equipment.category_ID = -1;

    this.purposes = [
      { id: 1, purpose: "Within an interval of 6 months" }
      , { id: 2, purpose: "Within an interval of 12 months" }
      , { id: 3, purpose: "In accordance with an examination scheme" }
      , { id: 3, purpose: "After an occurence of exceptional circumstance" }
    ];
    

    this.inspection = new Inspection();
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.colourService.getAll().then((data) => {
        this.colours = data;
      });
      

      this.tabletService.getAll().then((data) => {
        this.users = data;
      });

      this.manufacturerService.getAll().then((data) => {
        this.manufacturers = data;
        console.log(this.manufacturers);
      });
      this.categoryService.getAll().then((data) => {
        this.categories = data;
      });

      this.descriptionService.getAll().then((data) => {
        this.descriptionList = data;
        this.descriptions = this.descriptionList;
        console.log(this.equipment.category_ID);
        if (this.equipment.category_ID != 0 && this.equipment.category_ID != -1) {
          this.descriptions = this.descriptionList.filter(d => {
            return d.category_ID == this.equipment.category_ID;
          });
        }
      });

      const id = params['id'];
      this.inspectionService.getJobs(id).then((data) => {
        this.jobs = data;
        console.log(this.jobs);
      });

      if (id != undefined) {
        this.inspectionService.get(id).then((data) => {
          this.inspection = data;
          this.rows = data.answered_Questions;

          this.equipment = data.equipment;

          console.log(this.inspection);
          if (this.equipment.date_Of_Manuf != null && this.equipment.date_Of_Manuf != undefined && moment(this.equipment.date_Of_Manuf).format("YYYY-MM-DD") != moment("0001-01-01").format("YYYY-MM-DD")) {
            var str = this.equipment.date_Of_Manuf.split("T", 2);
            this.equipment.date_Of_Manuf = moment(str[0]).format("YYYY-MM-DD");
          } else {
            this.equipment.date_Of_Manuf = undefined;
          }
          if (this.inspection.inspected_At != null && this.inspection.inspected_At != undefined && moment(this.inspection.inspected_At).format("YYYY-MM-DD") != moment("0001-01-01").format("YYYY-MM-DD")) {
            var str = this.inspection.inspected_At.split("T", 2);
            this.inspection.inspected_At = moment(str[0]).format("YYYY-MM-DD");
          } else {
            this.inspection.inspected_At = undefined;
          }
          if (this.inspection.next_Inspection_Date != null && this.inspection.next_Inspection_Date != undefined && moment(this.inspection.next_Inspection_Date).format("YYYY-MM-DD") != moment("0001-01-01").format("YYYY-MM-DD")) {
            var str = this.inspection.next_Inspection_Date.split("T", 2);
            this.inspection.next_Inspection_Date = moment(str[0]).format("YYYY-MM-DD");
          } else {
            this.inspection.next_Inspection_Date = undefined;
          }

          if (this.inspection.danger_Date != null && this.inspection.danger_Date != undefined && this.inspection.danger_Date != '') {
            var str = this.inspection.danger_Date_Formatted.split("T", 2);
            console.log(str[0]);
            this.inspection.danger_Date = moment(str[0]).format("YYYY-MM-DD");
          }

          if (this.equipment.category_ID != 0 && this.equipment.category_ID != -1) {
            this.descriptions = this.descriptionList.filter(d => {
              return d.category_ID == this.equipment.category_ID;
            });
          }

        });
      }
    });
  }

  statusChanged() {
    if (this.inspection.safe_For_Use) {
      let next_Inspection_Date = moment(this.inspection.inspected_At).add(this.equipment.inspection_Interval, 'M').format("YYYY-MM-DD");
      this.inspection.next_Inspection_Date = moment(next_Inspection_Date).format("YYYY-MM-DD");
    } else {
      this.inspection.next_Inspection_Date = moment(this.inspection.inspected_At).format("YYYY-MM-DD");
    }
  }

  dateChanged() {
    if (!this.inspection.safe_For_Use) {
      this.inspection.next_Inspection_Date = this.inspection.inspected_At;
    } else {
      let next_Inspection_Date = moment(this.inspection.inspected_At).add(this.equipment.inspection_Interval, 'M').format("YYYY-MM-DD");
      this.inspection.next_Inspection_Date = moment(next_Inspection_Date).format("YYYY-MM-DD");
    }
  }

  examChanged() {
    if (!this.inspection.first_Examination) {
      this.inspection.installed_Correctly = false;
    } 
  }

  immediateChanged() {
    if (this.inspection.immediate_To_Persons) {
      this.inspection.become_Danger_To_Persons = false;
      this.inspection.danger_Date = '';
      this.inspection.repair = '';
    } 
  }
  becomeChanged() {
    if (this.inspection.become_Danger_To_Persons) {
      this.inspection.immediate_To_Persons = false;

    } 
  }

  onDescriptionChange(value) {
    console.log(value);
    this.equipment.description = value;


  }
  onCategoryChange(value) {
    console.log(value);
    if (value == 0) { value = null; }

    this.descriptions = this.descriptionList.filter(c => {
      return value == null || c.category_ID == value;
    });

    this.equipment.description_ID = null;
  }
  passFail(row) {
    console.log(row);
    row.response = !row.response;
  }
  

  OnSubmit() {
    console.log("submitting");
    if (this.inspection.become_Danger_To_Persons == true && (this.inspection.danger_Date == null || this.inspection.danger_Date == undefined)) {

      alert('error: need to select when this defect could become a danger by.');
      return;
    }

    let status = "";

    if (this.inspection.safe_For_Use) {
      status = "PASS";
    } else {
      status = "FAIL";
    }

    this.confirmationService.confirm({
      message: 'Are you sure this item is a ' + status + '?',
      accept: () => {
        this.inspection.answered_Questions = this.rows;

        this.inspection.inspected_At = moment(this.inspection.inspected_At).toDate();
        this.inspection.next_Inspection_Date = moment(this.inspection.next_Inspection_Date).toDate();

        this.inspection.equipment.owner_ID = +this.inspection.equipment.owner_ID;
        this.inspection.colour_ID = +this.inspection.colour_ID;



        //if (this.inspection.danger_Date != undefined) {
        //  this.inspection.danger_Date = moment(this.inspection.date_Of_First_Use).toDate();
        //}

        if (this.inspection.equipment.date_Of_Manuf != undefined) {
          this.inspection.date_Of_Manuf = moment(this.inspection.equipment.date_Of_Manuf).toDate();
        }
        if (this.inspection.become_Danger_To_Persons == true) {
          this.inspection.danger_Date = moment(this.inspection.danger_Date).format("DD/MM/YYYY 00:00:00");
        } else {
          this.inspection.danger_Date = null;
        }
        console.log(this.inspection);

        this.inspectionService.update(this.inspection).then((data) => {
          this.router.navigate(['reports/inspections']);
        });
      }
    });
  }

}
