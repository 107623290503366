import { Component, OnInit } from '@angular/core';
import { Company } from '../../../../models/company';
import { Location } from '../../../../models/location';
import { CompanyService } from '../../../../services/company.service';
import { LocationService } from '../../../../services/location.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-location-manage',
  templateUrl: './location-manage.component.html',
  styleUrls: ['./location-manage.component.css']
})
export class LocationManageComponent implements OnInit {
  companies: Company[];
  location: Location;
  phoneisvalid: boolean;
  user;

  constructor(private companyService: CompanyService, private locationService: LocationService,
    private router: Router, private route: ActivatedRoute, private confirmationService: ConfirmationService, private snotifyService: SnotifyService) {
    this.location = new Location();
    this.user = localStorage.getItem("currentUser");
    this.user = JSON.parse(this.user);
  }

  ngOnInit() {
    this.phoneisvalid = true; 
    this.companyService.getAll().then((data) => {
      this.companies = data;
      this.route.queryParams.subscribe(params => {
        const id = params['id'];
        console.log(id);
        if (id != undefined) {
          this.locationService.get(id).then((data) => {
            this.location = data;
            this.location.is_edit = true;
          });
        }
      });
    });
  }


  submit() {
    if (this.location.companyId < 1) {
      this.snotifyService.warning('Please enter company');
      return;
    }
    console.log("sub");
    console.log(this.location);
    if (this.location.archived) {
      console.log("is archived");
      this.confirmationService.confirm({
        message: 'Are you sure that you want to mark this location obsolete? This will remove it completely from the system including all Jobs, Assets and Inspections at this location </br><br/>',
        accept: () => {
          this.complete();
        }
      });
    } else {
      this.complete();
    }



  }
  complete() {
    
    this.location.companyId = +this.location.companyId;
    this.locationService.save(this.location).then((data) => {
      this.router.navigate(['administration/locations']);
    });
  }
  checknumber(input: string) {
    console.log(input);
    if (input == undefined || input == null || input == 'null') {
      this.phoneisvalid = true;
      return;
    }

      let num = + input;
      if (num > 0)
        this.phoneisvalid = true;
      else
        this.phoneisvalid = false;
  }

}
