import { Component } from '@angular/core';
import { InspectionService } from '../../services/inspection.service';
import { ServiceInspectionService } from '../../services/service-inspection.service';
import { AuthService } from '../../services/auth.service';
import { CompanyService } from '../../services/company.service';
import { LocationService } from '../../services/location.service';
import { TabletService } from '../../services/tablet.service';
import { ReportableService } from '../../services/reportable.service';
import { Basket_Item } from '../../models/Basket_Item';
import { BasketService } from '../../services/basket.service';
import * as moment from 'moment';
import { Page } from '../../models/paging/page';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {



  date = new Date();
  name = "";
  data = [];
  colorScheme = {};

  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;

  viewtype: 0;
  page = new Page();
  rows = Array<any>();
  init: boolean;
  is_client = false;
  client;
  passed = 0;
  missed = 0;
  failed = 0;

  no_Defect = 0;
  worn = 0;
  safety_Defect = 0;


  single: any[];
  singleSource: any[];



  public view: any[] = [1000, 400];
  public showXAxis = true;
  public showYAxis = true;
  public gradient2 = false;
  public showLegend2 = true;
  public showXAxisLabel = true;
  public xAxisLabel: "days";
  public showYAxisLabel = true;
  public yAxisLabel: "inspections";
  public graphDataChart: any[];
  public colorScheme2 = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA', '#ff8c00', '#ec008c', '#68217a', '#00188f', '#00bcf2', '#00b294', '#bad80a']
  };

  date_from: string;
  date_to: string;
    

  single1: any[];
  ServiceTitle: string = 'Status';
  colorScheme3 = {};

  axisFormat(val) {
    if (val % 1 === 0) {
      return val.toLocaleString();
    } else {
      return '';
    }
  }
  servicexAxisLabel: string = "Month";
  serviceyAxisLabel: string = "No. Inspections";


  data3: any[];
  colorScheme4 = {};


  constructor(authService: AuthService, companyService: CompanyService, locationService: LocationService,
    tabletService: TabletService, public inspectionService: InspectionService, public serviceInspectionService: ServiceInspectionService, public reportableService: ReportableService, public basketService: BasketService) {
    this.is_client = authService.currentUser.value.userType == 0;
    this.client = authService.currentUser.value;

    this.page.pageNumber = 0;
    this.page.size = 10;
    this.page.sortOrder = 'desc';
    this.page.sortBy = "Inspected_At";

    this.date_from = moment().startOf('month').add(-1,'year').format("DD/MM/YYYY").toString();
    this.date_to = moment().endOf('month').add(-1, 'month').format("DD/MM/YYYY").toString();
    

    inspectionService.getAllInspections().then((data) => {
      //console.log(data);
      this.passed = data.passed;
      this.missed = data.missed;
      this.failed = data.failed;

      this.name = 'Angular';

      this.data = [
        { 'name': "Pass", 'value': this.passed },
        { 'name': "Fail", 'value': this.failed },
        { 'name': "Missing", 'value': this.missed }
      ]
      this.colorScheme = {
        domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
      };
    });


    inspectionService.getAllInspectionsEngineers().then((data) => {
      this.single = data;
      this.name = 'Angular';

    });

    inspectionService.getAllInspectionsEngineers().then((data) => {
      this.singleSource = data;
      this.name = 'Angular';

    });


    serviceInspectionService.getAllServiceInspections().then((data) => {
      this.single1 = data;
      this.colorScheme3 = {
        domain: ['#C7B42C', '#A10A28', '#AAAAAA']
      };
      this.name = 'Angular';

    });


    serviceInspectionService.getInAndOutDateInspections().then((data) => {

      this.name = 'Angular';

      this.data3 = [
        { 'name': "In Date", 'value': data.inDate },
        { 'name': "Out Of Date", 'value': data.outDate }
      ]

      this.colorScheme4 = {
        domain: ['#5AA454', '#A10A28']
      };
    });
  }







  onSelect(event) {
    var x = 0;
    for (x; this.single.length; x++) {
      if (this.single[x].name != event) {
        continue;
      }
      else {
        if (this.single[x].series.length > 0) {
          this.single[x].series = {};
        }
        else {
          var y = 0;
          for (y; this.singleSource.length; y++) {
            var singleS = this.singleSource[y];
            if (singleS.name != event) {
              continue;
            }
            else {
              this.single[x].series = singleS.series;
              break;
            }
          }
        }
        this.single = [...this.single];
        break;
      }
    }
   }
   
 }

