export class Equipment_Extra {
  id =0 ;
  serial_Num = '';
  manuf_ID: number;
  model = '';
  year_Of_Manuf = '';
  hoist_Bs_Class = '';
  ct_Bs_Class = '';
  customer_Asset = '';
  equipment_ID =0;
}
