import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PagedData } from '../models/paging/paged-data';
import { Page } from '../models/paging/page';
import { ServerData } from '../models/paging/server-data';
import { AuthService } from '../services/auth.service';
import * as moment from 'moment';
import { environment } from '../../environments/environment';


@Injectable()
export class AssetRegisterService {
  constructor(private http: HttpClient, private authService: AuthService) { }

  public getResults(page: Page, company_Id: number, location_Id: number, start_Date: string, end_Date: string,
    serial_Num: string, status: number, quickSearch: string, job_No: string, decommissioned: boolean, category_Id: number): Promise<PagedData<any>> {
    const pagedData = new PagedData<any>();
    console.log("getting asset register");
    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'Serial_Num'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'desc'; }

      //if (page.sortBy == "last_Inspection_Date")
      //  page.sortBy = "Last_Inspection_Date_Formatted";

      //if (page.sortBy == "next_Inspection_Date")
      //  page.sortBy = "Next_Inspection_Date_Formatted";
      //page.sortBy = 'PDA_StartTime';
      //page.sortOrder = 'desc';


      let params = '&start_Date=' + moment(start_Date).format('YYYY-MM-DD');
      params += '&end_Date=' + moment(end_Date).add(1, 'days').format('YYYY-MM-DD');
      params += '&company_Id=' + company_Id;
      params += '&location_Id=' + location_Id;
      
      params += '&serial_Num=' + serial_Num;
      params += '&status=' + status;
      params += '&quickSearch=' + quickSearch;
      params += '&job_No=' + job_No;
      params += '&decommissioned=' + decommissioned;
      params += '&category_Id=' + category_Id;
      console.log(params);
      this.http.get<ServerData>(environment.apiUrl + 'assetregister/gettable?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params).subscribe(data => {
        page.totalElements = data.total;
        page.totalPages = page.totalElements / page.size;
        console.log(data);
        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }

  public getAll(page: Page, company_Id: String, location_Id: String, start_Date: string, end_Date: string, serial_Num: string, status: number): Promise<any> {

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'Serial_Num'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'asc'; }

      let params = '&company_Id=' + company_Id;
      params += '&location_Id=' + location_Id;
      params += '&start_Date=' + start_Date;
      params += '&end_Date=' + end_Date;
      params += '&serial_Num=' + serial_Num;
      params += '&status=' + status;

      this.http.get<any>('/api/assetregister/getall?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params).subscribe(data => {
        
        resolve(data.data);

      }, error => {
        reject();
      });
    });
  }

  public getExcel(company_Id: number, location_Id: number, start_Date: string, end_Date: string,
    serial_Num: string, status: number, quickSearch: string, job_No: string, category_Id:number): Promise<any> {
    return new Promise((resolve, reject) => {
      let params = '?company_Id=' + company_Id;
      params += '&location_Id=' + location_Id;
      params += '&start_Date=' + moment(start_Date).format('YYYY-MM-DD');
      params += '&end_Date=' + moment(end_Date).add(1, 'days').format('YYYY-MM-DD');
      params += '&serial_Num=' + serial_Num;
      params += '&status=' + status;
      params += '&quickSearch=' + quickSearch;
      params += '&job_No=' + job_No;
      params += '&category_Id=' + category_Id;

      this.http.get<any>(environment.apiUrl + 'assetregister/getexcel' + params).subscribe(data => {
        let blob = this.converBase64toBlob(data, 'application/vnd.ms-excel');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }



  converBase64toBlob(content, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;
    let byteCharacters = window.atob(content); //method which converts base64 to binary
    let byteArrays = [
    ];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);
      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    let blob = new Blob(byteArrays, {
      type: contentType
    }); //statement which creates the blob
    return blob;
  }
}
