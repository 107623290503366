import { Component, OnInit } from '@angular/core';
import { CompanyService } from '../../../services/company.service';
import { Page } from '../../../models/paging/page';
import { Router } from '@angular/router';
import { saveAs as importedSaveAs } from 'file-saver';
import { AuthService } from '../../../services/auth.service';
import { ColumnService } from '../../../services/column.service';
import { StorageService } from '../../../services/storage.service';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css']
})
export class CompaniesComponent implements OnInit {
  filter: any = {
    name: '',
    quickSearch: '',
  };
  toggleOpen = false;
  user;
  is_admin = false;

  public readonly pageLimitOptions = [
    { value: 5 },
    { value: 10 },
    { value: 25 },
    { value: 50 },
    { value: 100 },
  ];


  public currentPageLimit: number = 10;
  columns = [
    { name: 'Name', prop: 'companyName' },
    { name: 'Address 1', prop: 'address1' },
    { name: 'Address 2', prop: 'address2' },
    { name: 'County', prop: 'county' },
    { name: 'Postcode', prop: 'postcode' },
    { name: 'Contact', prop: 'contactName' },
    { name: 'Auto Email', prop: 'autoEmail_Formatted' },
  ];

  allColumns = [
    { name: 'Name', prop: 'companyName' },
    { name: 'Address 1', prop: 'address1' },
    { name: 'Address 2', prop: 'address2' },
    { name: 'County', prop: 'county' },
    { name: 'Postcode', prop: 'postcode' },
    { name: 'Contact', prop: 'contactName' },
    { name: 'Auto Email', prop: 'autoEmail_Formatted' },
  ];




  toggle(col) {
    const isChecked = this.isChecked(col);

    if (isChecked) {
      this.columns = this.columns.filter(c => {
        return c.name !== col.name;
      });
    } else {
      this.columns = [...this.columns, col];
    }
    this.toggleSelection();
  }

  isChecked(col) {
    return (
      this.columns.find(c => {
        return c.name === col.name;
      }) !== undefined
    );
  }

  toggleSelection() {
    this.toggleOpen = !this.toggleOpen;
  }


  page = new Page();
  rows = Array<any>();
  init: boolean;
  constructor(private authService: AuthService, private columnService: ColumnService,
    private companyService: CompanyService, private router: Router, private storageService: StorageService) {
    this.page.pageNumber = 0;
    this.page.size = 10;

    this.user = localStorage.getItem("currentUser");
    this.user = JSON.parse(this.user);
    //console.log(this.user);
    this.is_admin = this.user.userTypeId == 2;
  }

  ngOnInit() {
    //this.loadTable(this.page);
    //this.init = true;
    var page = JSON.parse(this.storageService.get("company_page"));
    var filter = JSON.parse(this.storageService.get("company_filters"));
    if (page != undefined) {
      this.page = page;
      console.log(this.page);
    }
    if (filter != undefined) {
      this.filter = filter;
    }
    if (page == undefined && filter == undefined) {
      console.log("no filters");
      this.setPage({ offset: 0 });
      this.init = true;
      this.resetFilters();
    } else {
      this.currentPageLimit = this.page.size;
      this.loadTable(this.page, false);
    }
  }

  resetFilters() {
    this.filter = {
      name: '',
      quickSearch: '',
    };
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.currentPageLimit = this.page.size;
  }

  edit(row) {
    console.log(row.companyId);
    this.router.navigate(['administration/companies/manage'], { queryParams: { id: row.companyId } });
  }

  // ###################################
  // TABLE
  // ###################################
  public onLimitChange(limit: any): void {
    this.page.size = parseInt(limit, 10);
    this.loadTable(this.page,false);
  }
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page, false);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page, false);
  }

  loadTable(page: Page, clicked) {

    if (clicked) {
      this.page.pageNumber = 0;
      this.currentPageLimit = this.page.size;
      this.setPage({ offset: 0 });
      return;
    }
    this.storageService.dataSave("company_filters", JSON.stringify(this.filter));
    this.storageService.dataSave("company_page", JSON.stringify(this.page));
    this.companyService.getResults(this.page, this.filter.name, this.filter.quickSearch).then((pagedData) => {
      this.page = pagedData.page;
      this.rows = pagedData.data;
      if (this.init) {
        this.gotoTop();
        this.init = false;
      }
    });
    this.getcolumns();
  }
  getcolumns() {
    this.columnService.getColumns(this.authService.currentUser.value.userId, "companies").then((data) => {
      if (data.columns != null) {
        this.columns = this.columns.filter(c => {
          return data.columns.includes(c.name);
        });
        this.toggleOpen = false;
      }

    });
  }
  SaveSelection() {
    let result = this.columns.map(a => a.name);
    this.columnService.save(this.authService.currentUser.value.userId, "companies", result).then((data) => {
    });
  }

  generateExcel() {
    this.companyService.getExcel(this.filter.name, this.filter.quickSearch).then((data) => {
      importedSaveAs(data, 'Report.xlsx');
    });
  }
  gotoTop() {
    console.log("GOTO TOP");
    // window.scrollTo(-9999,-99999);
    let top = document.getElementById('top');
    console.log(top);
    if (top !== null) {
      top.scrollIntoView(true);
      //  document.getElementById("top").scrollTop -= 99999;
      top = null;
    }
  }
}
