import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Category } from '../../../../models/category';
import { Description } from '../../../../models/description';
import { CategoryService } from '../../../../services/category.service';
import { DescriptionsService } from '../../../../services/descriptions.service';

@Component({
  selector: 'app-descriptions-manage',
  templateUrl: './descriptions-manage.component.html',
  styleUrls: ['./descriptions-manage.component.css']
})
export class DescriptionsManageComponent implements OnInit {
  description: Description;
  categories;

  constructor(public categoryService: CategoryService, public descriptionService: DescriptionsService, public router: Router, private route: ActivatedRoute) {
    this.description = new Description();
    //this.categoryService.getParentCategories().then((data) => {
    //  this.parent_Categories = data;
    //});
    categoryService.getAll().then((data) => {
      this.categories = data;
    });

  }

  ngOnInit() {
    // Load our id if one exists
    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      if (id != undefined) {
        this.descriptionService.get(id).then((data) => {
          this.description = data;
          //this.description.is_edit = true;
        });
      }
    });
  }

  submit() {
    this.descriptionService.save(this.description).then((data) => {
      this.router.navigate(['administration/descriptions']);
    });
  }

}
