import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Manufacturer } from '../../../../models/manufacturer';
import { ManufacturerService } from '../../../../services/manufacturer.service';

@Component({
  selector: 'app-manufacturer-manage',
  templateUrl: './manufacturer-manage.component.html',
  styleUrls: ['./manufacturer-manage.component.css']
})
export class ManufacturerManageComponent implements OnInit {
  manufacturer: Manufacturer;

  constructor(public manufacturerService: ManufacturerService, public router: Router, private route: ActivatedRoute) {
    this.manufacturer = new Manufacturer();
  }

  ngOnInit() {
    // Load our id if one exists
    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      if (id != undefined) {
        this.manufacturerService.get(id).then((data) => {
          this.manufacturer = data;
          this.manufacturer.is_edit = true;
        });
      }
    });
  }

  submit() {
    this.manufacturerService.save(this.manufacturer).then((data) => {
      this.router.navigate(['administration/manufacturers']);
    });
  }
}
