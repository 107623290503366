import { Component, OnInit } from '@angular/core';

import { Company } from '../../../models/company';
import { Location } from '../../../models/location';
import * as moment from 'moment';
import { CompanyService } from '../../../services/company.service';
import { LocationService } from '../../../services/location.service';
import { JobService } from '../../../services/job.service';

import { Page } from '../../../models/paging/page';
import { Location_Inspection } from '../../../models/location_inspection';
import { ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import { saveAs as importedSaveAs } from 'file-saver';


@Component({
  selector: 'app-job-approval',
  templateUrl: './job-approval.component.html',
  styleUrls: ['./job-approval.component.css']
})
export class JobApprovalComponent implements OnInit {
  filter: any = {
    company_Id: 0,
    location_Id: 0,
    job_No: '',
    created_date_Range: 12,
    created_start_Date: moment().add(-12, 'months').toDate(),
    created_end_Date: moment().toDate(),
    scheduled_date_Range: 12,
    scheduled_start_Date: moment().add(-12, 'months').toDate(),
    scheduled_end_Date: moment().toDate(),
    filterscheduled: false,
    filtercreated:false
  };
  init: boolean;
  locationList: Location[];

  companies: Company[];
  locations: Location[];

  page = new Page();
  rows = Array<Location_Inspection>();


  constructor(public companyService: CompanyService, public locationService: LocationService, public jobService: JobService,
    public confirmationService: ConfirmationService, public router: Router) {
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.page.sortBy = "Created_At";
    this.page.sortOrder = "asc";

    companyService.getAll().then((data) => {
      this.companies = data;
    });

    locationService.getAll().then((data) => {
      this.locationList = data;
      this.locations = this.locationList;
    });
  }

  ngOnInit() {
    this.setPage({ offset: 0 });
    this.init = true;
  }

  onCompanyChange(value) {
    this.filter.location_Id = 0;
    if (value == 0) { value = null; }

    this.locations = this.locationList.filter(c => {
      return value == null || c.companyId == value;
    });
  }

  approve(job) {
    this.router.navigate(['administration/approval/details', job.id]);
  }

  reject(job) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want reject this job?</br><br/>',
      accept: () => {
        this.jobService.delete(job.id).then(() => {
          this.loadTable(this.page);
        });
      }
    });
  }

  // ###################################
  // TABLE
  // ###################################
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page);
  }

  loadTable(page: Page) {
    this.jobService.getApproveResults(this.page, this.filter.company_Id, this.filter.location_Id, this.filter.job_No, true,
      this.filter.created_start_Date, this.filter.created_end_Date, this.filter.scheduled_start_Date, this.filter.scheduled_end_Date, this.filter.filterscheduled, this.filter.filtercreated).then((pagedData) => {
      console.log(pagedData);
      this.page = pagedData.page;
      this.rows = pagedData.data;
      if (this.init) {
        this.gotoTop();
        this.init = false;
      }
    });
  }
  edit(job) {
    this.router.navigate(['administration/jobs/manage'], { queryParams: { id: job.id, fromAmend: true} });
  }
  dateChange(ev, type) {
    if (ev == -1) {
      return;
    }

    let date = moment();
    if (ev == 0) {
      date = date.subtract(1, 'weeks');
    } else {
      date = date.subtract(ev, 'months');
    }

    let jsDate = date.toDate();
    //var startDate: NgbDateStruct = { year: jsDate.getFullYear(), month: jsDate.getMonth() + 1, day: jsDate.getDate() };
    if (type == 1)
      this.filter.created_start_Date = jsDate;
    else if (type == 2)
      this.filter.scheduled_start_Date = jsDate;
  }

  generateExcel() {
    this.jobService.getUnapprovedExcel(this.page, this.filter.company_Id, this.filter.location_Id, this.filter.job_No, true,
      this.filter.created_start_Date, this.filter.created_end_Date, this.filter.scheduled_start_Date, this.filter.scheduled_end_Date, this.filter.filterscheduled, this.filter.filtercreated).then((data) => {
        importedSaveAs(data, 'Report.xlsx');
      });
  }
  gotoTop() {
    console.log("GOTO TOP");
    // window.scrollTo(-9999,-99999);
    let top = document.getElementById('top');
    console.log(top);
    if (top !== null) {
      top.scrollIntoView(true);
      //  document.getElementById("top").scrollTop -= 99999;
      top = null;
    }
  }
}
