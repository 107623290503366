import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PartService } from '../../../../services/parts.service';
import { JobService } from '../../../../services/job.service';
import { InspectionService } from '../../../../services/inspection.service';
import { ReportableService } from '../../../../services/reportable.service';
import { BasketService } from '../../../../services/basket.service';
import { Page } from '../../../../models/paging/page';
import { saveAs as importedSaveAs } from 'file-saver';

@Component({
  selector: 'app-job-amend',
  templateUrl: './job-amend.component.html',
  styleUrls: ['./job-amend.component.css']
})
export class JobAmendComponent implements OnInit {

  job;
  parts;
  selectedPart_Id = 0;
  estr_complete: boolean;
  cachedJob;
  viewtype: 0;
  page = new Page();
  rows = Array<any>();

  constructor(public actr: ActivatedRoute, public partService: PartService, public jobService: JobService, public router: Router,
    public inspectionService: InspectionService, public reportableService: ReportableService, public basketService: BasketService) {
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.page.sortOrder = 'desc';
    this.page.sortBy = "inspected_At";

    partService.get().then((data) => {
      this.parts = data;
    });
  }

  ngOnInit() {
    this.viewtype = 0;
    this.estr_complete = true;
    this.actr.data.subscribe((data) => {
      console.log(data);
      this.job = data.job;
      if (data.job.engineer_Reports[0] == undefined || data.job.engineer_Reports[0] == null)
        this.estr_complete = false;

        this.job.engineer_Report = data.job.engineer_Reports[0];
      
      this.cachedJob = JSON.parse(JSON.stringify(this.job));
    });

    console.log(this.job);
    this.loadTable(this.page);
  }

  selectReport(report) {
    this.job.engineer_Report = report;
  }

  removePart(part) {
    part.removed = true;
  }

  addPart() {
    if (this.selectedPart_Id == 0) {
      return;
    }

    let part = this.parts.find(a => a.id == this.selectedPart_Id);
    let newPart = {
      id: 0,
      part: part,
      part_Id: this.selectedPart_Id
    };

    this.job.engineer_Report.parts.push(newPart);
  }

  approve() {
    // we want to check if this job has been modified.
    // if it hasn't then we do not need to do the update on the server

    if (JSON.stringify(this.job) == JSON.stringify(this.cachedJob)) {
      this.job.modified = false;
    } else {
      this.job.modified = true;
    }

    this.jobService.approve(this.job).then((data) => {
      this.router.navigate(['administration/approval']);
    });
  }
  // ###################################
  // TABLE
  // ###################################
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page);
  }

  loadTable(page: Page) {
    this.inspectionService.getApprovalDetails(this.page, this.job.id).then((pagedData) => {
      console.log(pagedData);
      this.page = pagedData.page;
      this.rows = pagedData.data;
      console.log("ROWS");
      console.log(this.rows);
      });
  }

  downloadReport(id: number) {
    this.reportableService.getLiftingExam(id).then((data) => {
      importedSaveAs(data, 'Inspection Report.pdf');
    });
  }

  //downloadRAMS(id: string) {
  //  this.reportableService.getRAMSReport(id).then((data) => {
  //    importedSaveAs(data, 'RISKS.pdf');
  //  });
  //}

  downloadERSTR(id: number) {
    this.reportableService.getERSTR(id).then((data) => {
      importedSaveAs(data, 'ERSTR.pdf');
    });
  }

}
//angular.module('coreApp').controller("JobInspectController", ['$timeout', '$scope', '$http', 'toastr', '$state', '$rootScope', 'QueryStringService', 'NgTableParams', '$location', 'job', 'InspectFactory', '$stateParams', function ($timeout, $scope, $http, toastr, $state, $rootScope, QueryStringService, NgTableParams, $location, job, InspectFactory, $stateParams) {

//  var default_filters = { desc: "", serial_num: "" };
//  $scope.filterBy = QueryStringService.getFilters(jQuery.extend(true, {}, default_filters));

//  $scope.job = job;
//  $scope.jobid = job.jobid;
//  $scope.signinReady = false;
//  $scope.loginobj = {};
//  $scope.li = {};

//  $scope.gojobmanagement = function () {
//    $state.go("JobManagement", {});
//    //$state.go($rootScope.previousState);
//  }

//  $http.get("api/jobs/get?Id=" + job.jobid).success(function (response) {
//    $scope.li = response;
//    console.log(response);
//  });


//  $scope.engineerLogin = function () {
//    $scope.signinReady = true;
//  }


//  $scope.clearCheckBoxes = function () {
//    $scope.isAllSelected = false;
//    $scope.select_text = "Select All";
//    $scope.id_list = { Id: [] };
//  }

//  $scope.clearCheckBoxes();


//  //if ($scope.job.Job_Type == 1)
//  //    $scope.job.Job_Type = $scope.jobtypes[0];
//  //else if ($scope.job.Job_Type == 2)
//  //    $scope.job.Job_Type = $scope.jobtypes[1];
//  //else
//  //    $scope.job.Job_Type = $scope.jobtypes[2];

//  $scope.clearFilters = function () {
//    for (var param in $scope.filterBy) {
//      $scope.filterBy[param] = default_filters[param];
//    };

//    $location.url($location.path());
//  };


//  $scope.search = function () {



//    if (angular.equals($scope.filterBy.Serial_Num, "")) {
//      $scope.filterBy.Serial_Num = null;
//    }
//    if (angular.equals($scope.filterBy.Desc, "")) {
//      $scope.filterBy.Desc = null;
//    }



//    $location.url($location.path());
//    loadTable();
//  }


//  loadTable();

//  function loadTable() {

//    $scope.clearCheckBoxes();

//    $scope.tableParams = new NgTableParams(angular.extend({

//      page: 1,
//      count: 10,
//      sorting: { Description: "asc" },
//      Id: job.jobid,
//      Desc: $scope.filterBy.Desc,
//      Serial_Num: $scope.filterBy.Serial_Num

//    }, $location.search()), {
//        getData: function (params) {
//          var urlApi = 'api/jobs/getinspections';



//          //if (!firstLoad) {
//          //$location.search(params.url());
//          //}
//          //firstLoad = false;

//          var key = Object.keys(params.sorting())[0];

//          var filters = {
//            page: params.page(),
//            count: params.count(),
//            sortby: Object.keys(params.sorting())[0],
//            sortorder: params.sorting()[Object.keys(params.sorting())[0]],
//            Id: job.jobid,
//            Desc: $scope.filterBy.Desc,
//            Serial_Num: $scope.filterBy.Serial_Num
//          };

//          return $http.get(urlApi, { params: filters }).then(function (response) {
//            $scope.itemCount = response.data.inlineCount;
//            console.log(response.data);
//            params.total(response.data.inlineCount); // recal. page nav controls

//            $scope.pagination = {
//              totalCount: response.data.inlineCount,
//              page: filters.page == 1 ? 1 : (filters.page * filters.count) - (filters.count - 1),
//              count: filters.page * filters.count
//            };

//            $scope.theresults = response.data.results;
//            $scope.N_Inspect = 0;
//            angular.forEach($scope.theresults, function (itm) { if (itm.Inspected == "Inspect") { $scope.N_Inspect++; } });
//            return $scope.theresults;
//          });
//        }
//      });
//  }


//  $scope.toggleAll = function () {
//    var toggleStatus = !$scope.isAllSelected;
//    $scope.isAllSelected = toggleStatus;
//    if (!toggleStatus) {
//      $scope.select_text = "Select All";
//      $scope.id_list = { Id: [] };
//    } else {
//      $scope.select_text = "DeSelect All";
//      $scope.id_list = { Id: [] };
//      angular.forEach($scope.theresults, function (itm) {
//        if (itm.Inspected == "Inspect") {
//          $scope.id_list.Id.push(itm.Id);
//        }
//      });

//    }

//    angular.forEach($scope.theresults, function (itm) { itm.selected = toggleStatus; });
//    //alert($scope.id_list.Id);
//  }

//  $scope.optionToggled = function (row) {
//    if (row.selected) {
//      var index = -1;
//      index = $scope.id_list.Id.indexOf(row.Id);
//      if (index == -1) {
//        $scope.id_list.Id.push(row.Id);
//      }
//    }
//    else {
//      var index = -1;
//      index = $scope.id_list.Id.indexOf(row.Id);
//      if (index > -1) {
//        $scope.id_list.Id.splice(index, 1);
//      }
//    }
//    //alert($scope.id_list.Id);
//  }

//  $scope.save = function () {

//    angular.forEach($scope.theresults, function (itm) { if (itm.selected) { $scope.cnt++; } });


//  }

//  $scope.savejobdetails = function () {

//    $http.put("api/jobs/put", $scope.li).success(function (response, status) {
//      //toastr.success("Successfully saved Job Details.", "Success");
//    }).error(function (response) {
//      toastr.error(response, "Error");
//    });

//  }


//  $scope.LoggedPurposedAndInspectable = function (inspectable) {

//    if ($scope.li.Purpose_Of_Examination_Id == undefined) {
//      return false;
//    }
//    if ($scope.li.Purpose_Of_Examination_Id == 0) {
//      return false;
//    }
//    if ($scope.li.Colour_Id == undefined) {
//      return false;
//    }
//    if ($scope.li.Colour_Id == 0) {
//      return false;
//    }

//    if (!$scope.SignedIn()) {
//      return false;
//    }

//    if (!inspectable) {
//      return false;
//    }

//    return true;
//  }

//  $scope.ItemsSelected = function () {
//    $scope.cnt = 0;
//    angular.forEach($scope.theresults, function (itm) { if (itm.selected) { $scope.cnt++; } });
//    if ($scope.cnt > 0) {
//      return true;
//    } else { return false; }

//  }


//  $scope.ItemInspect = function (Id) {

//    // If Inspection not exists create blank inspection
//    // Open existing in approve edit
//    //alert("checking...");
//    var api = "api/inspection/getexists?Id=" + Id;

//    $http.get(api).success(function (response) {
//      $scope.inspection = response;
//      //alert("checked"+$scope.inspection.Id);
//      loadTable();
//      window.location = "/#/Administration/ApproveInspections/Edit?Id=" + $scope.inspection.Id;
//    }).error(function (response) {
//      toastr.error(response, "Error");
//    });
//  }

//  $scope.MultiInspection = function () {
//    InspectFactory.setInspectionString($scope.id_list);
//    InspectFactory.setJobGUIDString($scope.jobid);
//    InspectFactory.setInspectSummary("");
//    $state.go("InspectionsEdit");
//  }

//  $scope.SingleInspection = function (row) {
//    $scope.mylist = { Id: [] };
//    $scope.mylist.Id.push(row.Id);
//    InspectFactory.setInspectionString($scope.mylist);
//    InspectFactory.setJobGUIDString($scope.jobid);
//    InspectFactory.setInspectSummary(" - " + row.Description + " - " + row.Serial_Num + " - " + row.Category);
//    $state.go("InspectionsEdit");
//  }



//  $scope.SignOut = function () {
//    InspectFactory.setEngineerGUIDString("");
//    $scope.signinReady = false;
//  }

//  $scope.SignedIn = function () {

//    $scope.engineersignedin = "";
//    $scope.engineersignedin = InspectFactory.getEngineerGUIDString();
//    //alert($scope.engineersignedin);
//    if (angular.equals($scope.engineersignedin, "")) {
//      return false;
//    }
//    if (angular.equals($scope.engineersignedin, null)) {
//      return false;
//    }
//    return true;
//  }

//  $scope.EngineerLogin = function () {

//    if ($scope.loginobj.Engineer_Id == null) { return; }

//    var api = "api/engineer/checklogin?Id=" + $scope.loginobj.Engineer_Id + "&pin=" + $scope.loginobj.Engineer_PIN;

//    $http.get(api).success(function (response) {
//      if (response) {
//        $scope.loginmsg = "";
//        InspectFactory.setEngineerGUIDString($scope.loginobj.Engineer_Id);
//      } else {
//        $scope.loginmsg = "Invalid Attempt. Please try again.";
//      }
//      //set inspect factory object engineerid
//    }).error(function (response) {
//      toastr.error(response, "Error");
//    });
//  }

//  $http.get("api/engineer/getall").success(function (response) {
//    $scope.engineers = response;
//  });

//  $http.get("api/purpose/getall").success(function (response) {
//    $scope.purposes = response;
//  });

//  $http.get("api/colour/getall").success(function (response) {
//    $scope.colours = response;
//  });


//  $('.datepicker').datepicker({
//    format: 'dd/mm/yyyy',
//    autoclose: true
//  });

//}]);
