import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { PagedData } from '../models/paging/paged-data';
import { Page } from '../models/paging/page';
import { ServerData } from '../models/paging/server-data';

@Injectable()
export class PartService {
  constructor(private http: HttpClient) { }

  public get(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>('/api/part/get').subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
}
