import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';

@Injectable()
export class ApiHttpInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) { }
  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //console.log("intercept");
    if (request.url.includes(environment.reportableUrl)) {
      return next.handle(request);
    }
    var user = this.authService.currentUser.value;
    if (user != null) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${user.basicAuthToken}`
        }
      });
    }
    return next.handle(request);
  }
}
