import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

//import { Category } from '../../../../models/category';
import { Tablet } from '../../../../models/tablet';
import { Vehicle } from '../../../../models/vehicle';
import { CategoryService } from '../../../../services/category.service';
import { TabletService } from '../../../../services/tablet.service';
import { VehicleService } from '../../../../services/vehicle.service';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-vehicle-manage',
  templateUrl: './vehicle-manage.component.html',
  styleUrls: ['./vehicle-manage.component.css']
})
export class VehicleManageComponent implements OnInit {
  users: Tablet[];
  vehicle: Vehicle;
  constructor(public vehicleService: VehicleService, private snotifyService: SnotifyService, public tabletService: TabletService, public router: Router, private route: ActivatedRoute) {

    this.tabletService.getAll().then((data) => {
      this.users = data;
      //console.log("users");
      //console.log(data);
    });

    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      if (id != undefined) {
        this.vehicleService.get(id).then((data) => {
          console.log(data);
          this.vehicle = data;

        });
      }
    });

  }

  ngOnInit() {
    // Load our id if one exists
    
  }

  edit(row) {
    this.router.navigate(['administration/vehicle/manage'], { queryParams: { id: row.id } });
  }

  submit() {
    this.vehicle.pdA_User_ID = +this.vehicle.pdA_User_ID
    if (this.vehicle.pdA_User_ID < 1) {
      this.snotifyService.success("Please assign this vehicle to an engineer");
    }
    this.vehicleService.save(this.vehicle).then((data) => {
      this.router.navigate(['administration/vehicle']);
    });
  }

}
