import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate, state, stagger } from '@angular/animations';
import { AuthService } from '../services/auth.service';


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
  animations: [
    trigger('navAnimation', [
      state('close', style({
        opacity: 0,
        transform: 'translateX(-100%)',
        display: 'none'
      })),
      state('open', style({
        opacity: 1,
        transform: 'translateX(0%)',
        display: 'block'
      })),
      transition('close <=> open', animate(120)),
    ])
    //trigger('navAnimation',
    //  [
    //    transition(':enter', [
    //        style({ transform: 'translateX(100%)', opacity: 0 }),
    //        animate('150ms', style({ transform: 'translateX(0%)', 'opacity': 1 }))
    //      ]
    //    ),
    //    transition(':leave', [
    //        style({ transform: 'translateY(0)', 'opacity': 1 }),
    //        animate('100ms', style({ transform: 'translateY(100%)', 'opacity': 0 })
    //    )]
    //)
  ],
})
export class LayoutComponent implements OnInit {
  is_customer;
  is_admin;
  is_super;
  user;

  openNavs = [];

  constructor(authService: AuthService) {
    this.user = localStorage.getItem("currentUser");
    this.user = JSON.parse(this.user);
    this.is_customer = this.user.userTypeId == 1;
    this.is_admin = this.user.userTypeId == 2;
    this.is_super = this.user.userTypeId == 3;
  }

  ngOnInit() {

  }


  setNav(nav) {
    if (this.openNavs.includes(nav)) {
      let idx = this.openNavs.indexOf(nav);
      this.openNavs.splice(idx, 1);
      return;
    }

    this.openNavs.push(nav);
  }

  isNavOpen(nav) {
    return this.openNavs.includes(nav);
  }
}
