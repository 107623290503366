import { Component, OnInit } from '@angular/core';
import { Company } from '../../../models/company';
import { Location } from '../../../models/location';
import { Location_Inspection } from '../../../models/location_inspection';
import { Page } from '../../../models/paging/page';
import { CompanyService } from '../../../services/company.service';
import { LocationService } from '../../../services/location.service';
import { JobService } from '../../../services/job.service';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/components/common/confirmationservice';
import * as moment from 'moment';
import { saveAs as importedSaveAs } from 'file-saver';
import { ReportableService } from '../../../services/reportable.service';
import { ColumnService } from '../../../services/column.service';
import { AuthService } from '../../../services/auth.service';
import { StorageService } from '../../../services/storage.service';
import { TabletService } from '../../../services/tablet.service';
import { Tablet } from '../../../models/tablet';

@Component({
  selector: 'app-job-management',
  templateUrl: './job-management.component.html',
  styleUrls: ['./job-management.component.css']
})
export class JobManagementComponent implements OnInit {
  filter: any = {
    company_Id: 0,
    location_Id: 0,
    job_No: '',
    quickSearch: '',
    created_date_Range: 1,
    created_start_Date: moment().add(-1, 'months').toDate(),
    created_end_Date: moment().toDate(),
    status: 0,
    pdaUserId: 0
    //scheduled_date_Range: 12,
    //scheduled_start_Date: moment().add(-12, 'months').toDate(),
    //scheduled_end_Date: moment().toDate()
  };
  init: boolean;
  locationList: Location[];
  toggleOpen = false;
  user;
  is_admin = false;
  users: Tablet[];
  companies: Company[];
  locations: Location[];

  page = new Page();
  rows = Array<Location_Inspection>();

  public readonly pageLimitOptions = [
    { value: 5 },
    { value: 10 },
    { value: 25 },
    { value: 50 },
    { value: 100 },
  ];


  public currentPageLimit: number = 10;

  columns = [
    { name: 'Company', prop: 'company' },
    { name: 'Location', prop: 'location' },
    { name: 'Created', prop: 'created_At_Formatted' },
    { name: 'Job Description', prop: 'notes' },
    { name: 'Job No', prop: 'job_No' },
    { name: 'Job Type', prop: 'job_Type_Formatted' },
    { name: 'Engineers', prop: 'users' },
  ];

  allColumns = [
    { name: 'Company', prop: 'company' },
    { name: 'Location', prop: 'location' },
    { name: 'Created', prop: 'created_At_Formatted' },
    { name: 'Job Description', prop: 'notes' },
    { name: 'Job No', prop: 'job_No' },
    { name: 'Job Type', prop: 'job_Type_Formatted' },
    { name: 'Engineers', prop: 'users' },
  ];




  toggle(col) {
    const isChecked = this.isChecked(col);

    if (isChecked) {
      this.columns = this.columns.filter(c => {
        return c.name !== col.name;
      });
    } else {
      this.columns = [...this.columns, col];
    }
    this.toggleSelection();
  }

  isChecked(col) {
    return (
      this.columns.find(c => {
        return c.name === col.name;
      }) !== undefined
    );
  }

  toggleSelection() {
    this.toggleOpen = !this.toggleOpen;
  }

  constructor(private authService: AuthService, public companyService: CompanyService, public locationService: LocationService,
    private columnService: ColumnService, public reportableService: ReportableService, tabletService: TabletService,
    public jobService: JobService, public router: Router, private confirmationService: ConfirmationService, private storageService: StorageService) {

    this.page.pageNumber = 0;
    this.page.size = 10;

    this.user = localStorage.getItem("currentUser");
    this.user = JSON.parse(this.user);
    //console.log(this.user);
    this.is_admin = this.user.userTypeId == 2;
    console.log(this.is_admin);

    this.companyService.getAll().then((data) => {
      this.companies = data;
      this.locationService.getAll().then((data) => {
        this.locationList = data;
      });
    });
    tabletService.getAll().then((data) => {
      this.users = data;
    });
  }
  resetFilters() {
    this.filter = {
      company_Id: 0,
      location_Id: 0,
      job_No: '',
      quickSearch: '',
      created_date_Range: 1,
      created_start_Date: moment().add(-1, 'months').toDate(),
      created_end_Date: moment().toDate(),
      status: 0,
      pdaUserId: 0
      //scheduled_date_Range: 12,
      //scheduled_start_Date: moment().add(-12, 'months').toDate(),
      //scheduled_end_Date: moment().toDate()
    };
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.currentPageLimit = this.page.size;
  }
  onCompanyChange(value) {
    this.filter.location_Id = 0;
    if (value == 0) { value = null; }

    this.locations = this.locationList.filter(c => {
      return value == null || c.companyId == value;
    });
  }

  ngOnInit() {
    //window.scroll(0, 0);
    //this.setPage({ offset: 0 });
    //this.init = true;
    var page = JSON.parse(this.storageService.get("job_management_page"));
    var filter = JSON.parse(this.storageService.get("job_management_filters"));
    if (page != undefined) {
      this.page = page;
      console.log(this.page);
    }
    if (filter != undefined) {
      this.filter = filter;
      this.filter.created_start_Date = moment(this.filter.created_start_Date).toDate();
      this.filter.created_end_Date = moment(this.filter.created_end_Date).toDate();
      this.filter.pdaUserId = +this.filter.pdaUserId;

    }
    if (page == undefined && filter == undefined) {
      this.setPage({ offset: 0 });
      this.init = true;
      this.resetFilters();
    } else {
      this.currentPageLimit = this.page.size;
      this.loadTable(this.page, false);
    }
  }

  complete(job) {
    //let jobDetails = 'Company: ' + job.company.name + '<br/>';
    //jobDetails += 'Location: ' + job.location.name + '<br/>';
    console.log(job);
    let jobDetails = 'Job No: ' + job.job_No;
    this.confirmationService.confirm({
      message: 'Are you sure that you want complete this job?</br><br/>' + jobDetails,
      accept: () => {
        this.jobService.complete(job.id).then(() => {
          this.loadTable(this.page,false);
        });
      }
    });
  }

  reopen(job) {
    //let jobDetails = 'Company: ' + job.company.name + '<br/>';
    //jobDetails += 'Location: ' + job.location.name + '<br/>';
    console.log(job);
    let jobDetails = 'Job No: ' + job.job_No;
    this.confirmationService.confirm({
      message: 'Are you sure that you want reopen this job?</br><br/>' + jobDetails,
      accept: () => {
        this.jobService.reopen(job.id).then(() => {
          this.loadTable(this.page, false);
        });
      }
    });
  }

  edit(job) {
    this.router.navigate(['administration/jobs/manage'], { queryParams: { id: job.id } });
  }

  inspect(job) {
    this.router.navigate(['administration/jobs/inspect'], { queryParams: { id: job.id } });
  }

  delete(job) {
    let jobDetails = 'Company: ' + job.company.name + '<br/>';
    jobDetails += 'Location: ' + job.location.name + '<br/>';
    jobDetails += 'Job No: ' + job.job_No;
    this.confirmationService.confirm({
      message: 'Are you sure that you want delete this job?</br><br/>' + jobDetails,
      accept: () => {
        this.jobService.delete(job.id).then(() => {
          this.loadTable(this.page, false);
        });
      }
    });
  }


  // ###################################
  // TABLE
  // ###################################
  public onLimitChange(limit: any): void {
    this.page.size = parseInt(limit, 10);
    this.loadTable(this.page, false);
  }
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page, false);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page, false);
  }

  loadTable(page: Page,clicked) {
    if (clicked) {
      this.page.pageNumber = 0;
      this.currentPageLimit = this.page.size;
      this.setPage({ offset: 0 });
      return;
    }
    this.storageService.dataSave("job_management_filters", JSON.stringify(this.filter));
    this.storageService.dataSave("job_menagement_page", JSON.stringify(this.page));

    if (this.filter.company_Id == null || this.filter.company_Id == undefined) {
      this.filter.company_Id = 0;
    }
    if (this.filter.location_Id == null || this.filter.location_Id == undefined) {
      this.filter.location_Id = 0;
    }
    this.jobService.getResults(this.page, this.filter.company_Id, this.filter.location_Id, this.filter.job_No, 
      this.filter.created_start_Date, this.filter.created_end_Date, this.filter.status, this.filter.quickSearch, this.filter.pdaUserId).then((pagedData) => {
      this.page = pagedData.page;
        this.rows = pagedData.data;
        console.log(this.rows);
        if (this.init) {
          this.gotoTop();
          this.init = false;
        }
      });
    this.getcolumns();
  }

  getcolumns() {
    this.columnService.getColumns(this.authService.currentUser.value.userId, "jobs").then((data) => {
      if (data.columns != null) {
        this.columns = this.columns.filter(c => {
          return data.columns.includes(c.name);
        });
        this.toggleOpen = false;
      }

    });
  }
  SaveSelection() {
    let result = this.columns.map(a => a.name);
    this.columnService.save(this.authService.currentUser.value.userId, "jobs", result).then((data) => {
    });
  }

  dateChange(ev,type) {
    if (ev == -1) {
      return;
    }

    let date = moment();
    if (ev == -2) {
      date.set('year', 2010);
      date.set('month', 0);
      date.set('date', 1);
    }
    else {
      if (ev == 0) {
        date = date.subtract(1, 'weeks');
      } else {
        date = date.subtract(ev, 'months');
      }
    }

    let jsDate = date.toDate();
    //var startDate: NgbDateStruct = { year: jsDate.getFullYear(), month: jsDate.getMonth() + 1, day: jsDate.getDate() };
    if(type ==1)
      this.filter.created_start_Date = jsDate;
    else if (type == 2)
      this.filter.scheduled_start_Date = jsDate;
  }

  getRowClass = (row) => {
    if (row.hasTimesheets == 1) {
      if (row.job_Ended == 1) {
        return {
          'row-colour-completed': true
        };
      }
      return {
        'row-colour-timesheets': true
      };
    }
    

  }

  generateExcel() {
    this.jobService.getExcel(this.filter.company_Id, this.filter.location_Id, this.filter.job_No,
      this.filter.created_start_Date, this.filter.created_end_Date, this.filter.status, this.filter.quickSearch, this.filter.pdaUserId).then((data) => {
      importedSaveAs(data, 'Report.xlsx');
    });
  }
  gotoTop() {
    console.log("GOTO TOP");
    // window.scrollTo(-9999,-99999);
    let top = document.getElementById('top');
    console.log(top);
    if (top !== null) {
      top.scrollIntoView(true);
      //  document.getElementById("top").scrollTop -= 99999;
      top = null;
    }
  }

  downloadReport(id: number) {
    this.reportableService.getJobForm(id).then((data) => {
      importedSaveAs(data, 'Breakdown Inspection Report.pdf');
    });
  }

}
