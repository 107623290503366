import { Component, OnInit } from '@angular/core';
import { CompanyService } from '../../../../services/company.service';
import { LocationService } from '../../../../services/location.service';
import { EquipmentService } from '../../../../services/equipment.service';
import { ManufacturerService } from '../../../../services/manufacturer.service';
import { CategoryService } from '../../../../services/category.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Equipment } from '../../../../models/equipment';
import { Equipment_Extra } from '../../../../models/equipment_Extra';
import { SnotifyService } from 'ng-snotify';
import * as moment from 'moment';
import { Manufacturer } from '../../../../models/manufacturer';
import { DescriptionsService } from '../../../../services/descriptions.service';

@Component({
  selector: 'app-equipment-manage',
  templateUrl: './equipment-manage.component.html',
  styleUrls: ['./equipment-manage.component.css']
})
export class EquipmentManageComponent implements OnInit {

  companies;
  locations;
  manufacturers: Manufacturer[] = [];
  categories;
  descriptions;

  equipment: Equipment;

  locationList;
  descriptionList;

  is_Crane;
  is_Jib;
  is_Edit = false;
  user;
  filter: any = {
    company_Id: 0,
    location_Id: undefined,
    category_Id: 0,
    serial_Num: '',
    asset_Num: ''
  };

  constructor(public companyService: CompanyService,public descriptionService: DescriptionsService, public locationService: LocationService, public equipmentService: EquipmentService, manufacturerService: ManufacturerService,
    categoryService: CategoryService, public router: Router, private route: ActivatedRoute, private snotifyService: SnotifyService) {
    this.equipment = new Equipment();
    this.equipment.inspection_Status = -1;
    this.equipment.location_Id = -1;
    this.equipment.company_Id = -1;
    this.equipment.owner_ID = 1;
    this.equipment.cE_Num = '';
    this.equipment.category_ID = -1;
    this.is_Edit = false;
    this.user = localStorage.getItem("currentUser");
    this.user = JSON.parse(this.user);
    this.equipment.userId = this.user.userId

    
    companyService.getAll().then((data) => {
      this.companies = data;
    });
    manufacturerService.getAll().then((data) => {
      this.manufacturers = data;
      console.log(this.manufacturers);
    });

    locationService.getAll().then((data) => {
      this.locationList = data;
      this.locations = this.locationList;
    });

    categoryService.getAll().then((data) => {
      this.categories = data;
      console.log(this.categories);
    });

    descriptionService.getAll().then((data) => {
      this.descriptionList = data;
      this.descriptions = this.descriptionList;
    });

    
  }

  onCompanyChange(value) {
    console.log("hit on company change");
    this.filter.location_Id = -1;
    if (value == 0) { value = null; }

    this.locations = this.locationList.filter(c => {
      return value == null || c.companyId == value;
    });
    this.equipment.location_Id = null;
  }



  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      if (id != undefined) {
        this.equipmentService.get(id).then((data) => {
          this.equipment = data;
          console.log(this.equipment);
          if (this.equipment.date_Of_Manuf != null && this.equipment.date_Of_Manuf != undefined && moment(this.equipment.date_Of_Manuf).format("YYYY-MM-DD") != moment("0001-01-01").format("YYYY-MM-DD")) {
            var str = this.equipment.date_Of_Manuf.split("T", 2);
            this.equipment.date_Of_Manuf = moment(str[0]).format("YYYY-MM-DD");
          } else {
            this.equipment.date_Of_Manuf = undefined;
          }

          if (this.equipment.date_Of_First_Use != null && this.equipment.date_Of_First_Use != undefined && moment(this.equipment.date_Of_First_Use).format("YYYY-MM-DD") != moment("0001-01-01").format("YYYY-MM-DD") ) {
            var str2 = this.equipment.date_Of_First_Use.split("T", 2);
            this.equipment.date_Of_First_Use = moment(str2[0]).format("YYYY-MM-DD");
          } else {
            this.equipment.date_Of_First_Use = undefined;
          }

          if (this.equipment.date_Of_Last_Inspection != null && this.equipment.date_Of_Last_Inspection != undefined && moment(this.equipment.date_Of_Last_Inspection).format("YYYY-MM-DD") != moment("0001-01-01").format("YYYY-MM-DD")) {
            var str3 = this.equipment.date_Of_Last_Inspection.split("T", 2);
            this.equipment.date_Of_Last_Inspection = moment(str3[0]).format("YYYY-MM-DD");
          } else {
            this.equipment.date_Of_Last_Inspection = undefined;
          }

          if (this.equipment.last_Inspection_ID == 0) {
            this.equipment.inspection_Status = -1;
          }
          
          this.equipment.is_edit = true;
          this.is_Edit = true;
          //this.locations = this.locationList.filter(c => {
          //  return c.company_Id == this.equipment.company_Id;
          //});
          //this.descriptions = this.descriptionList.filter(c => {
          //  return c.category_ID == this.equipment.category_ID;
          //});
          //this.equipment.location_Id == this.equipment.location_Id;
          //this.onCategoryChange(this.equipment.category_ID);
        });
      }
    });
  }

  onCategoryChange(value) {
    console.log(value);
    this.filter.category_Id = -1;
    if (value == 0) { value = null; }

    this.descriptions = this.descriptionList.filter(c => {
      return value == null || c.category_ID == value;
    });
    this.equipment.description_ID = null;

    //const category = this.categories.find(a => a.id == value);
    //if (this.equipment.interval == null || this.equipment.interval == undefined || this.equipment.interval == 0) {
    //  this.equipment.interval = category.inspection_Interval;
    //}

    //if (category.name == 'Crane') {
    //  this.is_Crane = true;
    //  this.is_Jib = false;
    //}
    //else if (category.name == 'Jib') {
    //  this.is_Jib = true;
    //  this.is_Crane = false;
    //} else {
    //  this.is_Crane = false;
    //  this.is_Jib = false;
    //}

    
  }

  onDescriptionChange(value) {
    console.log(value);
    this.equipment.description = value;

    
  }

  addExtra() {
    if (this.equipment.extras.length >= 3) {
      this.snotifyService.warning('You cannot have more than 3 additional Details');
      return;
    }
    const extra = new Equipment_Extra();
    extra.manuf_ID = +extra.manuf_ID;

    this.equipment.extras.push(extra);
    console.log(this.equipment.extras);
  }

  removeExtra(extra) {
    this.equipment.extras.splice(this.equipment.extras.indexOf(extra), 1);
  }

  submit() {
    console.log("submitting");
    
    // Check if we have any extras and if so validate them
    for (const extra of this.equipment.extras) {

      if (extra.serial_Num == '' || extra.model == '' || extra.hoist_Bs_Class == ''
        || extra.ct_Bs_Class == '' || extra.manuf_ID == undefined) {
        this.snotifyService.warning('Please enter all Additional information');
        return;
      } else {
        extra.manuf_ID = + extra.manuf_ID;
      }
      //this.equipment.extras[extra].manuf_ID = +extra.manuf_ID;
    }
    this.equipment.category_ID = +this.equipment.category_ID;
    this.equipment.company_Id = +this.equipment.company_Id;
    this.equipment.inspection_Status = +this.equipment.inspection_Status;
    this.equipment.location_Id = +this.equipment.location_Id;
    this.equipment.manuf_ID = +this.equipment.manuf_ID;
    this.equipment.owner_ID = +this.equipment.owner_ID;
    

    if (this.equipment.company_Id < 1) {
      this.snotifyService.warning('Please enter company');
      return;
    }
    if (this.equipment.location_Id < 1) {
      this.snotifyService.warning('Please enter location');
      return;
    }
    if (this.equipment.category_ID < 1) {
      this.snotifyService.warning('Please enter category');
      return;
    }
    if (this.equipment.description == undefined) {
      this.snotifyService.warning('Please enter description');
      return;
    }
    if ((this.equipment.serial_Num == null || this.equipment.serial_Num == undefined || this.equipment.serial_Num == '') &&
      (this.equipment.asset_Num == null || this.equipment.asset_Num == undefined || this.equipment.asset_Num == '')) {
      this.snotifyService.warning('Please enter a serial or asset number');
      return;
    }

    

    
    
    if (this.equipment.inspection_Status != -1) {
      if (this.equipment.date_Of_Last_Inspection == undefined || this.equipment.date_Of_Last_Inspection == null) {
        this.snotifyService.warning('Please enter inspection date');
        return;
      }
        
    }
    if (this.equipment.date_Of_First_Use == "") {
      this.equipment.date_Of_First_Use = null;
    }
    if (this.equipment.date_Of_Manuf == "") {
      this.equipment.date_Of_Manuf = null;
    }
    if (this.equipment.date_Of_Last_Inspection == "") {
      this.equipment.date_Of_Last_Inspection = null;
    }
    console.log(this.equipment)
    this.equipmentService.save(this.equipment).then((data) => {
      this.router.navigate(['administration/equipment']);
    });



  }


}
