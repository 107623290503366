import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SnotifyService } from 'ng-snotify';
import { ApiResponse } from '../models/api-response';
import { environment } from '../../environments/environment';
import { ServerData } from '../models/paging/server-data';

@Injectable()
export class MassUploadService {

  constructor(private http: HttpClient, private snotifyService: SnotifyService) { }

  //public uploadFile(base64String: string): Promise<any> {
  //  return new Promise((resolve, reject) => {
  //    var uploadFileDto = {
  //      FileData: base64String
  //    };

  //    this.http.post('/api/upload/uploadservicequestions', uploadFileDto).subscribe(data => {
  //      resolve(data);
  //      this.snotifyService.success('Successfully updated questions');
  //    }, error => {
  //      reject(error);
  //    });

  //  });
  //}

  public uploadEquipmentFile(base64String: string, user: string): Promise<any[]> {
    console.log("SERVICE");
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      //let params = 'FileData=' + base64String
      //params += '&user=' + user;

      var uploadFileDto = {
        FileData: base64String,
        User: user
      };

      this.http.post<any[]>(environment.apiUrl + 'massupload/upload', uploadFileDto, { headers: headers }).subscribe(data => {
        resolve(data);
        
        
      }, error => {
        reject(error);
      });

    });
  }

  
}
